import { AxiosError } from 'axios';
import useAppDispatch from 'Hooks/Redux/useAppDispatch';
import { useCallback } from 'react';
import { MessageActionType, MessageStatusLevel } from 'Redux/Actions/Messages';

type UseMessagesDispatch = () => {
	dispatchSetMessageAction: (message: string, level: MessageStatusLevel, duration?: number) => void;
	dispatchCloseMessageAction: (guid: string) => void;
	dispatchClearMessageAction: (guid: string) => void;
	dispatchSetAxiosErrorMessage: (error: AxiosError) => void;
};

const useMessagesDispatch: UseMessagesDispatch = () => {
	const appDispatch = useAppDispatch();

	const dispatchSetMessageAction = useCallback(
		(message: string, level: MessageStatusLevel, duration: number = 3000): void =>
			appDispatch({ type: MessageActionType.SetMessage, message, level, duration }),
		[appDispatch]
	);
	const dispatchCloseMessageAction = useCallback(
		(guid: string): void => appDispatch({ type: MessageActionType.CloseMessage, guid }),
		[appDispatch]
	);
	const dispatchClearMessageAction = useCallback(
		(guid: string): void => appDispatch({ type: MessageActionType.ClearMessage, guid }),
		[appDispatch]
	);

	const dispatchSetAxiosError = useCallback(
		(error: AxiosError): void =>
			dispatchSetMessageAction(`${error.response?.status}: ${error.message}`, MessageStatusLevel.Error, 5000),
		[dispatchSetMessageAction]
	);

	return {
		dispatchSetMessageAction,
		dispatchCloseMessageAction,
		dispatchClearMessageAction,
		dispatchSetAxiosErrorMessage: dispatchSetAxiosError,
	};
};

export default useMessagesDispatch;
