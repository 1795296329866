import { Box, createStyles, FormGroup } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FastForwardIcon from '@material-ui/icons/FastForward';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import EditorButton from 'Components/Common/Buttons/EditorButton';
import { PlayMode, UseVideoPlayerInstance } from 'Hooks/Editor/useVideoPlayerInstance';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import useForceRerender from 'Hooks/useForceRerender';
import { ReactElement } from 'react';
import { getNextBufferedDetectionTime } from 'Utils/DetectionHelpers';
import postSeekNextVideoFrameDetectionRequest, {
	PostSeekNextVideoFrameDetectionRequest,
} from 'Utils/Http/Requests/Videos/Detections/PostSeekNextVideoFrameDetectionRequest';

interface PlayerVideoControlsProps {
	instance: UseVideoPlayerInstance;
}

const useStyles = makeStyles(({ spacing }: Theme) =>
	createStyles({
		formGroup: {
			'& > div': {
				marginBottom: spacing(1),
			},
			'& > div:last-child': {
				marginBottom: 0,
			},
		},
		root: {
			display: 'inline-block',
		},
	})
);

const DEFAULT_RATE_STEP = 0.5;
const MAX_SPEED_RATE = 6;

const PlayerVideoControls = ({ instance }: PlayerVideoControlsProps): ReactElement => {
	const {
		videoNode,
		toggleVideoPlay,
		fps,
		isVideoPlaying,
		frameDetections,
		video,
		filteredBrandIds,
		filteredPlacementIds,
		playMode,
	} = instance;
	const classes = useStyles();

	const forceRerender = useForceRerender();
	const token = useAppSelector((state) => state.profile.token);
	const { dispatchStartLoading, dispatchStopLoading } = useLoadingDispatch();
	const { dispatchSetAxiosErrorMessage } = useMessagesDispatch();

	const buttonsDisabled = videoNode === null;
	const playbackRate = !!videoNode ? videoNode.playbackRate : 0;
	const isSpeedDownButtonDisabled = playbackRate - DEFAULT_RATE_STEP < 0;
	const isSpeedUpButtonDisabled = playbackRate + DEFAULT_RATE_STEP > MAX_SPEED_RATE;

	const handlePauseButtonClicked = () => {
		if (videoNode) toggleVideoPlay(false);
	};

	const handlePlayButtonClicked = () => {
		if (videoNode) toggleVideoPlay(true);
	};

	const skipVideo = (skipFor: number): void => {
		if (videoNode) videoNode.currentTime += skipFor;
	};

	const handleNextFrameButtonClicked = () => {
		skipVideo(1 / fps);
	};

	const handlePreviousFrameButtonClicked = () => {
		skipVideo(-(1 / fps));
	};

	const handleNextSecondButtonClicked = () => {
		skipVideo(1);
	};

	const handlePreviousSecondButtonClicked = () => {
		skipVideo(-1);
	};

	const handleSpeedupPlaybackButtonClicked = () => {
		if (videoNode) {
			const newRate = videoNode.playbackRate + DEFAULT_RATE_STEP;
			if (!(newRate > MAX_SPEED_RATE)) {
				videoNode.playbackRate += DEFAULT_RATE_STEP;
				forceRerender();
			}
		}
	};

	const handleSlowdownPlaybackButtonClicked = () => {
		if (videoNode) {
			const newRate = videoNode.playbackRate - DEFAULT_RATE_STEP;
			if (!(newRate < 0)) {
				videoNode.playbackRate = newRate;
				forceRerender();
			}
		}
	};

	const getNextOrPreviousDetectionFromRemote: (
		backwards: boolean,
		currentTime: number,
		seekBySecond: boolean
	) => Promise<number | null> = async (backwards, currentTime, seekBySecond) => {
		const videoId = video?.id;
		if (!videoId) return null;

		const request: PostSeekNextVideoFrameDetectionRequest = {
			backwards,
			timestamp: currentTime * 1000, // Set into Miliseconds!
			brandFilterIds: filteredBrandIds,
			placementFilterIds: filteredPlacementIds,
			seekBySecond,
		};
		dispatchStartLoading();
		try {
			return (await postSeekNextVideoFrameDetectionRequest(token, videoId, request)).data / 1000; // Now back to seconds.
		} catch (e) {
			if (e.response.status === 404) {
				alert('No detection was found.');
			} else {
				dispatchSetAxiosErrorMessage(e);
			}
		} finally {
			dispatchStopLoading();
		}
		return null;
	};

	const handleNextOrPreviousDetectionSeek: (backwards: boolean) => void = async (backwards) => {
		if (!videoNode || fps === -1) return;

		let nextDetectionAt = getNextBufferedDetectionTime(
			fps,
			frameDetections,
			videoNode.currentTime,
			videoNode.duration,
			filteredBrandIds,
			filteredPlacementIds,
			backwards,
			playMode
		);
		if (nextDetectionAt === null) {
			nextDetectionAt = await getNextOrPreviousDetectionFromRemote(
				backwards,
				videoNode.currentTime,
				playMode === PlayMode.SECONDS
			);
		}
		if (nextDetectionAt !== null) {
			videoNode.currentTime = nextDetectionAt;
		}
	};

	const handleToNextDetectionButtonClicked: () => void = async () => handleNextOrPreviousDetectionSeek(false);

	const handleToPreviousDetectionButtonClicked: () => void = async () => handleNextOrPreviousDetectionSeek(true);

	const renderPlayButton = (): JSX.Element => {
		if (isVideoPlaying)
			return (
				<EditorButton title="Pause" onClick={handlePauseButtonClicked} hotkey={'p'} disabled={buttonsDisabled}>
					<PauseIcon />
				</EditorButton>
			);
		return (
			<EditorButton title="Play" onClick={handlePlayButtonClicked} hotkey={'p'} disabled={buttonsDisabled}>
				<PlayArrowIcon />
			</EditorButton>
		);
	};

	return (
		<Box border={1} className={classes.root}>
			<FormGroup className={classes.formGroup}>
				{renderPlayButton()}
				<EditorButton
					title={'Next Frame'}
					onClick={handleNextFrameButtonClicked}
					hotkey={{ key: 'ArrowRight', withCtrl: true }}
					disabled={buttonsDisabled}
				>
					<SkipNextIcon />
				</EditorButton>
				<EditorButton
					title={'Previous Frame'}
					onClick={handlePreviousFrameButtonClicked}
					hotkey={{ key: 'ArrowLeft', withCtrl: true }}
					disabled={buttonsDisabled}
				>
					<SkipPreviousIcon />
				</EditorButton>
				<EditorButton
					title={'Next Second'}
					onClick={handleNextSecondButtonClicked}
					hotkey={{ key: 'ArrowRight', withCtrl: false }}
					disabled={buttonsDisabled}
				>
					<FastForwardIcon />
				</EditorButton>
				<EditorButton
					title={'Previous Second'}
					onClick={handlePreviousSecondButtonClicked}
					hotkey={{ key: 'ArrowLeft', withCtrl: false }}
					disabled={buttonsDisabled}
				>
					<FastRewindIcon />
				</EditorButton>
				<EditorButton
					title={'Speedup Playback'}
					onClick={handleSpeedupPlaybackButtonClicked}
					hotkey="+"
					disabled={buttonsDisabled || isSpeedUpButtonDisabled}
				>
					<ChevronRightIcon />
				</EditorButton>
				<EditorButton title={'Current Playback Speed'} disabled={true}>
					{playbackRate}x
				</EditorButton>
				<EditorButton
					title={'Slowdown Playback'}
					onClick={handleSlowdownPlaybackButtonClicked}
					hotkey="-"
					disabled={buttonsDisabled || isSpeedDownButtonDisabled}
				>
					<ChevronLeftIcon />
				</EditorButton>
				<EditorButton
					title={'To Next Detection'}
					onClick={handleToNextDetectionButtonClicked}
					hotkey="n"
					disabled={buttonsDisabled}
				>
					<ArrowForwardIcon />
				</EditorButton>
				<EditorButton
					title={'To Previous Detection'}
					onClick={handleToPreviousDetectionButtonClicked}
					hotkey="b"
					disabled={buttonsDisabled}
				>
					<ArrowBackIcon />
				</EditorButton>
			</FormGroup>
		</Box>
	);
};

export default PlayerVideoControls;
