import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import useCommonClasses from 'Hooks/Styles/useCommonClasses';
import BrandActivation from 'Models/Brands/BrandActivation';
import { ReactElement } from 'react';
import { getLocalFormattedDateFromUTCTimestamp } from 'Utils/DateHelper';

interface BrandActivationTableProps {
	brandActivations: BrandActivation[];
	isLoading: boolean;
}

const BrandActivationTable = (props: BrandActivationTableProps): ReactElement => {
	const classes = useCommonClasses();

	const createdValueGetter = (params: GridValueGetterParams): string => {
		const valueAsNumber = Number(params.value);
		if (!isNaN(valueAsNumber)) return getLocalFormattedDateFromUTCTimestamp(valueAsNumber);
		return 'NaN';
	};

	const columns: GridColDef[] = [
		{ field: 'name', headerName: 'Name', width: 250 },
		{ field: 'exampleUrl', headerName: 'Url', width: 300 },
		{ field: 'status', headerName: 'Status', width: 140 },
		{ field: 'requestHash', headerName: 'Request Hash', width: 150 },
		{ field: 'created', headerName: 'Created', valueGetter: createdValueGetter, width: 200 },
		{ field: 'exampleImagesCount', headerName: 'Example Images Count', width: 205 },
		{ field: 'requested', headerName: 'Requested', width: 125 },
		{ field: 'processed', headerName: 'Processed', width: 125 },
		{ field: 'message', headerName: 'Message', width: 600 },
	];
	return (
		<div className={classes.fullScreen}>
			<DataGrid
				rows={props.brandActivations}
				columns={columns}
				loading={props.isLoading}
				disableSelectionOnClick={true}
			/>
		</div>
	);
};

export default BrandActivationTable;
