import { AppAction } from 'Redux/Actions';
import {
	ClearMessageAction,
	CloseMessageAction,
	MessageActionType,
	MessageStatusLevel,
	SetMessageAction,
} from 'Redux/Actions/Messages';
import { createNewGuid } from 'Utils/uuid';

export interface MessageState {
	guid: string;
	message: string;
	level: MessageStatusLevel;
	duration: number;
	isOpen: boolean;
}

export type MessagesState = MessageState[];

const initialState: MessagesState = [];

const setMessage = (state: MessagesState, { duration, level, message }: SetMessageAction): MessagesState => {
	const guid = createNewGuid();
	const newState = [...state];
	newState.push({
		duration,
		guid,
		level,
		message,
		isOpen: true,
	});
	return newState;
};

const closeMessage = (state: MessagesState, { guid }: CloseMessageAction): MessagesState =>
	state.map((x) => (x.guid === guid ? { ...x, isOpen: false } : x));

const clearMessages = (state: MessagesState, { guid }: ClearMessageAction): MessagesState =>
	state.filter((x) => x.guid !== guid);

const messages = (state: MessagesState = initialState, action: AppAction): MessagesState => {
	switch (action.type) {
		case MessageActionType.SetMessage:
			return setMessage(state, action);
		case MessageActionType.CloseMessage:
			return closeMessage(state, action);
		case MessageActionType.ClearMessage:
			return clearMessages(state, action);
		default:
			return state;
	}
};

export default messages;
