import { colors } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIconButton from 'Components/Common/Buttons/DeleteIconButton';
import EditIconButton from 'Components/Common/Buttons/EditIconButton';
import CachedIcon from '@material-ui/icons/Cached';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ActionCardImageContent from 'Components/Common/Cards/ActionCardImageContent';
import SelectableActionCard from 'Components/Common/Cards/SelectableActionCard';
import ColorDot from 'Components/Common/ColorDot';
import useVideoDispatch from 'Hooks/Redux/useVideoDispatch';
import { UserClaimFlag } from 'Models/User';
import Video, { VideoStatus } from 'Models/Videos/Video';
import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { AppUrl } from 'Utils/Http/AppUrls';

interface VideoCardProps {
	video: Video;
	isSelected: boolean;
	onSelectedChanged: (selected: boolean, id: number) => void;
	onDeleteButtonClicked: (video: Video) => void;
	onEditButtonClicked: (video: Video) => void;
	onDownloadButtonClicked: (videoId: number) => void;
	onRefreshButtonClicked: (video: Video) => void;
}

const VideoCard = (props: VideoCardProps): ReactElement => {
	const { dispatchSelectVideo } = useVideoDispatch();
	const history = useHistory();

	const getColorDot = (): string => {
		switch (props.video.videoStatus) {
			case VideoStatus.Processing:
				return colors.blue[600];
			case VideoStatus.Completed:
				return colors.green[600];
			case VideoStatus.Failed:
				return colors.red[600];
		}
	};

	const handleSelectionChanged = (checked: boolean): void => {
		props.onSelectedChanged(checked, props.video.id);
	};

	const handleCardClick = (): void => {
		dispatchSelectVideo(props.video.id);
		history.push(AppUrl.Editor);
	};

	const handleRefreshButtonClicked = () => {
		props.onRefreshButtonClicked(props.video);
	};

	const handleEditButtonClicked = () => {
		props.onEditButtonClicked(props.video);
	};

	const handleDeleteButtonClicked = () => {
		props.onDeleteButtonClicked(props.video);
	};

	const handleDownloadButtonClicked = (): void => {
		props.onDownloadButtonClicked(props.video.id);
	};

	return (
		<SelectableActionCard
			isSelected={props.isSelected}
			onSelectionChanged={handleSelectionChanged}
			onClick={handleCardClick}
			claimFlag={UserClaimFlag.BrandWrite}
			title={props.video.name}
			actions={
				<>
					<IconButton onClick={handleRefreshButtonClicked}>
						<CachedIcon />
					</IconButton>
					<EditIconButton onClick={handleEditButtonClicked} />
					<DeleteIconButton onClick={handleDeleteButtonClicked} />
					<IconButton onClick={handleDownloadButtonClicked}>
						<CloudDownloadIcon />
					</IconButton>
				</>
			}
		>
			<ActionCardImageContent imageUrl={null} size="small" /> {/* TODO: Implment thumbnail image*/}
			<Typography variant="h5" component="h2" noWrap>
				<ColorDot color={getColorDot()} />
				{props.video.name}
			</Typography>
			<Typography variant="subtitle2" component="span">
				{props.video.hash}
			</Typography>
		</SelectableActionCard>
	);
};

export default VideoCard;
