import { Card, CardActions, createStyles, Tooltip } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import ClaimPermissionComponent from 'Components/Common/ClaimPermissionComponent';
import useCommonClasses from 'Hooks/Styles/useCommonClasses';
import { UserClaimFlag } from 'Models/User';
import { ChangeEvent, ReactElement, ReactNode } from 'react';

interface SelectableActionCardProps {
	claimFlag?: UserClaimFlag;
	isSelected: boolean;
	onSelectionChanged: (selected: boolean) => void;
	onClick: () => void;
	children: ReactNode;
	actions?: ReactNode;
	title?: string;
}

const useClasses = makeStyles(
	createStyles({
		actionsContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
		},
	})
);

const SelectableActionCard = (props: SelectableActionCardProps): ReactElement => {
	const commonClasses = useCommonClasses();
	const classes = useClasses();

	const handleSelectedCheckboxChanged = (event: ChangeEvent<HTMLInputElement>): void => {
		const { checked } = event.target;
		props.onSelectionChanged(checked);
	};

	return (
		<Card className={commonClasses.cardBackground}>
			<ClaimPermissionComponent claimFlag={props.claimFlag}>
				<div className={classNames([commonClasses.relative, commonClasses.fullSize])}>
					<Checkbox
						checked={props.isSelected}
						onChange={handleSelectedCheckboxChanged}
						color="primary"
						className={commonClasses.absolute}
					/>
				</div>
			</ClaimPermissionComponent>

			<CardActionArea onClick={props.onClick}>
				{props.title ? (
					<Tooltip title={props.title} placement="top">
						<CardContent>{props.children}</CardContent>
					</Tooltip>
				) : (
					<CardContent>{props.children}</CardContent>
				)}
			</CardActionArea>
			{props.actions && <CardActions className={classes.actionsContainer}>{props.actions}</CardActions>}
		</Card>
	);
};

export default SelectableActionCard;
