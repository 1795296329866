import BrandActivationTable from 'Components/BrandActivation/BrandActivationTable';
import CreateBrandActivationModal from 'Components/BrandActivation/CreateBrandActivationModal';
import { MainMenuListItemSubItem } from 'Components/Layout/MainMenuListItem';
import useFetchBrandActivations from 'Hooks/Http/Brands/useFetchBrandActivations';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import Layout from 'Layout';
import BrandActivation, { BRAND_ACTIVATION_STATUS_PROCESSING } from 'Models/Brands/BrandActivation';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import getUpdateBrandActivationRequest from 'Utils/Http/Requests/Brands/BrandActivations/GetUpdateBrandActivationRequest';

const BrandActivationsView: FC = () => {
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const wasBrandActivationsStatusUpdatedRef = useRef(false);

	const token = useAppSelector((x) => x.profile.token);
	const { data, loading, setData } = useFetchBrandActivations();

	const sortedData = useMemo(() => [...(data?.brandActivations ?? [])].reverse(), [data?.brandActivations]);

	useEffect(() => {
		// The effect loads updated data for all activations currently processing. This triggers loading new data from Visua, updating our database and updating records in the table.
		if (data === null || token === null) return;
		if (wasBrandActivationsStatusUpdatedRef.current) return;
		wasBrandActivationsStatusUpdatedRef.current = true;

		const brandActivationsToCheck = data.brandActivations.filter(
			(x) => x.status === BRAND_ACTIVATION_STATUS_PROCESSING
		);
		for (let i = 0; i < brandActivationsToCheck.length; i++) {
			getUpdateBrandActivationRequest(token, brandActivationsToCheck[i].id).then((response) => {
				setData((prev) => {
					if (prev === null) return null;
					const idx = prev.brandActivations.findIndex((x) => x.id === response.data.id);
					if (idx < 0) return prev;
					const copiedPrevBrandActivations = [...prev.brandActivations];
					copiedPrevBrandActivations[idx] = response.data;
					return {
						brandActivations: copiedPrevBrandActivations,
					};
				});
				brandActivationsToCheck[i] = response.data;
			});
		}
	}, [data, setData, token]);

	const handleCreateNewClicked = (): void => {
		setIsCreateModalOpen(true);
	};

	const handleCreateModalClosed = (): void => {
		setIsCreateModalOpen(false);
	};

	const handleBrandActivationCreated = (brandActivation: BrandActivation): void => {
		setData((prev) => {
			if (prev === null) return prev;
			const copiedBrandActivations = [...prev.brandActivations];
			copiedBrandActivations.push(brandActivation);
			return {
				brandActivations: copiedBrandActivations,
			};
		});
	};

	const layoutSubItems: MainMenuListItemSubItem[] = [{ primary: 'Create New', onClick: handleCreateNewClicked }];

	return (
		<Layout title="Brand Activations" subItems={layoutSubItems}>
			<BrandActivationTable brandActivations={sortedData} isLoading={loading} />
			<CreateBrandActivationModal
				isOpen={isCreateModalOpen}
				onClose={handleCreateModalClosed}
				onBrandActivationCreated={handleBrandActivationCreated}
			/>
		</Layout>
	);
};

export default BrandActivationsView;
