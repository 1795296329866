import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useLayoutClasses = makeStyles(({ mixins, spacing }: Theme) =>
	createStyles({
		appBarSpacer: mixins.toolbar,
		contentContainer: {
			flexGrow: 1,
			height: '100vh',
			overflow: 'hidden',
		},
		content: {
			overflow: 'scroll',
			'overflow-x': 'auto',
			padding: spacing(3),
			maxHeight: `calc(100% - (${mixins.toolbar.minHeight}px + ${spacing(1)}px))`,
		},
	})
);

export default useLayoutClasses;
