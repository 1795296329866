import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import SaveModal from 'Components/Common/Modals/SaveModal';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import BrandGroup from 'Models/Brands/BrandGroup';
import { ChangeEvent, ReactElement, useState } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import postBrandGroupRequest from 'Utils/Http/Requests/Brands/PostMergeBrandGroupRequest';

interface CreateBrandGroupModalProps {
	selectedGroupIds: number[];
	isOpen: boolean;
	onClose: () => void;
	onBrandGroupCreated: (group: BrandGroup) => void;
}

const CreateBrandGroupModal = (props: CreateBrandGroupModalProps): ReactElement => {
	const [name, setName] = useState('');

	const token = useAppSelector((state) => state.profile.token);
	const { dispatchSetMessageAction } = useMessagesDispatch();
	const { dispatchStartLoading, dispatchStopLoading } = useLoadingDispatch();

	const handleNameInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
		setName(event.target.value);
	};

	const handleSaveClicked: () => void = async () => {
		if (name.length < 3) {
			dispatchSetMessageAction('Name should have at least three characters.', MessageStatusLevel.Warning, 5000);
			return;
		}
		if (props.selectedGroupIds.length < 2) {
			dispatchSetMessageAction('At least 2 groups must be selected for merging.', MessageStatusLevel.Warning, 5000);
			return;
		}

		dispatchStartLoading();
		try {
			const response = await postBrandGroupRequest(token, name, props.selectedGroupIds);
			props.onBrandGroupCreated(response.data);
			props.onClose();
		} catch (e) {
			dispatchSetMessageAction(`${e.code}: ${e.message}`, MessageStatusLevel.Error, 5000);
		} finally {
			dispatchStopLoading();
		}
	};

	return (
		<SaveModal open={props.isOpen} onClose={props.onClose} onSave={handleSaveClicked}>
			<Typography variant="h5">Merge selected brand groups</Typography>
			<Divider />
			<FormControl margin="normal" fullWidth={true} required={true}>
				<InputLabel>Name</InputLabel>
				<Input onChange={handleNameInputChanged} type="text" value={name} />
			</FormControl>
		</SaveModal>
	);
};

export default CreateBrandGroupModal;
