import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useLoginViewClasses = makeStyles((theme: Theme) =>
	createStyles({
		main: {
			width: 'auto',
			display: 'block', // Fix IE 11 issue.
			marginLeft: theme.spacing(3),
			marginRight: theme.spacing(3),
			[theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
				width: 400,
				marginLeft: 'auto',
				marginRight: 'auto',
			},
		},
		card: {
			marginTop: theme.spacing(8),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
		},
		avatar: {
			margin: theme.spacing(1),
			backgroundColor: theme.palette.secondary.main,
		},
		form: {
			width: '100%', // Fix IE 11 issue.
			marginTop: theme.spacing(1),
		},
		submit: {
			marginTop: theme.spacing(3),
		},
		error: {
			backgroundColor: theme.palette.error.dark,
		},
		message: {
			display: 'flex',
			alignItems: 'center',
		},
		icon: {
			fontSize: 20,
			opacity: 0.9,
			marginRight: theme.spacing(1),
		},
	})
);

export default useLoginViewClasses;
