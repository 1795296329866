import { AxiosPromise } from 'axios';
import Brand from 'Models/Brands/Brand';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export type PutDeactivateBrandResponse = Brand;

export type PutDeactivateBrandRequest = (
	token: string | null,
	brandId: number
) => AxiosPromise<PutDeactivateBrandResponse>;

const putDeactivateBrandRequest: PutDeactivateBrandRequest = (token, brandId) => {
	const url = getApiUrl(ApiUrl.BrandsBrandDeactivate).replace(':brandId', brandId.toString());
	const axios = getAxiosInstance(token);
	return axios.put<PutDeactivateBrandResponse>(url);
};

export default putDeactivateBrandRequest;
