import { Button } from '@material-ui/core';
import Modal, { ModalProps } from 'Components/Common/Modals/Modal';
import useModalClasses from 'Hooks/Styles/Common/useModalClasses';
import React, { PropsWithChildren, ReactElement } from 'react';

interface SaveModalProps extends ModalProps {
	onSave: () => void;
	saveButtonDisabled?: boolean;
}

const SaveModal = (props: PropsWithChildren<SaveModalProps>): ReactElement => {
	const { onSave, children, saveButtonDisabled, ...restProps } = props;

	const classes = useModalClasses();

	const handleSaveButtonClicked = (event: React.MouseEvent<HTMLButtonElement>): void => {
		event.preventDefault();
		onSave();
	};

	const handleCloseButtonClicked = (event: React.MouseEvent<HTMLButtonElement>): void => {
		if (restProps.onClose) restProps.onClose(event, 'escapeKeyDown');
	};

	return (
		<Modal {...restProps}>
			<form>
				{children}
				<Button
					variant="contained"
					disabled={saveButtonDisabled}
					onClick={handleSaveButtonClicked}
					color={'primary'}
					className={classes.primaryModalButton}
					type="submit"
				>
					Save
				</Button>
				<Button
					variant="text"
					onClick={handleCloseButtonClicked}
					color={'secondary'}
					className={classes.primaryModalButton}
				>
					Close
				</Button>
			</form>
		</Modal>
	);
};

export default SaveModal;
