import Grid from '@material-ui/core/Grid';
import BrandGroupCard from 'Components/Brand/BrandGroupCard';
import ItemCardsSkeleton from 'Components/Common/ItemCardsSkeleton';
import BrandGroupModal from 'Components/Brand/BrandGroupModal';
import CreateBrandGroupModal from 'Components/Brand/CreateBrandGroupModal';
import Loader from 'Components/Common/Loader';
import SearchBar from 'Components/Common/SearchBar';
import { MainMenuListItemSubItem } from 'Components/Layout/MainMenuListItem';
import useFetchBrandGroups from 'Hooks/Http/Brands/useFetchBrandGroups';
import Layout from 'Layout';
import Brand from 'Models/Brands/Brand';
import BrandGroup from 'Models/Brands/BrandGroup';
import { UserClaimFlag } from 'Models/User';
import { FC, useMemo, useState } from 'react';

const DEFAULT_SKELETON_ITEMS_COUNT = 40;

/**
 * This view displays all the brand groups for given organization. Groups themself hold the brands.
 */
const BrandsView: FC = () => {
	const [openedGroup, setOpenedGroup] = useState<BrandGroup | null>(null);
	const [isBrandGroupModalOpen, setIsBrandGroupModalOpen] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [refresh, setRefresh] = useState(false);
	const [refreshCount, setRefreshCount] = useState(0);
	const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>([]);
	const [isCreateBrandGroupModalOpen, setIsCreateBrandGroupModalOpen] = useState(false);

	const { data, loading, setData } = useFetchBrandGroups({ refresh, forceReload: refreshCount });

	const groups = useMemo(() => {
		const lowerSearchValue = searchValue.toLowerCase();
		const filteredGroups =
			data?.groups.filter(
				(x) =>
					x.name.toLowerCase().includes(lowerSearchValue) ||
					!!x.brands.find((y) => y.name.toLowerCase().includes(lowerSearchValue))
			) ?? [];
		return filteredGroups.sort((a, b) => {
			const aName = a.name.toLowerCase();
			const bName = b.name.toLowerCase();
			if (aName < bName) return -1;
			if (aName > bName) return 1;
			return 0;
		});
	}, [data?.groups, searchValue]);

	const updateBrand = (groupId: number, brand: Brand): void => {
		setData((prev) => {
			if (prev === null) return null;

			const newGroups = prev.groups.map((x) => {
				if (groupId !== x.id) return x;
				return {
					...x,
					brands: x.brands.map((y) => (y.id === brand.id ? brand : y)),
				};
			});
			return { groups: newGroups };
		});
		setOpenedGroup((prev) => {
			if (prev === null) return null;
			return {
				...prev,
				brands: prev.brands.map((x) => (x.id === brand.id ? brand : x)),
			};
		});
	};

	const handleBrandGroupCardClicked = (id: number): void => {
		const group = groups.find((x) => x.id === id);
		if (!!group) {
			setIsBrandGroupModalOpen(true);
			setOpenedGroup(group);
		}
	};

	const handleBrandGroupModalClose = (): void => {
		setIsBrandGroupModalOpen(false);
	};

	const handleCardSelected = (id: number, checked: boolean): void => {
		if (checked) setSelectedGroupIds((prev) => [...prev, id]);
		else setSelectedGroupIds((prev) => prev.filter((x) => x !== id));
	};

	const handleCreateBrandGroupModalClose = (): void => {
		setIsCreateBrandGroupModalOpen(false);
	};

	const handleBrandGroupCreated = (group: BrandGroup): void => {
		setData((prev) => {
			if (prev === null) return null;
			const newData = prev.groups.filter((x) => !selectedGroupIds.includes(x.id));
			newData.push(group);
			return {
				groups: newData,
			};
		});
		setSelectedGroupIds([]);
	};

	const handleBrandRemovedFromGroup = (groupId: number, brandId: number, newGroup: BrandGroup): void => {
		setData((prev) => {
			if (prev === null) return null;

			const newData = prev.groups.map((x) => {
				if (groupId !== x.id) return x;
				return {
					...x,
					brands: x.brands.filter((y) => y.id !== brandId),
				};
			});
			newData.push(newGroup);
			return {
				groups: newData,
			};
		});
		setOpenedGroup((prev) => {
			if (prev === null) return prev;
			return {
				...prev,
				brands: prev.brands.filter((x) => x.id !== brandId),
			};
		});
	};

	const handleBrandDeactivated = (groupId: number, brand: Brand): void => {
		updateBrand(groupId, brand);
	};

	const handleBrandDeleted = (groupId: number, brandId: number): void => {
		setData((prev) => {
			if (prev === null) return null;

			const newGroups = prev.groups.map((x) => {
				if (groupId !== x.id) return x;
				return {
					...x,
					brands: x.brands.filter((y) => y.id !== brandId),
				};
			});
			return { groups: newGroups.filter((x) => x.brands.length > 0) };
		});
		setOpenedGroup((prev) => {
			if (prev === null) return null;
			const brands = prev.brands.filter((x) => x.id !== brandId);
			if (brands.length === 0) return null;
			return {
				...prev,
				brands,
			};
		});
		setIsBrandGroupModalOpen((openedGroup?.brands?.length ?? 0) > 1);
	};

	const handleBrandEdited = (groupId: number, brand: Brand): void => {
		updateBrand(groupId, brand);
	};

	const handleOnBrandGroupEdited = (brandGroup: BrandGroup): void => {
		setData((prev) => {
			if (prev === null) return prev;
			const copiedGroups = [...prev.groups];
			const idx = copiedGroups.findIndex((x) => x.id === brandGroup.id);
			if (idx > -1) copiedGroups[idx] = brandGroup;
			return { groups: copiedGroups };
		});
	};

	const handleSynchronizeClicked = (): void => {
		setRefresh(true);
		setRefreshCount((prev) => prev + 1);
	};

	const handleMergeBrands = (): void => {
		setIsCreateBrandGroupModalOpen(true);
	};

	const layoutSubItems: MainMenuListItemSubItem[] = [
		{ primary: 'Synchronize', onClick: handleSynchronizeClicked, claimFlag: UserClaimFlag.BrandRead },
		{
			primary: 'Merge Brands',
			onClick: handleMergeBrands,
			disabled: selectedGroupIds.length <= 1,
			claimFlag: UserClaimFlag.BrandWrite,
		},
	];

	return (
		<Layout title={'Brands'} subItems={layoutSubItems}>
			<SearchBar onSearchChanged={setSearchValue} />
			<Loader
				isLoading={loading}
				loaderComponent={
					<ItemCardsSkeleton
						elementCount={data?.groups?.length ?? DEFAULT_SKELETON_ITEMS_COUNT}
						rectHeight={146}
						textWidth={64}
					/>
				}
			>
				<Grid container={true} spacing={2}>
					{groups.map((x) => (
						<Grid item={true} xs={12} sm={6} md={4} lg={3} xl={2} key={x.id}>
							<BrandGroupCard
								brandGroup={x}
								onCardClick={handleBrandGroupCardClicked}
								isSelected={selectedGroupIds.includes(x.id)}
								onCardSelected={handleCardSelected}
							/>
						</Grid>
					))}
				</Grid>
			</Loader>
			<BrandGroupModal
				group={openedGroup}
				onClose={handleBrandGroupModalClose}
				isOpen={isBrandGroupModalOpen}
				onBrandRemovedFromGroup={handleBrandRemovedFromGroup}
				onBrandDeactivated={handleBrandDeactivated}
				onBrandDeleted={handleBrandDeleted}
				onBrandEdited={handleBrandEdited}
				onBrandGroupEdited={handleOnBrandGroupEdited}
			/>
			<CreateBrandGroupModal
				selectedGroupIds={selectedGroupIds}
				isOpen={isCreateBrandGroupModalOpen}
				onClose={handleCreateBrandGroupModalClose}
				onBrandGroupCreated={handleBrandGroupCreated}
			/>
		</Layout>
	);
};

export default BrandsView;
