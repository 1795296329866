import { AxiosPromise } from 'axios';
import Video from 'Models/Videos/Video';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

const putVideoProjects = (
	token: string | null,
	videoIds: number[],
	projectId: number | null
): AxiosPromise<Video[]> => {
	const url = getApiUrl(ApiUrl.VideosProjects);
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.put<Video[]>(url, { videoIds, projectId });
};

export default putVideoProjects;
