import { Fade, fade, useTheme } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ElementLoading from 'Components/Common/ElementLoading';
import Video from 'Models/Videos/Video';
import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState, MouseEvent } from 'react';
import { secondsToHHMMSS } from 'Utils/conversions';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

const useStyles = makeStyles(({ spacing, typography, palette }: Theme) =>
	createStyles({
		previewContainer: {
			position: 'fixed',
			zIndex: 10,
		},
		time: {
			margin: spacing(1),
			fontWeight: typography.fontWeightBold,
			color: palette.common.white,
			textShadow: '1px 1px 3px' + palette.grey[500],
			textAlign: 'center',
		},
		canvas: {
			display: 'block',
		},
	})
);

interface VideoProgressBarPreviewTooltipProps {
	/** Top offset for popover. How much above bar it will be located. Default value is 0*/
	topOffset?: number;
	/** How long to wait before showing the preview. Default is 0 */
	enterTimeout?: number;
	/** The size of the preview video. */
	previewVideoSize?: number;
	selectedVideo: Video | null;
	children: ReactNode;
}

const DEFAULT_TOP_OFFSET = 0;
const DEFAULT_VIDEO_SIZE = 160;

const CANVAS_ID = 'previewCanvas';

const VideoProgressBarPreviewTooltip = (props: VideoProgressBarPreviewTooltipProps): ReactElement => {
	const { children, enterTimeout, previewVideoSize, selectedVideo } = props;
	const topOffset = props.topOffset !== undefined ? props.topOffset : DEFAULT_TOP_OFFSET;
	const videoSize = previewVideoSize !== undefined ? previewVideoSize : DEFAULT_VIDEO_SIZE;

	// ----------------------------------- States & Refs & Variables ---------------------------------------------------
	const [xPosition, setXPosition] = useState<number>(0);
	// This is true when preview is actually opened.
	const [isPreviewOpen, setIsPreviewOpen] = useState(false);
	// this is true when we want to preview to open but not yet show. For waiting the enter timeout.
	const [shouldPreviewOpen, setShouldPreviewOpen] = useState(false);

	const wrapperDivRef = useRef<HTMLDivElement | null>(null);
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const canvasPreviewContextRef = useRef<CanvasRenderingContext2D | null>(null);
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const previewContainerRef = useRef<HTMLDivElement | null>(null);

	const classes = useStyles();
	const theme = useTheme();

	const yPosition: number =
		!!wrapperDivRef.current && previewContainerRef.current
			? wrapperDivRef.current.offsetTop - (previewContainerRef.current.clientHeight + topOffset)
			: 0;

	const setCanvasRef = (ref: HTMLCanvasElement) => {
		canvasRef.current = ref;
		if (ref) {
			canvasPreviewContextRef.current = ref.getContext('2d');
		}
	};

	const getHoveringTime = useCallback((): number | null => {
		if (!videoRef.current || !wrapperDivRef.current) {
			return null;
		}
		if (isNaN(videoRef.current.duration)) return null;

		const positionOnBar = xPosition + videoSize / 2 - wrapperDivRef.current.offsetLeft;
		const x = positionOnBar / wrapperDivRef.current.clientWidth;
		return Math.round(videoRef.current.duration * x);
	}, [xPosition, videoSize]);

	const renderFrameToCanvas = useCallback((): void => {
		if (!videoRef.current || !canvasRef.current || !canvasPreviewContextRef.current) {
			return;
		}

		const hoveringTime = getHoveringTime();
		if (hoveringTime === null) {
			return;
		}

		videoRef.current.currentTime = hoveringTime;

		canvasPreviewContextRef.current.drawImage(
			videoRef.current,
			0,
			0,
			canvasRef.current.width,
			canvasRef.current.height
		);
	}, [getHoveringTime]);

	useEffect(() => {
		if (shouldPreviewOpen) {
			const timeout = !!enterTimeout ? enterTimeout : 0;
			const t = setTimeout(() => setIsPreviewOpen(true), timeout);
			return () => clearTimeout(t);
		} else {
			setIsPreviewOpen(false);
		}
		return;
	}, [shouldPreviewOpen, enterTimeout]);

	const handleMouseMove = useCallback(
		(event: MouseEvent<HTMLDivElement>) => {
			setXPosition(event.pageX - videoSize / 2);
			renderFrameToCanvas();
		},
		[videoSize, renderFrameToCanvas]
	);

	const handleMouseEnter = useCallback(
		(event: MouseEvent<HTMLDivElement>) => {
			setXPosition(event.pageX - videoSize / 2);
			setShouldPreviewOpen(true);
			renderFrameToCanvas();
		},
		[renderFrameToCanvas, videoSize]
	);

	const handleMouseLeave = useCallback(() => {
		setShouldPreviewOpen(false);
	}, []);

	const renderedPreview = useMemo((): JSX.Element | null => {
		const hoveringTime = getHoveringTime();
		const formattedHoveringTime = hoveringTime !== null ? secondsToHHMMSS(hoveringTime) : 0;

		const isLoading = !videoRef.current || videoRef.current.currentTime !== hoveringTime;

		return (
			<div style={{ left: xPosition, top: yPosition }} className={classes.previewContainer} ref={previewContainerRef}>
				<Fade in={isPreviewOpen}>
					<div>
						<ElementLoading loading={isLoading} background={fade(theme.palette.grey.A400, 0.3)}>
							<canvas
								id={CANVAS_ID}
								width={videoSize}
								height={videoSize}
								ref={setCanvasRef}
								className={classes.canvas}
							/>
						</ElementLoading>
						<div className={classes.time}>{formattedHoveringTime}</div>
					</div>
				</Fade>
			</div>
		);
	}, [
		getHoveringTime,
		classes.previewContainer,
		isPreviewOpen,
		videoSize,
		classes.canvas,
		classes.time,
		theme.palette.grey.A400,
		xPosition,
		yPosition,
	]);

	const renderedHiddenVideo = useMemo((): JSX.Element | null => {
		if (!selectedVideo) {
			return null;
		}

		return (
			<video height={0} width={0} ref={videoRef}>
				<source src={getApiUrl(ApiUrl.VideosVideoMedia).replace(':videoGuid', selectedVideo.guid)} type="video/mp4" />
			</video>
		);
	}, [selectedVideo]);

	return useMemo(
		(): JSX.Element => (
			<>
				<div
					onMouseMove={handleMouseMove}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					ref={wrapperDivRef}
				>
					{children}
				</div>
				{renderedPreview}
				{renderedHiddenVideo}
			</>
		),
		[handleMouseMove, handleMouseEnter, handleMouseLeave, wrapperDivRef, children, renderedPreview, renderedHiddenVideo]
	);
};

export default VideoProgressBarPreviewTooltip;
