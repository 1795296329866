import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import SaveModal from 'Components/Common/Modals/SaveModal';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import BrandActivation from 'Models/Brands/BrandActivation';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import postBrandActivationRequest, {
	PostBrandActivationRequestProps,
} from 'Utils/Http/Requests/Brands/BrandActivations/PostBrandActivationRequest';
import CloseIcon from '@material-ui/icons/Close';

interface CreateBrandActivationModalProps {
	isOpen: boolean;
	onClose: () => void;
	onBrandActivationCreated: (brandActivation: BrandActivation) => void;
}

const CreateBrandActivationModal = (props: CreateBrandActivationModalProps): ReactElement => {
	const [name, setName] = useState('');
	const [localizedName, setLocalizedName] = useState<string | null>(null);
	const [url, setUrl] = useState('');
	const [exampleImages, setExampleImages] = useState<File[]>([]);

	const token = useAppSelector((x) => x.profile.token);
	const { dispatchSetAxiosErrorMessage } = useMessagesDispatch();
	const { dispatchStopLoading, dispatchStartLoading } = useLoadingDispatch();

	const isSaveButtonDisabled = name === '' || url === '';

	const handleModalExited = (): void => {
		setName('');
		setLocalizedName(null);
		setUrl('');
		setExampleImages([]);
	};

	const handleModalSaveClicked: () => Promise<void> = async () => {
		const request: PostBrandActivationRequestProps = {
			exampleUrl: url,
			name,
			localizedName,
		};

		dispatchStartLoading();
		try {
			const response = await postBrandActivationRequest(token, request, exampleImages);
			props.onBrandActivationCreated(response.data);
			props.onClose();
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		} finally {
			dispatchStopLoading();
		}
	};

	const handleNameInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
		setName(event.target.value);
	};

	const handleLocalizedNameInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
		if (!!event.target.value) setLocalizedName(event.target.value);
		else setLocalizedName(null);
	};

	const handleUrlInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
		setUrl(event.target.value);
	};

	const handleImageFiledChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const { files } = event.target;
		if (files === null) return;

		const filesArray: File[] = [];
		for (let i = 0; i < files.length; i++) filesArray.push(files[i]);

		setExampleImages((prev) => [...prev, ...filesArray]);
	};

	const handleRemoveUploadedImage = (
		event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>,
		idx: number
	): void => {
		setExampleImages((prev) => {
			const copiedFiles = [...prev];
			copiedFiles.splice(idx, 1);
			return copiedFiles;
		});
	};

	return (
		<SaveModal
			open={props.isOpen}
			onClose={props.onClose}
			onModalExited={handleModalExited}
			onSave={handleModalSaveClicked}
			saveButtonDisabled={isSaveButtonDisabled}
		>
			<Typography variant="h5">Create new brand activation</Typography>
			<Divider />
			<List>
				<ListItem>
					<FormControl fullWidth={true}>
						<InputLabel required={true}>Name</InputLabel>
						<Input onChange={handleNameInputChanged} type="text" value={name} required={true} />
					</FormControl>
				</ListItem>
				<ListItem>
					<FormControl fullWidth={true}>
						<InputLabel>Localized name</InputLabel>
						<Input onChange={handleLocalizedNameInputChanged} type="text" value={localizedName} />
					</FormControl>
				</ListItem>
				<ListItem>
					<FormControl fullWidth={true}>
						<InputLabel required={true}>Url</InputLabel>
						<Input onChange={handleUrlInputChanged} type="text" value={url} required={true} />
					</FormControl>
				</ListItem>
				<ListItem>
					<FormControl fullWidth={true}>
						<input
							accept={`image/*`}
							style={{ display: 'none' }}
							id="contained-button-file"
							multiple={true}
							type="file"
							onChange={handleImageFiledChange}
						/>
						<label htmlFor="contained-button-file">
							<Button variant="contained" component="span">
								Upload example images
							</Button>
						</label>
						{exampleImages.map((x, i) => (
							<Typography variant="subtitle2" color="textSecondary" key={x.name}>
								{x.name}
								<IconButton color="inherit" onClick={(e) => handleRemoveUploadedImage(e, i)}>
									<CloseIcon />
								</IconButton>
							</Typography>
						))}
					</FormControl>
				</ListItem>
			</List>
		</SaveModal>
	);
};

export default CreateBrandActivationModal;
