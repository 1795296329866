/**
 * This hook loads and save user data to redux state. If request returns 401 it redirects to login page.
 */
import useFetchUser from 'Hooks/Http/Profile/useFetchUser';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useProfileDispatch from 'Hooks/Redux/useProfileDispatch';
import { useEffect } from 'react';

const useFetchUserAndSaveToRedux = (): void => {
	const { data, loading } = useFetchUser();
	const { dispatchSetUser } = useProfileDispatch();
	const { dispatchStopLoading, dispatchStartLoading } = useLoadingDispatch();

	useEffect(() => {
		if (data === null) return;
		dispatchSetUser(data);
	}, [data, dispatchSetUser]);

	useEffect(() => {
		if (loading) {
			dispatchStartLoading('Loading user data');
		} else {
			dispatchStopLoading();
		}
	}, [dispatchStartLoading, dispatchStopLoading, loading]);
};

export default useFetchUserAndSaveToRedux;
