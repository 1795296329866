import useAppDispatch from 'Hooks/Redux/useAppDispatch';
import { useCallback } from 'react';
import { LoadingActionType } from 'Redux/Actions/Loading';

type UseLoadingDispatch = () => {
	dispatchStartLoading: (title?: string) => void;
	dispatchStopLoading: () => void;
};

const useLoadingDispatch: UseLoadingDispatch = () => {
	const appDispatch = useAppDispatch();

	const dispatchStartLoading = useCallback(
		(title?: string): void => appDispatch({ type: LoadingActionType.StartLoading, title }),
		[appDispatch]
	);

	const dispatchStopLoading = useCallback((): void => appDispatch({ type: LoadingActionType.StopLoading }), [
		appDispatch,
	]);

	return {
		dispatchStartLoading,
		dispatchStopLoading,
	};
};

export default useLoadingDispatch;
