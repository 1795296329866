import { List } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import ClaimPermissionComponent from 'Components/Common/ClaimPermissionComponent';
import useMainMenuListItemClasses from 'Hooks/Styles/Layout/useMainMenuListItemClasses';
import { UserClaimFlag } from 'Models/User';
import { ReactElement, useState } from 'react';
import { NavLink } from 'react-router-dom';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

export interface MainMenuListItemSubItem {
	primary: string;
	secondary?: string;
	onClick: () => void;
	disabled?: boolean;
	claimFlag?: UserClaimFlag;
}

interface MainMenuListItemProps {
	title: string;
	to: string;
	icon: JSX.Element;
	allowSubItems: boolean;
	subItems?: MainMenuListItemSubItem[];
	claimFlag?: UserClaimFlag;
}

const MainMenuListItem = (props: MainMenuListItemProps): ReactElement => {
	const isSelected = window.location.pathname.toLowerCase() === props.to.toLowerCase();
	const hasSubItems = !!props.subItems && props.subItems?.length > 0;
	const displaySubItems = hasSubItems && isSelected && props.allowSubItems;

	const [isSubListExpanded, setIsSubListExpanded] = useState(hasSubItems && isSelected);

	const classes = useMainMenuListItemClasses();
	const NavLinkListItemPrimaryTypographyClasses = classNames({
		[`${classes.navLinkSelected}`]: isSelected,
	});

	const handleListItemClicked = (): void => {
		setIsSubListExpanded((prev) => !prev);
	};

	const renderSubItems = (): JSX.Element | null => {
		if (!displaySubItems || !props.subItems) return null;

		return (
			<Collapse in={isSubListExpanded}>
				<List className={classes.nestedList}>
					{props.subItems.map((x, i) => (
						<ClaimPermissionComponent claimFlag={x.claimFlag} key={i}>
							<ListItem button={true} onClick={x.onClick} disabled={x.disabled}>
								<ListItemIcon>
									<KeyboardArrowRightIcon />
								</ListItemIcon>
								<ListItemText primary={x.primary} secondary={x.secondary} />
							</ListItem>
						</ClaimPermissionComponent>
					))}
				</List>
			</Collapse>
		);
	};

	const renderCollapseIcon = (): JSX.Element | null => {
		if (!displaySubItems) return null;
		return isSubListExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />;
	};

	return (
		<ClaimPermissionComponent claimFlag={props.claimFlag}>
			<NavLink to={props.to} className={classes.navLink}>
				<ListItem button={true} selected={isSelected} onClick={handleListItemClicked}>
					<ListItemIcon>{props.icon}</ListItemIcon>
					<ListItemText
						primary={props.title}
						primaryTypographyProps={{ className: NavLinkListItemPrimaryTypographyClasses }}
					/>
					{renderCollapseIcon()}
				</ListItem>
			</NavLink>
			{renderSubItems()}
		</ClaimPermissionComponent>
	);
};

export default MainMenuListItem;
