import { AxiosPromise } from 'axios';
import EditorVideoDetection from 'Models/Videos/Detections/EditorVideoDetection';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

const getEditorDetections = (token: string | null, videoId: number): AxiosPromise<EditorVideoDetection[]> => {
	const url = getApiUrl(ApiUrl.VideosVideoDetectionsEditor).replace(':videoId', videoId.toString());
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.get<EditorVideoDetection[]>(url);
};

export default getEditorDetections;
