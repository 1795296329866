import { Fade } from '@material-ui/core';
import { PropsWithChildren, ReactElement } from 'react';

export interface LoaderProps {
	loaderComponent: JSX.Element;
	isLoading: boolean;
}

const Loader = (props: PropsWithChildren<LoaderProps>): ReactElement => {
	const showLoader = props.isLoading;
	const showChildren = !props.isLoading;

	return (
		<div>
			<Fade in={showLoader} mountOnEnter={true} unmountOnExit={true}>
				<div>{props.loaderComponent}</div>
			</Fade>
			<Fade in={showChildren} mountOnEnter={true} unmountOnExit={true}>
				<div>{props.children}</div>
			</Fade>
		</div>
	);
};

export default Loader;
