import useAppSelector from 'Hooks/Redux/useAppSelector';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import useVideoDispatch from 'Hooks/Redux/useVideoDispatch';
import { useCallback, useEffect, useRef } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import { VideoFileToUpload, VideoFileToUploadStatus } from 'Redux/Reducers/Video';
import postVideoRequest, {
	POST_VIDEO_REQUEST_CANCEL_MESSAGE,
	PostVideoRequest,
} from 'Utils/Http/Requests/Videos/PostVideoRequest';

const PROGRESS_UPDATE_TICK_INTERVAL = 5000;

const useUploadVideosInQueue = (): void => {
	const progressTick = useRef(Date.now());

	const videosToUpload = useAppSelector<VideoFileToUpload[]>((state) => state.video.videoFilesToUpload);
	const token = useAppSelector<string | null>((state) => state.profile.token);

	const { dispatchSetAxiosErrorMessage, dispatchSetMessageAction } = useMessagesDispatch();
	const {
		dispatchStartUploadingVideo,
		dispatchCompleteVideoUpload,
		dispatchUpdateVideoUploadProgress,
	} = useVideoDispatch();

	const isUpdatingVideo = videosToUpload.find((x) => x.status === VideoFileToUploadStatus.Uploading) !== undefined;

	const onProgressUpdated = useCallback(
		(id: string, event: ProgressEvent): void => {
			const tick = Date.now();
			if (tick - progressTick.current < PROGRESS_UPDATE_TICK_INTERVAL) return;

			const progress = Math.round((event.loaded / event.total) * 100);
			dispatchUpdateVideoUploadProgress(id, progress);
		},
		[dispatchUpdateVideoUploadProgress]
	);

	const startUploadingFile: (videoToUpload: VideoFileToUpload) => Promise<void> = useCallback(
		async (videoToUpload) => {
			dispatchSetMessageAction(`Starting to upload video ${videoToUpload.videoFile.name}`, MessageStatusLevel.Info);
			dispatchStartUploadingVideo(videoToUpload.id);

			console.log(videoToUpload.folderId);
			const data: PostVideoRequest = { folderId: videoToUpload.folderId };
			try {
				await postVideoRequest(
					token,
					data,
					videoToUpload.videoFile,
					(e) => onProgressUpdated(videoToUpload.id, e),
					videoToUpload.cancelTokenSource.token
				);
				dispatchSetMessageAction(
					`Successfully uploaded video ${videoToUpload.videoFile.name}`,
					MessageStatusLevel.Success
				);
				dispatchCompleteVideoUpload(videoToUpload.id, true);
			} catch (e) {
				if (e.message !== POST_VIDEO_REQUEST_CANCEL_MESSAGE) {
					dispatchSetAxiosErrorMessage(e);
					dispatchCompleteVideoUpload(videoToUpload.id, false);
				}
			}
		},
		[
			dispatchCompleteVideoUpload,
			dispatchSetAxiosErrorMessage,
			dispatchSetMessageAction,
			dispatchStartUploadingVideo,
			onProgressUpdated,
			token,
		]
	);

	useEffect(() => {
		// Only run this effect if we currently do not upload any video.
		if (isUpdatingVideo) return;

		for (let i = 0; i < videosToUpload.length; i++) {
			const videoFileToUpload = videosToUpload[i];
			if (videoFileToUpload.status === VideoFileToUploadStatus.Queued) {
				startUploadingFile(videoFileToUpload).then((r) => r);
				return;
			}
		}
	}, [isUpdatingVideo, startUploadingFile, videosToUpload]);

	useEffect(() => {
		if (!isUpdatingVideo) return;

		window.onbeforeunload = () => true;
		return () => {
			window.onbeforeunload = null;
		};
	}, [isUpdatingVideo]);
};

export default useUploadVideosInQueue;
