import { AxiosPromise } from 'axios';
import VideoDetectionPlacement from 'Models/Videos/VideoDetectionPlacement';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

const putVideoDetectionPlacement = (
	token: string | null,
	placementId: number,
	placement: Omit<VideoDetectionPlacement, 'id' | 'visuaId'>
): AxiosPromise<VideoDetectionPlacement> => {
	const url = getApiUrl(ApiUrl.PlacementsPlacement).replace(':placementId', placementId.toString());
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.put(url, placement);
};

export default putVideoDetectionPlacement;
