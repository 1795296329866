import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import SaveModal from 'Components/Common/Modals/SaveModal';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import Brand from 'Models/Brands/Brand';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { putBrand } from 'Utils/Http/Requests/Brands/PutBrand';

interface BrandEditModalProps {
	isOpen: boolean;
	onClose: () => void;
	onBrandEdited: (brand: Brand) => void;
	brand: Brand | null;
}

const BrandEditModal = (props: BrandEditModalProps): ReactElement => {
	const [name, setName] = useState<string>(props.brand?.name ?? '');

	const token = useAppSelector((x) => x.profile.token);
	const { dispatchSetAxiosErrorMessage } = useMessagesDispatch();
	const { dispatchStopLoading, dispatchStartLoading } = useLoadingDispatch();

	const isSaveButtonDisabled = name === '';

	useEffect(() => {
		if (!props.isOpen || props.brand === null) return;

		setName(props.brand?.name);
	}, [props.brand, props.isOpen]);

	const handleModalExited = (): void => {
		setName('');
	};

	const handleModalSaveClicked: () => void = async () => {
		if (props.brand === null) return;

		const request = { name };
		dispatchStartLoading();
		try {
			const response = await putBrand(token, props.brand.id, request);
			props.onBrandEdited(response.data);
			props.onClose();
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		} finally {
			dispatchStopLoading();
		}
	};

	const handleNameInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
		setName(event.target.value);
	};

	return (
		<SaveModal
			open={props.isOpen}
			onClose={props.onClose}
			onModalExited={handleModalExited}
			onSave={handleModalSaveClicked}
			saveButtonDisabled={isSaveButtonDisabled}
		>
			<Typography variant="h5">Edit brand</Typography>
			<Divider />
			<List>
				<ListItem>
					<FormControl fullWidth={true}>
						<InputLabel required={true}>Name</InputLabel>
						<Input onChange={handleNameInputChanged} type="text" value={name} required={true} />
					</FormControl>
				</ListItem>
			</List>
		</SaveModal>
	);
};

export default BrandEditModal;
