import VideoDetectionCoordinate from 'Models/Videos/Detections/VideoDetectionCoordinate';

export interface EditorVideoNewDetectionCoordinate {
	startTime: number;
	endTime: number;
	coordinate: VideoDetectionCoordinate;
}

export interface EditorVideoNewDetection {
	coordinates: EditorVideoNewDetectionCoordinate[];
	brandId: number;
	placementId: number;
	videoId: number;
}

export interface EditorVideoNewDetectionWithNullableIds
	extends Omit<EditorVideoNewDetection, 'brandId' | 'placementId'> {
	brandId: number | null;
	placementId: number | null;
	selected: boolean;
}

export const isEditorVideoNewDetection = (
	value: EditorVideoNewDetectionWithNullableIds | EditorVideoNewDetection
): value is EditorVideoNewDetection => {
	return value.brandId !== null && value.placementId !== null;
};

export default EditorVideoNewDetection;
