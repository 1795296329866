import { User } from 'Models/User';
import { AppAction } from 'Redux/Actions';
import { LoginAction, ProfileActionType, SetUserAction } from 'Redux/Actions/Profile';
import { getToken } from 'Utils/LocalStorageHelper';

export interface ProfileState {
	token: string | null;
	user: User | null;
}

const initialState: ProfileState = {
	token: getToken(),
	user: null,
};

const login = (state: ProfileState, { token }: LoginAction): ProfileState => ({
	...state,
	token,
});

const setUser = (state: ProfileState, { user }: SetUserAction): ProfileState => ({
	...state,
	user,
});

const profile = (state: ProfileState = initialState, action: AppAction): ProfileState => {
	switch (action.type) {
		case ProfileActionType.Login:
			return login(state, action);
		case ProfileActionType.SetUser:
			return setUser(state, action);

		default:
			return state;
	}
};

export default profile;
