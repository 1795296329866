import { InputLabel, MenuItem, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { isNumber } from '@material-ui/data-grid';
import EditorDetectionPlacementLabel from 'Components/Common/Labels/EditorDetectionPlacementLabel';
import ConfirmModal from 'Components/Common/Modals/ConfirmModal';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import VideoDetectionPlacement from 'Models/Videos/VideoDetectionPlacement';
import * as React from 'react';
import { ReactElement, ReactNode, useState } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import putChangeAllPlacementsRequest, {
	PutChangeAllPlacementsRequest,
} from 'Utils/Http/Requests/Videos/Detections/PutChangeAllPlacementsRequest';

interface ChangeAllPlacementModalProps {
	open: boolean;
	request: Omit<PutChangeAllPlacementsRequest, 'placementId'>;
	placements: VideoDetectionPlacement[];
	videoId: number;
	onClose: () => void;
	onDetectionChanged: () => void;
}

const ChangeAllPlacementModal = (props: ChangeAllPlacementModalProps): ReactElement => {
	const [selectedPlacementId, setSelectedPlacementId] = useState<number | null>(null);

	const token = useAppSelector<string | null>((state) => state.profile.token);
	const { dispatchStartLoading, dispatchStopLoading } = useLoadingDispatch();
	const { dispatchSetMessageAction } = useMessagesDispatch();

	const handleConfirmClicked = async (): Promise<void> => {
		if (selectedPlacementId === null) {
			const errMsg = 'Cannot send request if new placement is not selected';
			console.error(errMsg);
			return;
		}

		const request: PutChangeAllPlacementsRequest = {
			placementId: selectedPlacementId,
			...props.request,
		};

		dispatchStartLoading();
		try {
			await putChangeAllPlacementsRequest(token, props.videoId, request);
			dispatchSetMessageAction('Successfully change all placements', MessageStatusLevel.Success);
			props.onDetectionChanged();
		} catch (e) {
			dispatchSetMessageAction(`An error occurred: ${e}`, MessageStatusLevel.Error);
		} finally {
			dispatchStopLoading();
		}
	};

	const handlePlacementSelectionChanged = ({ target }: React.ChangeEvent<{ value: unknown }>): void => {
		if (isNumber(target.value)) setSelectedPlacementId(target.value);
		else console.error(`expected number but instead got '${target.value}'`);
	};

	const renderValue = (selected: unknown): ReactNode => {
		if (!isNumber(selected)) return null;
		const placement = props.placements.find((x) => x.id === selected);
		if (placement === undefined) return;
		return <EditorDetectionPlacementLabel placement={placement} />;
	};

	return (
		<ConfirmModal
			open={props.open}
			title="Change all placements"
			onConfirmClicked={handleConfirmClicked}
			confirmButtonText="Apply"
			closeButtonText="Cancel"
			onClose={props.onClose}
		>
			<FormControl fullWidth={true} margin="normal">
				<InputLabel id="change-all-placement-placements-label" required={true}>
					New placement
				</InputLabel>
				<Select
					required={true}
					labelId="change-all-placement-placements-label"
					value={selectedPlacementId}
					onChange={handlePlacementSelectionChanged}
					renderValue={renderValue}
				>
					{props.placements.map((x) => (
						<MenuItem key={x.id} value={x.id}>
							<EditorDetectionPlacementLabel placement={x} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</ConfirmModal>
	);
};

export default ChangeAllPlacementModal;
