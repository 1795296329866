import { AxiosPromise } from 'axios';
import useDataFetcher, { UseDataFetcherReturn } from 'Hooks/Http/useDataFetcher';
import EditorVideoDetection from 'Models/Videos/Detections/EditorVideoDetection';
import { useCallback } from 'react';
import getEditorDetections from 'Utils/Http/Requests/Videos/Detections/GetEditorDetections';

const useFetchEditorDetections = (
	videoId: number | null,
	forceRefresh?: number
): UseDataFetcherReturn<EditorVideoDetection[]> => {
	const nonNullableVideoId = videoId ?? 0;

	const fetcher = useCallback(
		(token: string | null): AxiosPromise<EditorVideoDetection[]> => {
			// Bellow line allows us to add forceRefresh param into deps.
			if (forceRefresh !== undefined) console.debug(`Calling editor detections for ${forceRefresh} times.`);
			return getEditorDetections(token, nonNullableVideoId);
		},
		[nonNullableVideoId, forceRefresh]
	);

	return useDataFetcher(fetcher, videoId === null);
};

export default useFetchEditorDetections;
