import { AxiosPromise } from 'axios';
import BrandActivation from 'Models/Brands/BrandActivation';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export type GetUpdateBrandActivationResponse = BrandActivation;

export type GetUpdateBrandActivationRequest = (
	token: string | null,
	id: number
) => AxiosPromise<GetUpdateBrandActivationResponse>;

const getUpdateBrandActivationRequest: GetUpdateBrandActivationRequest = async (token, id) => {
	const url = getApiUrl(ApiUrl.BrandActivationsUpdate).replace(':brandActivationId', id.toString());
	const axios = getAxiosInstance(token);
	return await axios.get<GetUpdateBrandActivationResponse>(url);
};

export default getUpdateBrandActivationRequest;
