import Video from 'Models/Videos/Video';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { secondsToHHMMSS, secondsToHHMMSSWithFrameCount } from 'Utils/conversions';

interface VideoTimecodeProps {
	video: HTMLVideoElement | null;
	selectedVideo: Video | null;
}

const VideoTimecode = (props: VideoTimecodeProps): ReactElement => {
	const { video, selectedVideo } = props;
	const [time, setTime] = useState(0);
	const [totalTime, setTotalTime] = useState(0);

	const handleTimeUpdated = useCallback(
		function (this: HTMLVideoElement): void {
			setTime(this.currentTime);
		},
		[setTime]
	);

	const setVideoDuration = useCallback(
		function (this: HTMLVideoElement): void {
			setTotalTime(this.duration);
		},
		[setTotalTime]
	);

	useEffect(() => {
		if (!video) return;

		video.addEventListener('durationchange', setVideoDuration);
		video.addEventListener('timeupdate', handleTimeUpdated);
		return () => {
			video.removeEventListener('durationchange', setVideoDuration);
			video.removeEventListener('timeupdate', handleTimeUpdated);
		};
	}, [video, handleTimeUpdated, setVideoDuration]);

	if (selectedVideo !== null) {
		return (
			<>
				{secondsToHHMMSSWithFrameCount(time, selectedVideo.fps ?? 24)} /
				{secondsToHHMMSSWithFrameCount(totalTime, selectedVideo.fps ?? 24)}
			</>
		);
	}
	return (
		<>
			{secondsToHHMMSS(time)} / {secondsToHHMMSS(totalTime)}
		</>
	);
};

export default VideoTimecode;
