import { useSelector } from 'react-redux';
import { AppState } from 'Redux/Reducers';

type StateSelector<TSelected> = (state: AppState) => TSelected;

const useAppSelector = <TSelected>(selector: StateSelector<TSelected>): TSelected => {
	return useSelector<AppState, TSelected>(selector);
};

export default useAppSelector;
