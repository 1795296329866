import useDataFetcher, { UseDataFetcherReturn } from 'Hooks/Http/useDataFetcher';
import Video from 'Models/Videos/Video';
import { useCallback } from 'react';
import getVideosProcessingRequest from 'Utils/Http/Requests/Videos/GetVideosProcessingRequest';

const useFetchVideoProcessing = (): UseDataFetcherReturn<Video[]> => {
	const fetcherFunction = useCallback(getVideosProcessingRequest, []);
	return useDataFetcher(fetcherFunction);
};

export default useFetchVideoProcessing;
