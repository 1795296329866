export enum AppUrl {
	Login = '/login',
	Dashboard = '/',
	Brands = '/brands',
	BrandActivations = '/brands/activations',
	Videos = '/videos',
	Editor = '/editor',
	Projects = '/projects',
	Placements = '/placements',
}
