import useAppDispatch from 'Hooks/Redux/useAppDispatch';
import { User } from 'Models/User';
import { useCallback } from 'react';
import { ProfileActionType } from 'Redux/Actions/Profile';

type UseProfileDispatch = () => {
	dispatchLogin: (token: string) => void;
	dispatchSetUser: (user: User) => void;
};

const useProfileDispatch: UseProfileDispatch = () => {
	const appDispatch = useAppDispatch();

	const dispatchLogin = useCallback((token: string): void => appDispatch({ type: ProfileActionType.Login, token }), [
		appDispatch,
	]);

	const dispatchSetUser = useCallback((user: User): void => appDispatch({ type: ProfileActionType.SetUser, user }), [
		appDispatch,
	]);

	return {
		dispatchLogin,
		dispatchSetUser,
	};
};

export default useProfileDispatch;
