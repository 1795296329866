import Typography from '@material-ui/core/Typography';
import ConfirmModal from 'Components/Common/Modals/ConfirmModal';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import Project from 'Models/Project';
import { ReactElement } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import deleteProjectRequest from 'Utils/Http/Requests/Projects/DeleteProjectRequest';

interface DeleteProjectModalProps {
	isOpen: boolean;
	project: Project | null;
	onDeleted: (id: number) => void;
	onClose: () => void;
}

const DeleteProjectModal = (props: DeleteProjectModalProps): ReactElement | null => {
	const token = useAppSelector<string | null>((state) => state.profile.token);
	const { dispatchStopLoading, dispatchStartLoading } = useLoadingDispatch();
	const { dispatchSetAxiosErrorMessage, dispatchSetMessageAction } = useMessagesDispatch();

	const handleConfirmModalConfirmClicked: () => Promise<void> = async () => {
		if (props.project === null) return;
		dispatchStartLoading();
		try {
			await deleteProjectRequest(token, props.project.id);
			dispatchSetMessageAction(`Deleted project "${props.project.name}`, MessageStatusLevel.Success);
			props.onDeleted(props.project.id);
			props.onClose();
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		} finally {
			dispatchStopLoading();
		}
	};

	if (props.project === null) return null;

	return (
		<ConfirmModal
			onConfirmClicked={handleConfirmModalConfirmClicked}
			title={`Delete Project ${props.project.name}`}
			open={props.isOpen}
			onClose={props.onClose}
			confirmButtonText="Delete"
			closeButtonText="Cancel"
		>
			<Typography variant="body1">
				Are you sure you want to delete project{' '}
				<b>
					{props.project.name} [{props.project.id}]
				</b>
				?
			</Typography>
		</ConfirmModal>
	);
};

export default DeleteProjectModal;
