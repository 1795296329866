import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

interface UseLayoutAppBarClassesProps {
	drawerWidth: number;
}

// A Hack to allow passing props and having return. Sadly must define twice.
type Classes = 'toolbar' | 'menuButtonHidden' | 'appBar' | 'menuButton' | 'title' | 'appBarShift';
type UseLayoutAppBarClasses = (props: UseLayoutAppBarClassesProps) => ClassNameMap<Classes>;

const useLayoutAppBarClasses: UseLayoutAppBarClasses = makeStyles(({ zIndex, transitions, spacing }: Theme) =>
	createStyles({
		appBar: {
			zIndex: zIndex.drawer + 20,
			transition: transitions.create(['width', 'margin'], {
				easing: transitions.easing.sharp,
				duration: transitions.duration.leavingScreen,
			}),
		},
		appBarShift: {
			marginLeft: ({ drawerWidth }) => drawerWidth,
			width: ({ drawerWidth }) => `calc(100% - ${drawerWidth}px)`,
			transition: transitions.create(['width', 'margin'], {
				easing: transitions.easing.sharp,
				duration: transitions.duration.enteringScreen,
			}),
		},
		toolbar: {
			paddingRight: spacing(3),
		},
		menuButton: {
			marginLeft: 6,
			marginRight: 36,
		},
		menuButtonHidden: {
			display: 'none',
		},
		title: {
			flexGrow: 1,
		},
	})
);

export default useLayoutAppBarClasses;
