import Button from '@material-ui/core/Button';
import ConditionalDisplay from 'Components/Common/ConditionalDisplay';
import LogoSelectModal from 'Components/Common/Modals/LogoSelectModal';
import Brand from 'Models/Brands/Brand';
import { ReactElement, useState } from 'react';

interface ChangeAllPlacementCardBrandLogoSelectProps {
	displayContent: boolean;
	brand: Brand | null;
	selectedLogoIds: number[];
	onSelectedLogoIdsChanged: (ids: number[]) => void;
}

const ChangeAllPlacementCardBrandLogoSelect = (props: ChangeAllPlacementCardBrandLogoSelectProps): ReactElement => {
	const [isLogoModalOpen, setIsLogoModalOpen] = useState(false);

	const shouldDisplay = props.displayContent && props.brand !== null && props.brand.logos.length > 0;

	const handleLogoButtonClicked = (): void => {
		setIsLogoModalOpen((prev) => !prev);
	};

	const handleLogoSelectModalSaved = (logoIds: number[]): void => {
		props.onSelectedLogoIdsChanged(logoIds);
		setIsLogoModalOpen(false);
	};

	const handleLogoSelectModalClosed = (): void => {
		setIsLogoModalOpen(false);
	};

	return (
		<ConditionalDisplay displayContent={shouldDisplay}>
			<Button variant="contained" onClick={handleLogoButtonClicked}>
				Select logos
			</Button>
			<LogoSelectModal
				logos={props.brand?.logos ?? []}
				onSave={handleLogoSelectModalSaved}
				selectedLogoIds={props.selectedLogoIds}
				open={isLogoModalOpen}
				onClose={handleLogoSelectModalClosed}
			/>
		</ConditionalDisplay>
	);
};

export default ChangeAllPlacementCardBrandLogoSelect;
