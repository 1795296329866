import { Grow } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper, { PopperProps } from '@material-ui/core/Popper/Popper';
import { FC, ReactElement } from 'react';

interface GrowablePopperProps extends Pick<PopperProps, 'open' | 'anchorEl' | 'disablePortal'> {
	children: ReactElement<any, any>;
	onClose: () => void;
}

const GrowablePopper: FC<GrowablePopperProps> = ({ children, anchorEl, open, onClose, disablePortal }) => {
	const handleClickAway = (): void => {
		onClose();
	};

	return (
		<Popper open={open} anchorEl={anchorEl} disablePortal={disablePortal} transition={true}>
			{({ TransitionProps, placement }) => (
				<Grow
					{...TransitionProps}
					style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
					mountOnEnter={false}
				>
					<Paper>
						<ClickAwayListener onClickAway={handleClickAway}>{children}</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>
	);
};

export default GrowablePopper;
