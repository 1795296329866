import { AxiosPromise } from 'axios';
import Project from 'Models/Project';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

const putProjectRequest = (
	token: string | null,
	projectId: number,
	project: Omit<Project, 'id'>
): AxiosPromise<Project> => {
	const url = getApiUrl(ApiUrl.ProjectsProject).replace(':projectId', projectId.toString());
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.put<Project>(url, project);
};

export default putProjectRequest;
