import { Avatar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import useProfileDispatch from 'Hooks/Redux/useProfileDispatch';
import useLoginViewClasses from 'Hooks/Styles/useLoginViewClasses';
import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import { AppUrl } from 'Utils/Http/AppUrls';
import loginRequest from 'Utils/Http/Requests/Profile/LoginRequest';
import { setToken } from 'Utils/LocalStorageHelper';

const LoginView: FC = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const token = useAppSelector((state) => state.profile.token);
	const history = useHistory();
	const classes = useLoginViewClasses();
	const { dispatchLogin } = useProfileDispatch();
	const { dispatchStartLoading, dispatchStopLoading } = useLoadingDispatch();
	const { dispatchSetMessageAction } = useMessagesDispatch();

	useEffect(
		// If user is logged in we want to redirect it to Dashboard.
		() => {
			if (token !== null) {
				history.push(AppUrl.Dashboard);
			}
		},
		[history, token]
	);

	const handleShowVisibility = (): void => {
		setShowPassword((prev) => !prev);
	};

	const handleEmailInputChanged = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
		setEmail(event.target.value);
	};

	const handlePasswordInputChanged = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
		setPassword(event.target.value);
	};

	const handleSubmit: (event: FormEvent) => void = async (event) => {
		event.preventDefault();
		dispatchStartLoading('Logging in');
		try {
			const response = await loginRequest(email, password);
			dispatchLogin(response.data.token);
			setToken(response.data.token);
		} catch (error) {
			console.log(error);
			const message = error.response?.data.message ?? error.message;
			dispatchSetMessageAction(message, MessageStatusLevel.Error, 3000);
		} finally {
			setPassword('');
			dispatchStopLoading();
		}
	};

	return (
		<main className={classes.main}>
			<Card className={classes.card}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Log in
				</Typography>
				<form className={classes.form}>
					<FormControl margin="normal" fullWidth={true}>
						<InputLabel htmlFor="email">Username</InputLabel>
						<Input
							id="email"
							name="email"
							autoComplete="email"
							autoFocus={true}
							onChange={handleEmailInputChanged}
							value={email}
						/>
					</FormControl>
					<FormControl margin="normal" fullWidth={true}>
						<InputLabel htmlFor="password">Password</InputLabel>
						<Input
							id="password"
							name="password"
							type={showPassword ? 'text' : 'password'}
							autoComplete="password"
							onChange={handlePasswordInputChanged}
							value={password}
							endAdornment={
								<InputAdornment position="end">
									<IconButton onClick={handleShowVisibility}>
										{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						onClick={handleSubmit}
					>
						Log in
					</Button>
				</form>
			</Card>
		</main>
	);
};

export default LoginView;
