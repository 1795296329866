import { AxiosPromise } from 'axios';
import useDataFetcher, { UseDataFetcherReturn } from 'Hooks/Http/useDataFetcher';
import { useCallback } from 'react';
import getFolderContentRequest, { GetFolderContentResponse } from 'Utils/Http/Requests/Folder/GetFolderContentRequest';

const useFetchFolderContent = (folderId: number | null): UseDataFetcherReturn<GetFolderContentResponse> => {
	const fetcher = useCallback(
		(token: string | null): AxiosPromise<GetFolderContentResponse> => getFolderContentRequest(token, folderId ?? 0),
		[folderId]
	);
	return useDataFetcher(fetcher);
};

export default useFetchFolderContent;
