import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { DialogProps } from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useMainMenuListItemClasses from 'Hooks/Styles/Layout/useMainMenuListItemClasses';
import { Brand } from 'Models/Brands/Brand';
import BrandGroup from 'Models/Brands/BrandGroup';
import { Fragment, ReactElement, useMemo, useState } from 'react';

interface BrandSelectDialogProps extends DialogProps {
	brandGroups: BrandGroup[];
	onBrandSelected: (brandId: number) => void;
	sort?: boolean;
	searchable?: boolean;
}

const BrandSelectDialog = ({
	onBrandSelected,
	brandGroups,
	sort,
	searchable,
	...dialogProps
}: BrandSelectDialogProps): ReactElement => {
	const [expandedGroupIds, setExpandedGroupIds] = useState<number[]>([]);

	const classes = useMainMenuListItemClasses();
	const brandsToDisplay = useMemo(() => {
		if (!sort) return brandGroups;

		return brandGroups.sort((a, b) => {
			return a.name.localeCompare(b.name);
		});
	}, [sort, brandGroups]);

	const handleBrandSelected = (brandId: number): void => {
		onBrandSelected(brandId);
	};

	const handleBrandGroupSelected = (brandGroupId: number): void => {
		setExpandedGroupIds((prev) => {
			if (prev.indexOf(brandGroupId) > -1) return prev.filter((x) => x !== brandGroupId);
			return [...prev, brandGroupId];
		});
	};

	const renderBrand = (brand: Brand): JSX.Element => {
		return (
			<ListItem key={brand.id} onClick={() => handleBrandSelected(brand.id)} button={true}>
				<ListItemText primary={brand.name} />
			</ListItem>
		);
	};

	const renderBrandGroups = (brandGroup: BrandGroup): JSX.Element => {
		if (brandGroup.brands.length === 1)
			return (
				<ListItem key={brandGroup.id} onClick={() => handleBrandSelected(brandGroup.brands[0].id)} button={true}>
					<ListItemText primary={brandGroup.brands[0].name} />
				</ListItem>
			);

		const isExpanded = expandedGroupIds.indexOf(brandGroup.id) > -1;

		return (
			<Fragment key={brandGroup.id}>
				<ListItem button={true} onClick={() => handleBrandGroupSelected(brandGroup.id)}>
					<ListItemText primary={brandGroup.name} />
					{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
				</ListItem>
				<Collapse in={isExpanded}>
					<List className={classes.nestedList}>{brandGroup.brands.map((x) => renderBrand(x))}</List>
				</Collapse>
			</Fragment>
		);
	};

	return (
		<Dialog {...dialogProps}>
			<DialogTitle>Brand Select</DialogTitle>
			<DialogContent>
				<List>{brandsToDisplay.map((x) => renderBrandGroups(x))}</List>
			</DialogContent>
		</Dialog>
	);
};

export default BrandSelectDialog;
