import useAppDispatch from 'Hooks/Redux/useAppDispatch';
import { useCallback } from 'react';
import { VideoActionType } from 'Redux/Actions/Video';
import { deleteEditorTime, setSelectedVideoId } from 'Utils/LocalStorageHelper';

type UseVideoDispatch = () => {
	dispatchSelectFolder: (folderId: number | null) => void;
	dispatchAddVideosToUpload: (videoFiles: File[]) => void;
	dispatchStartUploadingVideo: (id: string) => void;
	dispatchCompleteVideoUpload: (id: string, successful: boolean) => void;
	dispatchUpdateVideoUploadProgress: (id: string, progress: number) => void;
	dispatchCancelVideoUpload: (id: string) => void;
	dispatchSelectVideo: (id: number) => void;
};

const useVideoDispatch: UseVideoDispatch = () => {
	const appDispatch = useAppDispatch();

	const dispatchSelectFolder = useCallback(
		(folderId: number | null): void => appDispatch({ type: VideoActionType.SelectFolder, selectedFolderId: folderId }),
		[appDispatch]
	);

	const dispatchAddVideosToUpload = useCallback(
		(videoFiles: File[]): void => appDispatch({ type: VideoActionType.AddVideosToUpload, videoFiles }),
		[appDispatch]
	);

	const dispatchStartUploadingVideo = useCallback(
		(id: string): void => appDispatch({ type: VideoActionType.StartUploadingVideo, id }),
		[appDispatch]
	);

	const dispatchCompleteVideoUpload = useCallback(
		(id: string, successful: boolean): void => {
			appDispatch({ type: VideoActionType.CompleteVideoUpload, id, successful });
		},
		[appDispatch]
	);

	const dispatchUpdateVideoUploadProgress = useCallback(
		(id: string, progress: number): void =>
			appDispatch({ type: VideoActionType.UpdateVideoUploadProgress, id, progress }),
		[appDispatch]
	);

	const dispatchCancelVideoUpload = useCallback(
		(id: string): void => appDispatch({ type: VideoActionType.CancelVideoUpload, id }),
		[appDispatch]
	);

	const dispatchSelectVideo = useCallback(
		(id: number): void => {
			deleteEditorTime();
			setSelectedVideoId(id);
			appDispatch({ type: VideoActionType.SelectVideo, id });
		},
		[appDispatch]
	);

	return {
		dispatchSelectFolder,
		dispatchAddVideosToUpload,
		dispatchStartUploadingVideo,
		dispatchCompleteVideoUpload,
		dispatchUpdateVideoUploadProgress,
		dispatchCancelVideoUpload,
		dispatchSelectVideo,
	};
};

export default useVideoDispatch;
