import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

interface UseColorDotClassesProps {
	color: string;
}

type Classes = 'colorDot' | 'colorDotContainer' | 'colorDotSpacer';
type UseColorDotClasses = (props: UseColorDotClassesProps) => ClassNameMap<Classes>;

const useColorDotClasses: UseColorDotClasses = makeStyles((theme: Theme) =>
	createStyles({
		colorDotContainer: {
			position: 'absolute',
		},
		colorDot: {
			height: '8px',
			width: '8px',
			borderRadius: '50%',
			display: 'inline-block',
			backgroundColor: ({ color }: UseColorDotClassesProps) => color,
			position: 'relative',
			top: '50%',
			transform: 'translate(0, -50%)',
		},
		colorDotSpacer: {
			marginRight: `calc(${theme.spacing(1)}px + 8px)`,
		},
	})
);

export default useColorDotClasses;
