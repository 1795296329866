import { AxiosPromise } from 'axios';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export interface PostSeekNextVideoFrameDetectionRequest {
	timestamp: number;
	backwards: boolean;
	brandFilterIds: number[];
	placementFilterIds: number[];
	seekBySecond: boolean;
}

const postSeekNextVideoFrameDetectionRequest = (
	token: string | null,
	videoId: number,
	request: PostSeekNextVideoFrameDetectionRequest
): AxiosPromise<number> => {
	const url = getApiUrl(ApiUrl.VideosVideoDetections).replace(':videoId', videoId.toString());
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.post<number>(url, request);
};

export default postSeekNextVideoFrameDetectionRequest;
