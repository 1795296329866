import { Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SearchBar from 'Components/Common/SearchBar';
import { MainMenuListItemSubItem } from 'Components/Layout/MainMenuListItem';
import CreateOrEditProjectModal from 'Components/Projects/CreateOrEditProjectModal';
import DeleteProjectModal from 'Components/Projects/DeleteProjectModal';
import ProjectCardItem from 'Components/Projects/ProjectCardItem';
import useFetchProjects from 'Hooks/Http/Projects/useFetchProjects';
import Layout from 'Layout';
import Project from 'Models/Project';
import { ReactElement, useMemo, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			paddingTop: theme.spacing(3),
		},
	})
);

const ProjectsView = (): ReactElement => {
	const [search, setSearch] = useState('');
	const [isCreateOrEditModalOpen, setIsCreateOrEditModalOpen] = useState(false);
	const [projectToEdit, setProjectToEdit] = useState<Project | undefined>();
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [projectToDelete, setProjectToDelete] = useState<Project | null>(null);

	const classes = useStyles();

	const projectsState = useFetchProjects();
	const projects = useMemo(() => projectsState.data?.filter((x) => x.name.includes(search)) ?? [], [
		projectsState.data,
		search,
	]);

	const handleCreateProjectClicked = (): void => {
		setIsCreateOrEditModalOpen(true);
	};

	const layoutSubItems: MainMenuListItemSubItem[] = [
		{ primary: 'Create Project', onClick: handleCreateProjectClicked },
	];

	const handleSearchChanged = (value: string): void => {
		setSearch(value.toLowerCase());
	};

	const handleProjectEditClicked = (project: Project): void => {
		setIsCreateOrEditModalOpen(true);
		setProjectToEdit(project);
	};

	const handleProjectDeleteClicked = (id: number): void => {
		const p = projects.find((x) => x.id === id);
		if (p === undefined) return;

		setIsDeleteModalOpen(true);
		setProjectToDelete(p);
	};

	const handleProjectSaved = (project: Project, isCreate: boolean): void => {
		if (isCreate) {
			projectsState.setData((prev) => {
				if (prev === null) return null;
				return [...prev, project];
			});
		} else {
			projectsState.setData((prev) => {
				if (prev === null) return null;

				const idx = prev.findIndex((x) => x.id === project.id);
				if (idx > -1) {
					const prevCopy = [...prev];
					prevCopy[idx] = project;
					return prevCopy;
				}
				return [...prev, project];
			});
		}
	};

	const handleCreateOrEditProjectModalClose = (): void => {
		setIsCreateOrEditModalOpen(false);
		setProjectToEdit(undefined);
	};

	const handleProjectDeleted = (id: number): void => {
		projectsState.setData((prev) => prev?.filter((x) => x.id !== id) ?? null);
	};

	const handleDeleteModalClosed = (): void => {
		setIsDeleteModalOpen(false);
	};

	return (
		<Layout title="Projects" subItems={layoutSubItems}>
			<SearchBar onSearchChanged={handleSearchChanged} />
			<Grid container className={classes.root} spacing={2}>
				{projects.map((x) => (
					<ProjectCardItem
						project={x}
						onEditClicked={handleProjectEditClicked}
						onDeleteClicked={handleProjectDeleteClicked}
					/>
				))}
			</Grid>
			<CreateOrEditProjectModal
				isOpen={isCreateOrEditModalOpen}
				onProjectSaved={handleProjectSaved}
				onClose={handleCreateOrEditProjectModalClose}
				project={projectToEdit}
			/>
			<DeleteProjectModal
				isOpen={isDeleteModalOpen}
				project={projectToDelete}
				onDeleted={handleProjectDeleted}
				onClose={handleDeleteModalClosed}
			/>
		</Layout>
	);
};

export default ProjectsView;
