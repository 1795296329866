import { ModalProps, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ActionCardImageContent from 'Components/Common/Cards/ActionCardImageContent';
import SelectableActionCard from 'Components/Common/Cards/SelectableActionCard';
import SaveModal from 'Components/Common/Modals/SaveModal';
import BrandLogo from 'Models/Brands/BrandLogo';
import { ReactElement, useState } from 'react';

interface LogoSelectModalProps extends Omit<ModalProps, 'children'> {
	logos: BrandLogo[];
	onSave: (logoIds: number[]) => void;
	selectedLogoIds: number[];
}

const LogoSelectModal = ({
	logos,
	onSave,
	selectedLogoIds,
	onClose,
	...restProps
}: LogoSelectModalProps): ReactElement => {
	const [internalSelectedLogoIds, setInternalSelectedLogoIds] = useState<number[]>([]);

	const handleSave = (): void => {
		onSave(internalSelectedLogoIds);
	};

	const isLogoSelected = (id: number): boolean => {
		return internalSelectedLogoIds.includes(id);
	};

	const handleClose = (event: Object, reason: 'backdropClick' | 'escapeKeyDown'): void => {
		setInternalSelectedLogoIds(selectedLogoIds);
		if (onClose !== undefined) onClose(event, reason);
	};

	const handleLogoSelectionChanged = (id: number, selected: boolean): void => {
		if (selected) setInternalSelectedLogoIds((prev) => [...prev, id]);
		else setInternalSelectedLogoIds((prev) => prev.filter((x) => x !== id));
	};

	return (
		<SaveModal onSave={handleSave} onClose={handleClose} {...restProps}>
			<Typography variant="h5">Select logos</Typography>
			<Typography variant="subtitle2">Deselect all to ignore logos.</Typography>
			<Divider />
			<Grid container={true} spacing={2}>
				{logos.map((x) => {
					const isSelected = isLogoSelected(x.id);
					return (
						<Grid item={true} key={x.id} xs={6}>
							<SelectableActionCard
								isSelected={isSelected}
								onSelectionChanged={(selected: boolean) => handleLogoSelectionChanged(x.id, selected)}
								onClick={() => handleLogoSelectionChanged(x.id, !isSelected)}
							>
								<ActionCardImageContent imageUrl={x.iconUrl} />
							</SelectableActionCard>
						</Grid>
					);
				})}
			</Grid>
		</SaveModal>
	);
};

export default LogoSelectModal;
