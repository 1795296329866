export enum DetectionType {
	Logo,
	Text,
	Manual,
}

export interface EditorVideoDetection {
	id: number;
	trackId: number;
	brandId: number;
	placementId: number;
	detectionType: DetectionType;
	area: number;
	angle: number;
}

export default EditorVideoDetection;
