import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import EditorButton from 'Components/Common/Buttons/EditorButton';
import EditorDetectionPlacementLabel from 'Components/Common/Labels/EditorDetectionPlacementLabel';
import useValueRef from 'Hooks/useValueRef';
import VideoDetectionPlacement from 'Models/Videos/VideoDetectionPlacement';
import { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import EditLocationIcon from '@material-ui/icons/EditLocation';

interface EditorButtonPlacementSelectProps {
	buttonTitle: string;
	placements: VideoDetectionPlacement[];
	onPlacementSelected: (placement: VideoDetectionPlacement) => void;
	disabled?: boolean;
}

const EditorButtonPlacementSelect = (props: EditorButtonPlacementSelectProps): ReactElement => {
	const [isPlacementSelectorOpen, setIsPlacementSelectorOpen] = useState(false);
	const editPlacementButtonRef = useRef<HTMLButtonElement | null>(null);

	const onPlacementSelectedValueRef = useValueRef(props.onPlacementSelected);

	const visuaPlacements = useMemo(() => props.placements.filter((x) => x.visuaId !== null), [props.placements]);
	const manualPlacements = useMemo(() => props.placements.filter((x) => x.visuaId === null), [props.placements]);

	const sortedVisuaPlacements = useMemo(
		() =>
			visuaPlacements.sort((a, b) => {
				if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
				if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
				return 0;
			}),
		[visuaPlacements]
	);
	const sortedManualPlacements = useMemo(
		() =>
			manualPlacements.sort((a, b) => {
				if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
				if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
				return 0;
			}),
		[manualPlacements]
	);

	const handleClickAway = useCallback((): void => {
		setIsPlacementSelectorOpen(false);
	}, []);

	const handleItemClicked = useCallback(
		(placement: VideoDetectionPlacement): void => {
			onPlacementSelectedValueRef.current(placement);
			setIsPlacementSelectorOpen(false);
		},
		[onPlacementSelectedValueRef]
	);

	const handleChangePlacements = useCallback((): void => {
		setIsPlacementSelectorOpen(true);
	}, []);

	return useMemo(
		(): JSX.Element => (
			<>
				<EditorButton
					title={props.buttonTitle}
					onClick={handleChangePlacements}
					size="small"
					disabled={props.disabled}
					ref={editPlacementButtonRef}
				>
					<EditLocationIcon />
				</EditorButton>
				<Popper open={isPlacementSelectorOpen} anchorEl={editPlacementButtonRef.current} transition>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClickAway}>
									<MenuList>
										<MenuItem disabled={true}>Visua Placements</MenuItem>
										<MenuItem>
											<MenuList>
												{sortedVisuaPlacements.map((p) => (
													<MenuItem key={p.id} onClick={() => handleItemClicked(p)}>
														<EditorDetectionPlacementLabel placement={p} />
													</MenuItem>
												))}
											</MenuList>
										</MenuItem>
										<MenuItem disabled={true}>Manual Placements</MenuItem>
										<MenuItem>
											<MenuList>
												{sortedManualPlacements.map((p) => (
													<MenuItem key={p.id} onClick={() => handleItemClicked(p)}>
														<EditorDetectionPlacementLabel placement={p} />
													</MenuItem>
												))}
											</MenuList>
										</MenuItem>
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</>
		),
		[
			props.buttonTitle,
			props.disabled,
			handleChangePlacements,
			isPlacementSelectorOpen,
			handleClickAway,
			sortedVisuaPlacements,
			sortedManualPlacements,
			handleItemClicked,
		]
	);
};

export default EditorButtonPlacementSelect;
