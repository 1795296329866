import useAppSelector from 'Hooks/Redux/useAppSelector';
import { UserClaimFlag } from 'Models/User';
import { PropsWithChildren, ReactElement } from 'react';
import { RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { AppUrl } from 'Utils/Http/AppUrls';

interface AuthRouteProps extends RouteProps {
	claimFlag?: UserClaimFlag;
}

const AuthRoute = (props: PropsWithChildren<AuthRouteProps>): ReactElement => {
	const { token, user } = useAppSelector((state) => state.profile);

	if (token === null) return <Redirect to={AppUrl.Login} />;
	if (user !== null && props.claimFlag && (user.claimFlag & props.claimFlag) === 0)
		return <Redirect to={AppUrl.Dashboard} />;

	return <Route {...props} />;
};

export default AuthRoute;
