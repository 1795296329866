import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Loader from 'Components/Common/Loader';
import TitledBox from 'Components/Common/TitledBox';
import useDataFetcher from 'Hooks/Http/useDataFetcher';
import { ReactElement } from 'react';
import getSpaceRequest from 'Utils/Http/Requests/GetSpaceRequest';

const useSpaceWidgetStyles = makeStyles(({ spacing }: Theme) =>
	createStyles({
		progress: { marginBottom: spacing(1), height: spacing(2) },
	})
);

const SpaceWidget = (): ReactElement => {
	const classes = useSpaceWidgetStyles();

	const { data, loading } = useDataFetcher(getSpaceRequest);

	const getHumanReadableBytes = (bytes: number): string => {
		if (bytes < 1) return '';

		for (let i = 1; true; i++) {
			const checkValue = bytes / Math.pow(1024, i);

			if (checkValue < 1) {
				const value = (bytes / Math.pow(1024, i - 1)).toFixed(2);
				switch (i) {
					case 1:
						return `${value} B`;
					case 2:
						return `${value} KB`;
					case 3:
						return `${value} MB`;
					case 4:
						return `${value} GB`;
					case 5:
					default:
						return `${value} TB`;
				}
			}
		}
	};

	const total = getHumanReadableBytes(data?.totalBytes ?? 0);
	const free = getHumanReadableBytes(data?.availableBytes ?? 0);
	const usedRelative = data !== null ? Math.round((data.usedBytes / data.totalBytes) * 100) : 0;

	return (
		<TitledBox title="Disk Space" border={1}>
			<Loader loaderComponent={<LinearProgress />} isLoading={loading}>
				<LinearProgress variant="determinate" value={usedRelative} className={classes.progress} />
				<Typography variant={'subtitle2'}>
					{free} free of {total} ({usedRelative}%)
				</Typography>
			</Loader>
		</TitledBox>
	);
};

export default SpaceWidget;
