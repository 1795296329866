import Grid from '@material-ui/core/Grid';
import SpaceWidget from 'Components/Dashboard/SpaceWidget';
import VideosProcessingTable from 'Components/Dashboard/VideosProcessingTable';
import VideoUploadsTable from 'Components/Dashboard/VideoUploadsTable';
import useFetchFolders from 'Hooks/Http/Folders/useFetchFolders';
import Layout from 'Layout';
import { ReactElement } from 'react';

const DashboardView = (): ReactElement => {
	const { data, loading } = useFetchFolders();
	return (
		<Layout title="Dashboard">
			<Grid container={true} spacing={4}>
				<Grid item={true} xs={12} xl={6}>
					<VideoUploadsTable foldersLoading={loading} folders={data ?? []} />
				</Grid>
				<Grid item={true} xs={12} xl={6}>
					<VideosProcessingTable foldersLoading={loading} folders={data ?? []} />
				</Grid>
				<Grid item={true} xs={6} xl={3}>
					<SpaceWidget />
				</Grid>
			</Grid>
		</Layout>
	);
};

export default DashboardView;
