import CssBaseline from '@material-ui/core/CssBaseline';
import AppLoadingModal from 'Components/App/AppLoadingModal';
import AppSnackbarMessageQueue from 'Components/App/AppSnackbarMessageQueue';
import AuthRoute from 'Components/Common/AuthRoute';
import useFetchUserAndSaveToRedux from 'Hooks/App/useFetchUserAndSaveToRedux';
import useUploadVideosInQueue from 'Hooks/App/useUploadVideosInQueue';
import { UserClaimFlag } from 'Models/User';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AppUrl } from 'Utils/Http/AppUrls';
import BrandActivationsView from 'Views/BrandActivationsView';
import BrandsView from 'Views/BrandsView';
import DashboardView from 'Views/DashboardView';
import EditorView from 'Views/EditorView';
import LoginView from 'Views/LoginView';
import PlacementsView from 'Views/PlacementsView';
import ProjectsView from 'Views/ProjectsView';
import VideoView from 'Views/VideoView';

const App = () => {
	useFetchUserAndSaveToRedux();
	useUploadVideosInQueue();

	return (
		<>
			<CssBaseline />
			<BrowserRouter>
				<Switch>
					<Route exact={true} path={AppUrl.Login} component={LoginView} />
					<AuthRoute exact={true} path={AppUrl.Dashboard} component={DashboardView} />
					<AuthRoute exact={true} claimFlag={UserClaimFlag.BrandRead} path={AppUrl.Brands} component={BrandsView} />
					<AuthRoute
						exact={true}
						claimFlag={UserClaimFlag.BrandWrite}
						path={AppUrl.BrandActivations}
						component={BrandActivationsView}
					/>
					<AuthRoute exact={true} claimFlag={UserClaimFlag.VideoRead} path={AppUrl.Videos} component={VideoView} />
					<AuthRoute
						exact={true}
						claimFlag={UserClaimFlag.VideoRead}
						path={AppUrl.Placements}
						component={PlacementsView}
					/>
					<AuthRoute exact={true} claimFlag={UserClaimFlag.VideoRead} path={AppUrl.Editor} component={EditorView} />
					<AuthRoute exact={true} path={AppUrl.Projects} component={ProjectsView} />
				</Switch>
			</BrowserRouter>
			<AppLoadingModal />
			<AppSnackbarMessageQueue />
		</>
	);
};

export default App;
