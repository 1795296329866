import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useModalClasses = makeStyles((theme: Theme) =>
	createStyles({
		modalBody: {
			position: 'absolute',
			minWidth: '400px',
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			padding: theme.spacing(4),
			outline: 'none',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			maxHeight: '100%',
			overflow: 'auto',
		},
		primaryModalButton: {
			marginTop: theme.spacing(3),
			marginRight: theme.spacing(3),
		},
		confirmModalBody: {
			marginTop: theme.spacing(2),
		},
	})
);

export default useModalClasses;
