import { AxiosPromise } from 'axios';
import Project from 'Models/Project';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

const getProjectsRequest = (token: string | null): AxiosPromise<Project[]> => {
	const url = getApiUrl(ApiUrl.Projects);
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.get<Project[]>(url);
};

export default getProjectsRequest;
