import { CircularProgress, CircularProgressProps, Fade } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ReactElement, ReactNode, ReactNodeArray, useRef } from 'react';

const useStyles = makeStyles(
	createStyles({
		root: {
			position: 'relative',
		},
		wrapper: {
			position: 'absolute',
		},
		progressContainer: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
		},
	})
);

interface ElementLoadingProps extends CircularProgressProps {
	/** If true, loading is displayed. Otherwise children are shown*/
	loading: boolean;
	/** If set background is used when used for loader container.*/
	background?: string;
	children: ReactNode | ReactNodeArray;
}

const ElementLoading = (props: ElementLoadingProps): ReactElement => {
	const { children, loading, background, ...circularProgressProps } = props;

	const contentWrapperRef = useRef<HTMLDivElement | null>(null);

	const classes = useStyles();

	const contentWidth = !!contentWrapperRef.current ? contentWrapperRef.current.clientWidth : 0;
	const contentHeight = !!contentWrapperRef.current ? contentWrapperRef.current.clientHeight : 0;
	const divStyle = { width: contentWidth, height: contentHeight };

	return (
		<div className={classes.root} style={divStyle}>
			<div ref={contentWrapperRef} className={classes.wrapper}>
				<Fade in={!loading}>
					<div>{children}</div>
				</Fade>
			</div>
			<div className={classes.wrapper} style={{ ...divStyle, background }}>
				<Fade in={loading}>
					<div className={classes.progressContainer}>
						<CircularProgress {...circularProgressProps} />
					</div>
				</Fade>
			</div>
		</div>
	);
};

export default ElementLoading;
