import EditorButton from 'Components/Common/Buttons/EditorButton';
import BrandSelectDialog from 'Components/Common/Dialogs/BrandSelectDialog';
import BrandGroup from 'Models/Brands/BrandGroup';
import { ReactElement, useCallback, useState } from 'react';

interface BrandsSelectProps {
	buttonTitle: string;
	brandGroups: BrandGroup[];
	onBrandSelected: (brandId: number) => void;
	disabled?: boolean;
	sort?: boolean;
	searchable?: boolean;
}

const BrandSelect = ({
	onBrandSelected,
	brandGroups,
	sort,
	disabled,
	buttonTitle,
}: BrandsSelectProps): ReactElement => {
	const [isOpen, setIsOpen] = useState(false);
	const handleClickAway = useCallback(() => {
		setIsOpen(false);
	}, []);

	const handleBrandSelected = (brandId: number): void => {
		onBrandSelected(brandId);
		setIsOpen(false);
	};

	const handleButtonClick = (): void => {
		setIsOpen(true);
	};

	return (
		<>
			<EditorButton title={buttonTitle} onClick={handleButtonClick} disabled={disabled}>
				&#8482;
			</EditorButton>
			<BrandSelectDialog
				brandGroups={brandGroups}
				onBrandSelected={handleBrandSelected}
				open={isOpen}
				onClose={handleClickAway}
				sort={sort}
			/>
		</>
	);
};

export default BrandSelect;
