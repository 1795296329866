import Grid from '@material-ui/core/Grid';
import ItemCardsSkeleton from 'Components/Common/ItemCardsSkeleton';
import Loader from 'Components/Common/Loader';
import ReportExportWizard from 'Components/Common/ReportExportWizard';
import { MainMenuListItemSubItem } from 'Components/Layout/MainMenuListItem';
import VideoFolderCardGridItems from 'Components/Videos/Folders/VideoFolderCardGridItems';
import VideoMenuBar from 'Components/Videos/VideoMenuBar';
import CreateVideoModal from 'Components/Videos/Videos/Modals/CreateVideoModal';
import SetProjectModal from 'Components/Videos/Videos/Modals/SetProjectModal';
import VideoCardGridItems from 'Components/Videos/Videos/VideoCardGridItems';
import useFetchBrandGroups from 'Hooks/Http/Brands/useFetchBrandGroups';
import useFetchFolderContent from 'Hooks/Http/Folders/useFetchFolderContent';
import useFetchProjects from 'Hooks/Http/Projects/useFetchProjects';
import useFetchPlacements from 'Hooks/Http/Videos/Placements/useFetchPlacements';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import Layout from 'Layout';
import { UserClaimFlag } from 'Models/User';
import Video, { VideoStatus } from 'Models/Videos/Video';
import VideoFolder from 'Models/Videos/VideoFolder';
import { ReactElement, useMemo, useState } from 'react';

const DEFAULT_SKELETON_ITEMS_COUNT = 64;

const VideoView = (): ReactElement => {
	const [search, setSearch] = useState('');
	const [isCreatingFolder, setIsCreatingFolder] = useState(false);
	const [isVideoCreateModalOpen, setIsVideoCreateModalOpen] = useState(false);
	const [selectedVideoIds, setSelectedVideoIds] = useState<number[]>([]);
	const [isSelectProjectModalOpen, setIsSelectProjectModalOpen] = useState(false);
	const [isReportExportWizardOpen, setIsReportExportWizardOpen] = useState(false);

	const selectedFolderId = useAppSelector<number | null>((state) => state.video.selectedFolderId);
	const { data, loading, setData } = useFetchFolderContent(selectedFolderId);
	const { data: projectsData } = useFetchProjects();
	const placementsState = useFetchPlacements();
	const brandsState = useFetchBrandGroups();

	const filteredFolders = data?.folders?.filter((x) => x.name.toLowerCase().includes(search)) ?? [];
	const filteredVideos = data?.videos?.filter((x) => x.name.toLowerCase().includes(search)) ?? [];
	const projects = projectsData ?? [];
	const isDownloadReportButtonDisabled = useMemo(() => {
		if (selectedVideoIds.length === 0 || data?.videos === null) return true;

		const selectedVideos = data?.videos.filter((x) => selectedVideoIds.includes(x.id));
		const nonCompletedVideo = selectedVideos?.find((x) => x.videoStatus !== VideoStatus.Completed);
		return !!nonCompletedVideo;
	}, [data?.videos, selectedVideoIds]);

	const handleSearchChanged = (value: string): void => {
		setSearch(value.toLowerCase());
	};

	const handleFolderDataChanged = (folders: VideoFolder[]): void => {
		setData((prev) => {
			if (prev === null) return prev;
			return {
				...prev,
				folders,
			};
		});
	};

	const handleVideoDataChanged = (videos: Video[]): void => {
		setData((prev) => {
			if (prev === null) return prev;
			return {
				...prev,
				videos,
			};
		});
	};

	const handleOnCreateFolderClicked = (): void => {
		setIsCreatingFolder(true);
	};

	const handleOnCreateVideoClicked = (): void => {
		setIsVideoCreateModalOpen(true);
	};

	const handleSelectAllVideosClicked = (): void => {
		setSelectedVideoIds((prev) => {
			if (prev.length === filteredVideos.length) return [];
			return filteredVideos.map((x) => x.id);
		});
	};

	const handleSetProjectClicked = (): void => {
		setIsSelectProjectModalOpen(true);
	};

	const handleDownloadReportClicked = (): void => {
		setIsReportExportWizardOpen(true);
	};

	const handleFolderEditModalClosed = (): void => {
		setIsCreatingFolder(false);
	};

	const handleVideoCreateModalClose = (): void => {
		setIsVideoCreateModalOpen(false);
	};

	const handleProjectSet = (videos: Video[]): void => {
		setData((prev) => {
			if (prev === null) return prev;

			const updatedVideos = prev.videos.map((x) => {
				const v = videos.find((y) => y.id === x.id);
				return v ?? x;
			});

			return {
				...prev,
				videos: updatedVideos,
			};
		});
	};

	const handleSelectProjectModalClose = (): void => {
		setIsSelectProjectModalOpen(false);
	};

	const handleReportExportWizardClosed = (): void => {
		setIsReportExportWizardOpen(false);
	};

	const handleVideoDownloadButtonClicked = (videoId: number): void => {
		setSelectedVideoIds([videoId]);
		setIsReportExportWizardOpen(true);
	};

	const layoutSubItems: MainMenuListItemSubItem[] = [
		{ primary: 'Create Folder', onClick: handleOnCreateFolderClicked, claimFlag: UserClaimFlag.VideoWrite },
		{ primary: 'Create Video', onClick: handleOnCreateVideoClicked, claimFlag: UserClaimFlag.VideoWrite },
		{
			primary: selectedVideoIds.length !== filteredVideos.length ? 'Select All Videos' : 'Deselect All Videos',
			onClick: handleSelectAllVideosClicked,
			claimFlag: UserClaimFlag.VideoWrite,
		},
		{
			primary: 'Set Project',
			onClick: handleSetProjectClicked,
			claimFlag: UserClaimFlag.VideoWrite,
			disabled: selectedVideoIds.length === 0,
		},
		{
			primary: 'Download Report',
			onClick: handleDownloadReportClicked,
			claimFlag: UserClaimFlag.VideoRead,
			disabled: isDownloadReportButtonDisabled,
		},
	];

	return (
		<Layout title="Videos" subItems={layoutSubItems}>
			<VideoMenuBar
				parentFolders={data?.parentFolders ?? []}
				onSearchChanged={handleSearchChanged}
				currentFolderName={data?.name ?? ''}
			/>
			<Loader
				isLoading={loading}
				loaderComponent={
					<ItemCardsSkeleton elementCount={DEFAULT_SKELETON_ITEMS_COUNT} rectHeight={146} textWidth={64} />
				}
			>
				<Grid container={true} spacing={2}>
					<VideoFolderCardGridItems
						folders={filteredFolders}
						onFolderDataChanged={handleFolderDataChanged}
						currentFolderId={data?.id ?? null}
						isCreateModalOpen={isCreatingFolder}
						onEditModalClose={handleFolderEditModalClosed}
						projects={projects}
					/>
					<VideoCardGridItems
						videos={filteredVideos}
						onVideoDataChanged={handleVideoDataChanged}
						selectedVideoIds={selectedVideoIds}
						setSelectedVideoIds={setSelectedVideoIds}
						projects={projects}
						onVideoDownloadButtonClicked={handleVideoDownloadButtonClicked}
					/>
				</Grid>
				<CreateVideoModal isOpen={isVideoCreateModalOpen} onClose={handleVideoCreateModalClose} />
				<SetProjectModal
					projects={projects}
					selectedVideoIds={selectedVideoIds}
					isOpen={isSelectProjectModalOpen}
					onProjectSet={handleProjectSet}
					onClose={handleSelectProjectModalClose}
				/>
				<ReportExportWizard
					isOpen={isReportExportWizardOpen}
					onClose={handleReportExportWizardClosed}
					placements={placementsState.data ?? []}
					brandGroups={brandsState.data?.groups ?? []}
					selectedVideoIds={selectedVideoIds}
					videos={filteredVideos}
				/>
			</Loader>
		</Layout>
	);
};

export default VideoView;
