import { AxiosPromise } from 'axios';
import Video from 'Models/Videos/Video';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

const getVideoRequest = (token: string | null, videoId: number): AxiosPromise<Video> => {
	const url = getApiUrl(ApiUrl.VideosVideo).replace(':videoId', videoId.toString());
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.get<Video>(url);
};

export default getVideoRequest;
