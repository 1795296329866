import { AppAction } from 'Redux/Actions';
import { LoadingActionType, StartLoadingAction } from 'Redux/Actions/Loading';

export interface LoadingState {
	isLoading: boolean;
	title: string | null;
}

const initialState: LoadingState = {
	isLoading: false,
	title: null,
};

const startLoading = ({ title }: StartLoadingAction): LoadingState => ({
	title: title ?? null,
	isLoading: true,
});

const stopLoading = (): LoadingState => ({
	isLoading: false,
	title: null,
});

const loading = (state: LoadingState = initialState, action: AppAction): LoadingState => {
	switch (action.type) {
		case LoadingActionType.StartLoading:
			return startLoading(action);
		case LoadingActionType.StopLoading:
			return stopLoading();
		default:
			return state;
	}
};

export default loading;
