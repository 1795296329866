import { UseVideoPlayerInstance } from 'Hooks/Editor/useVideoPlayerInstance';
import useValueRef from 'Hooks/useValueRef';
import { EditorVideoNewDetectionWithNullableIds } from 'Models/Videos/Detections/EditorVideoNewDetection';
import VideoDetectionCoordinate from 'Models/Videos/Detections/VideoDetectionCoordinate';
import VideoFrameDetection from 'Models/Videos/Detections/VideoFrameDetection';
import { useCallback, useEffect } from 'react';
import { shadeColor } from 'Utils/Colors';
import {
	getInterpolatedPointsFromCoordinates,
	isFilteredDetection,
	scaleDetections,
	scaleNewDetections,
} from 'Utils/DetectionHelpers';

const DEFAULT_DETECTION_COLOR = '#FF0000';

const useDrawDetections = (
	instance: UseVideoPlayerInstance,
	canvasCtx: CanvasRenderingContext2D | null,
	scale: number
): void => {
	const {
		placements,
		fps,
		videoNode,
		selectedDetectionIds,
		getCurrentFrameDetections,
		filteredBrandIds,
		filteredPlacementIds,
		showFilteredDetections,
		newDetections,
		currentTimeRef,
	} = instance;
	const selectedDetectionsIdsRef = useValueRef(selectedDetectionIds);
	const placementsRef = useValueRef(placements);
	const scaleRef = useValueRef(scale);
	const filteredBrandIdsRef = useValueRef(filteredBrandIds);
	const filteredPlacementIdsRef = useValueRef(filteredPlacementIds);
	const showFilteredDetectionsRef = useValueRef(showFilteredDetections);

	const drawCoordinates = useCallback(
		(
			coordinate: VideoDetectionCoordinate,
			color: string,
			isSelected: boolean,
			isFiltered: boolean,
			ctx: CanvasRenderingContext2D
		): void => {
			ctx.save();
			ctx.strokeStyle = isFiltered ? shadeColor(color, 40) : color;
			ctx.lineWidth = isFiltered ? 1 : 3;
			if (isSelected) {
				ctx.lineWidth = 5;
				ctx.shadowColor = color;
				ctx.shadowBlur = 20;
			}

			const { point1, point2, point3, point4 } = coordinate;
			ctx.beginPath();
			ctx.moveTo(point1.x, point1.y);
			ctx.lineTo(point2.x, point2.y);
			ctx.lineTo(point3.x, point3.y);
			ctx.lineTo(point4.x, point4.y);
			ctx.lineTo(point1.x, point1.y);

			ctx.stroke();
			ctx.restore();
		},
		[]
	);

	const drawDetection = useCallback(
		(frameDetection: VideoFrameDetection, ctx: CanvasRenderingContext2D) => {
			const { placementId, coordinate } = frameDetection;
			const placement = placementsRef.current.find((x) => x.id === placementId);
			const isFiltered = isFilteredDetection(
				frameDetection,
				filteredBrandIdsRef.current,
				filteredPlacementIdsRef.current
			);
			const isSelected = selectedDetectionsIdsRef.current.includes(frameDetection.videoDetectionId);
			const shouldDrawCoordinates = !isFiltered || showFilteredDetectionsRef.current;
			if (shouldDrawCoordinates) {
				drawCoordinates(coordinate, placement?.color ?? DEFAULT_DETECTION_COLOR, isSelected, isFiltered, ctx);
			}
		},
		[
			drawCoordinates,
			filteredBrandIdsRef,
			filteredPlacementIdsRef,
			placementsRef,
			selectedDetectionsIdsRef,
			showFilteredDetectionsRef,
		]
	);

	const drawNewDetection = useCallback(
		(detection: EditorVideoNewDetectionWithNullableIds, ctx: CanvasRenderingContext2D, idx: number) => {
			const { placementId, coordinates } = detection;

			// Find the color of this new detection.
			const placement = placementId !== null && placements.find((p) => p.id === placementId);
			const placementColor = !!placement ? placement.color : DEFAULT_DETECTION_COLOR;

			// Find out the correct coordinates with a given time. If one was not found do use the last one found.
			const points = getInterpolatedPointsFromCoordinates(coordinates, currentTimeRef.current, fps);

			if (!!points) drawCoordinates(points, placementColor, detection.selected, false, ctx);
		},
		[placements, currentTimeRef, fps, drawCoordinates]
	);

	const drawFrame = useCallback(
		(
			detections: VideoFrameDetection[],
			newDetections: EditorVideoNewDetectionWithNullableIds[],
			ctx: CanvasRenderingContext2D
		) => {
			ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

			// Draw all existing detections.
			for (let i = 0; i < detections.length; i++) {
				drawDetection(detections[i], ctx);
			}

			// Draw all new detections.
			for (let i = 0; i < newDetections.length; i++) {
				drawNewDetection(newDetections[i], ctx, i);
			}
		},
		[drawDetection, drawNewDetection]
	);

	useEffect(() => {
		if (!fps || !canvasCtx || !videoNode) return;

		const func = () => {
			const frameDetections = getCurrentFrameDetections();
			const scaledFrameDetections = scaleDetections(frameDetections, scaleRef.current);
			const scaledNewDetections = scaleNewDetections(newDetections, scale);
			drawFrame(scaledFrameDetections, scaledNewDetections, canvasCtx);
		};

		const interval = setInterval(func, 1000 / fps);
		return () => clearInterval(interval);
	}, [canvasCtx, drawFrame, fps, getCurrentFrameDetections, newDetections, scale, scaleRef, videoNode]);
};

export default useDrawDetections;
