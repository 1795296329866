import { ListItem, TextField, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { Autocomplete } from '@material-ui/lab';
import SaveModal from 'Components/Common/Modals/SaveModal';
import useFetchFolders from 'Hooks/Http/Folders/useFetchFolders';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import Project from 'Models/Project';
import Video from 'Models/Videos/Video';
import VideoFolder from 'Models/Videos/VideoFolder';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import patchVideoRequest, { PatchVideoRequest } from 'Utils/Http/Requests/Videos/PatchVideoRequest';

interface EditVideoModalProps {
	isOpen: boolean;
	onClose: () => void;
	video: Video | null;
	onVideoEdited: (video: Video) => void;
	projects: Project[];
}

const EditVideoModal = (props: EditVideoModalProps): ReactElement => {
	const [name, setName] = useState(props.video?.name ?? '');
	const [folderId, setFolderId] = useState(props.video?.folderId ?? null);
	const [projectId, setProjectId] = useState(props.video?.projectId ?? null);

	const { data: existingFolders } = useFetchFolders(!props.isOpen);
	const { dispatchStartLoading, dispatchStopLoading } = useLoadingDispatch();
	const { dispatchSetAxiosErrorMessage, dispatchSetMessageAction } = useMessagesDispatch();
	const token = useAppSelector<string | null>((state) => state.profile.token);

	const isSaveButtonDisabled = name === ''; // Aka invalid state.
	const selectedFolder = existingFolders?.find((x) => x.id === folderId) ?? null;
	const selectedProject = props.projects?.find((x) => x.id === projectId) ?? null;

	// Effects updates the input field if folder props get changed.
	useEffect(() => {
		if (props.video === null) return;

		setName(props.video.name);
		setFolderId(props.video.folderId);
		setProjectId(props.video.projectId);
	}, [props.video]);

	const handleModalExited = (): void => {
		setName('');
		setFolderId(null);
	};

	const handleModalSaveClicked: () => void = async () => {
		if (props.video === null) return;

		dispatchStartLoading();
		const patchData: PatchVideoRequest = {
			name,
			folderId,
			projectId,
		};
		try {
			const response = await patchVideoRequest(token, patchData, props.video.id);
			props.onVideoEdited(response.data);
			dispatchSetMessageAction(
				`Successfully updated video ${response.data.name} [${response.data.hash ?? response.data.id}].`,
				MessageStatusLevel.Success
			);
			props.onClose();
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		} finally {
			dispatchStopLoading();
		}
	};

	const handleNameInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
		setName(event.target.value);
	};

	const handleFolderIdSelectChanged = (event: ChangeEvent<{}>, value: VideoFolder | null): void => {
		if (value === null) setFolderId(null);
		else setFolderId(value.id);
	};

	const handleProjectIdSelectChanged = (event: ChangeEvent<{}>, value: Project | null): void => {
		if (value === null) setProjectId(null);
		else setProjectId(value.id);
	};

	return (
		<SaveModal
			open={props.isOpen}
			onClose={props.onClose}
			onModalExited={handleModalExited}
			onSave={handleModalSaveClicked}
			saveButtonDisabled={isSaveButtonDisabled}
		>
			<Typography variant="h5">
				Edit folder {props?.video?.name} [{props?.video?.hash ?? props?.video?.id}]
			</Typography>
			<Divider />
			<List>
				<ListItem>
					<TextField
						required={true}
						label="Name"
						type="text"
						value={name}
						onChange={handleNameInputChanged}
						fullWidth={true}
					/>
				</ListItem>
				<ListItem>
					<Autocomplete
						renderInput={(params) => <TextField {...params} label="Parent Folder" fullWidth={true} />}
						options={existingFolders ?? []}
						fullWidth={true}
						getOptionLabel={(option) => option.name}
						autoHighlight={true}
						getOptionSelected={(option) => option.id === folderId}
						onChange={handleFolderIdSelectChanged}
						value={selectedFolder}
					/>
				</ListItem>
				<ListItem>
					<Autocomplete
						renderInput={(params) => <TextField {...params} label="Project" fullWidth={true} />}
						options={props.projects}
						fullWidth={true}
						getOptionLabel={(option) => option.name}
						autoHighlight={true}
						getOptionSelected={(option) => option.id === projectId}
						onChange={handleProjectIdSelectChanged}
						value={selectedProject}
					/>
				</ListItem>
			</List>
		</SaveModal>
	);
};

export default EditVideoModal;
