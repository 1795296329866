import { fade, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import BrandSelect from 'Components/Common/BrandsSelect';
import EditorButton from 'Components/Common/Buttons/EditorButton';
import EditorDetectionPlacementLabel from 'Components/Common/Labels/EditorDetectionPlacementLabel';
import EditorButtonPlacementSelect from 'Components/Common/EditorButtonPlacementSelect';
import TitledBox from 'Components/Common/TitledBox';
import { EditorMode, UseVideoPlayerInstance } from 'Hooks/Editor/useVideoPlayerInstance';
import VideoDetectionPlacement from 'Models/Videos/VideoDetectionPlacement';
import { ReactElement } from 'react';

interface EditorNewDetectionsActionsProps {
	instance: UseVideoPlayerInstance;
}

const useStyles = makeStyles(({ spacing, palette, transitions, typography }: Theme) => ({
	paper: {
		padding: spacing(2),
	},
	buttonsContainer: {
		fontSize: typography.subtitle1.fontSize,
	},
	root: {
		'& > div': {
			paddingBottom: spacing(2),
		},
		'& > div:last-child': {
			paddingBottom: '0px',
		},
	},
	tableRow: {
		cursor: 'pointer',
		transition: transitions.create(['background-color'], {
			easing: transitions.easing.easeInOut,
			duration: transitions.duration.standard,
		}),
	},
	tableRowSelected: {
		backgroundColor: `${fade(palette.primary.main, 0.3)} !important`,
		'&:hover': {
			backgroundColor: `${fade(palette.primary.main, 0.2)} !important`,
		},
	},
	tableRowDisabled: {
		cursor: 'not-allowed',
		opacity: 0.5,
	},
}));

const EditorNewDetectionsActions = ({ instance }: EditorNewDetectionsActionsProps): ReactElement => {
	const classes = useStyles();

	const selectedDetections = instance.newDetections.filter((x) => x.selected);
	const selectedAreValid = selectedDetections.reduce((acc, x) => {
		if (x.placementId !== null && x.brandId !== null) return acc;
		return false;
	}, true);
	const disabledFinishedButton = selectedDetections.length === 0 || !selectedAreValid;
	const disabledOrdinaryButtons = selectedDetections.length === 0;

	const handleNewDetectionClicked = (): void => {
		instance.setEditorMode(EditorMode.NEW_DETECTION);
	};

	const handleFinishSelectedNewDetectionsClicked = (): void => {
		instance.completeNewDetections();
	};

	const handleDiscardSelectedNewDetectionsClicked = (): void => {
		instance.removeNewDetections();
	};

	const handleBrandSelected = (brandId: number): void => {
		instance.setBrandForNewDetections(brandId);
	};

	const handlePlacementItemClicked = (placement: VideoDetectionPlacement): void => {
		instance.setPlacementForNewDetections(placement.id);
	};

	const handleNewDetectionRowClicked = (idx: number): void => {
		instance.toggleNewDetectionSelection(idx);
	};

	return (
		<TitledBox border={1} title={'New Detections'}>
			<Grid container className={classes.root}>
				<Grid item xs={12}>
					<Grid container spacing={1}>
						<Grid item>
							<EditorButton title="Add new detection" onClick={handleNewDetectionClicked} size="small">
								<AddIcon fontSize="small" />
							</EditorButton>
						</Grid>
						<Grid item>
							<EditorButton
								title="Finish selected new detections"
								onClick={handleFinishSelectedNewDetectionsClicked}
								size="small"
								disabled={disabledFinishedButton}
							>
								<DoneIcon fontSize="small" />
							</EditorButton>
						</Grid>
						<Grid item>
							<EditorButton
								title="Delete"
								onClick={handleDiscardSelectedNewDetectionsClicked}
								size="small"
								disabled={disabledOrdinaryButtons}
							>
								<RemoveCircleOutlineIcon fontSize="small" />
							</EditorButton>
						</Grid>
						<Grid item>
							<BrandSelect
								buttonTitle="Set Brand"
								brandGroups={instance.brandGroups}
								onBrandSelected={handleBrandSelected}
								disabled={disabledOrdinaryButtons}
								sort={true}
							/>
						</Grid>
						<Grid item>
							<EditorButtonPlacementSelect
								buttonTitle="Set placement"
								placements={instance.placements}
								onPlacementSelected={handlePlacementItemClicked}
								disabled={disabledOrdinaryButtons}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>#</TableCell>
									<TableCell>Brand</TableCell>
									<TableCell>Placement</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{instance.newDetections.map((d, i) => {
									const brand = instance.brands.find((b) => b.id === d.brandId);
									const brandName = brand?.name ?? 'Not set';

									const placement = instance.placements.find((p) => p.id === d.placementId);
									const placementJSX = !!placement ? (
										<EditorDetectionPlacementLabel placement={placement} />
									) : (
										'Not set'
									);
									return (
										<TableRow
											className={classes.tableRow}
											classes={{
												selected: classes.tableRowSelected,
											}}
											key={i}
											hover
											onClick={() => handleNewDetectionRowClicked(i)}
											selected={d.selected}
										>
											<TableCell>{i}</TableCell>
											<TableCell>{brandName}</TableCell>
											<TableCell>{placementJSX}</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</TitledBox>
	);
};

export default EditorNewDetectionsActions;
