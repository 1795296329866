import { AxiosPromise } from 'axios';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

type LogoutRequest = (token: string | null) => AxiosPromise<void>;

const logoutRequest: LogoutRequest = async (token: string | null) => {
	const url = getApiUrl(ApiUrl.Logout);
	const axios = getAxiosInstance(token);
	return axios.post<void>(url);
};

export default logoutRequest;
