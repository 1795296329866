import { CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useAppLoadingModalStyles from 'Hooks/Styles/Common/useAppLoadingModalStyles';
import { ReactElement } from 'react';

const AppLoadingModal = (): ReactElement | null => {
	const { title, isLoading } = useAppSelector((state) => state.loading);
	const classes = useAppLoadingModalStyles();

	if (!isLoading) return null;

	return (
		<div className={classes.overlay}>
			<div className={classes.middle}>
				<CircularProgress className={classes.progress} size={window.innerWidth * 0.1} />
				{!!title && (
					<Typography variant="h5" color="primary">
						{title}
					</Typography>
				)}
			</div>
		</div>
	);
};

export default AppLoadingModal;
