import { Dispatch, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AppAction } from 'Redux/Actions';

type UseAppDispatch = (action: AppAction) => void;

const useAppDispatch = (): UseAppDispatch => {
	const dispatch = useDispatch<Dispatch<AppAction>>();
	return useCallback((action: AppAction) => dispatch(action), [dispatch]);
};

export default useAppDispatch;
