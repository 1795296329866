import axios, { AxiosPromise } from 'axios';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

interface LoginResponse {
	token: string;
}

export type LoginRequest = (email: string, password: string) => AxiosPromise<LoginResponse>;

const loginRequest: LoginRequest = async (email, password) => {
	const url = getApiUrl(ApiUrl.Login);
	const request = { email, password };
	return axios.post<LoginResponse>(url, request);
};

export default loginRequest;
