import useDataFetcher, { UseDataFetcherReturn } from 'Hooks/Http/useDataFetcher';
import Project from 'Models/Project';
import { useCallback } from 'react';
import getProjectRequest from 'Utils/Http/Requests/Projects/GetProjectRequest';

const useFetchProject = (projectId: number | null): UseDataFetcherReturn<Project> => {
	const fetcherFunction = useCallback((token: string | null) => getProjectRequest(token, projectId ?? 0), [projectId]);
	return useDataFetcher(fetcherFunction, projectId === null);
};

export default useFetchProject;
