import { Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import FilterListIcon from '@material-ui/icons/FilterList';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import RepeatIcon from '@material-ui/icons/Repeat';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import EditorButton from 'Components/Common/Buttons/EditorButton';
import FilterDialog from 'Components/Common/Dialogs/FilterDialog/FilterDialog';
import ReportExportWizard from 'Components/Common/ReportExportWizard';
import { PlayMode, UseVideoPlayerInstance, VideoPlayerDetailsAreaMode } from 'Hooks/Editor/useVideoPlayerInstance';
import { ReactElement, useState } from 'react';

interface PlayerUtilityControlsProps {
	instance: UseVideoPlayerInstance;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
	formGroup: {
		'& > div': {
			marginBottom: spacing(1),
		},
		'& > div:last-child': {
			marginBottom: 0,
		},
	},
	root: {
		display: 'inline-block',
	},
}));

const PlayerUtilityControls = ({ instance }: PlayerUtilityControlsProps): ReactElement => {
	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
	const [isExportModalOpen, setIsExportModalOpen] = useState(false);

	const classes = useStyles();

	const handlePlayModeChanged = (): void => {
		instance.togglePlayMode();
	};

	const handleFilterButtonClicked = (): void => {
		setIsFilterModalOpen(true);
	};

	const handleVisibleFiltersButtonClick = (): void => {
		instance.toggleShowFilteredDetections();
	};

	const HandleChangePlacementForAllDetections = (): void => {
		instance.setDetailsAreaMode((prev) => {
			if (prev === VideoPlayerDetailsAreaMode.DETECTIONS) return VideoPlayerDetailsAreaMode.CHANGE_ALL_PLACEMENTS;
			return VideoPlayerDetailsAreaMode.DETECTIONS;
		});
	};

	const handleExportExcelReportButtonClick = (): void => {
		setIsExportModalOpen(true);
	};

	const handleFilterModalClose = (): void => {
		setIsFilterModalOpen(false);
	};

	const handleCloseExportModal = (): void => {
		setIsExportModalOpen(false);
	};

	return (
		<>
			<Box border={1} className={classes.root}>
				<FormControl className={classes.formGroup}>
					<EditorButton
						title={instance.playMode === PlayMode.NORMAL ? 'Set play mode to 1 second' : 'Set play mode to normal'}
						onClick={handlePlayModeChanged}
						hotkey="m"
					>
						{instance.playMode === PlayMode.NORMAL ? <AvTimerIcon /> : <LooksOneIcon />}
					</EditorButton>
					<EditorButton title="Filters" onClick={handleFilterButtonClicked} hotkey="f">
						<FilterListIcon />
					</EditorButton>
					<EditorButton
						title={instance.showFilteredDetections ? 'Hide filtered detections' : 'Show filtered detections'}
						onClick={handleVisibleFiltersButtonClick}
						hotkey="v"
					>
						{instance.showFilteredDetections ? <VisibilityIcon /> : <VisibilityOffIcon />}
					</EditorButton>
					<EditorButton
						title="Change placement for all detections"
						onClick={HandleChangePlacementForAllDetections}
						active={instance.detailsAreaMode === VideoPlayerDetailsAreaMode.CHANGE_ALL_PLACEMENTS}
					>
						<RepeatIcon />
					</EditorButton>
					<EditorButton title="Export Excel Report" onClick={handleExportExcelReportButtonClick}>
						<VerticalAlignBottomIcon />
					</EditorButton>
				</FormControl>
			</Box>
			<FilterDialog
				brands={instance.brands}
				placements={instance.placements}
				open={isFilterModalOpen}
				onClose={handleFilterModalClose}
				filteredBrandIds={instance.filteredBrandIds}
				filteredPlacementIds={instance.filteredPlacementIds}
				onBrandSelectionChanged={instance.handleBrandFilterChanged}
				onPlacementSelectionChanged={instance.handlePlacementFilterChanged}
				onAllBrandSelectionChanged={instance.handleAllBrandFilterChanged}
				onAllPlacementSelectionChanged={instance.handleAllPlacementFilterChanged}
			/>
			{!!instance.video && (
				<ReportExportWizard
					isOpen={isExportModalOpen}
					onClose={handleCloseExportModal}
					placements={instance.placements}
					brandGroups={instance.brandGroups}
					selectedVideoIds={[instance.video?.id ?? 0]}
					videos={[instance.video]}
				/>
			)}
		</>
	);
};

export default PlayerUtilityControls;
