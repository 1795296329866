import { fade } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useEditorActionsStyles = makeStyles(({ spacing, palette, transitions, typography }: Theme) =>
	createStyles({
		paper: {
			padding: spacing(2),
		},
		buttonsContainer: {
			fontSize: typography.subtitle1.fontSize,
		},
		root: {
			'& > div': {
				paddingBottom: spacing(2),
			},
			'& > div:last-child': {
				paddingBottom: '0px',
			},
		},
		tableRow: {
			cursor: 'pointer',
			transition: transitions.create(['background-color'], {
				easing: transitions.easing.easeInOut,
				duration: transitions.duration.standard,
			}),
		},
		tableRowSelected: {
			backgroundColor: `${fade(palette.primary.main, 0.3)} !important`,
			'&:hover': {
				backgroundColor: `${fade(palette.primary.main, 0.2)} !important`,
			},
		},
		tableRowDisabled: {
			cursor: 'not-allowed',
			opacity: 0.5,
		},
	})
);

export default useEditorActionsStyles;
