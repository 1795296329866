import useDataFetcher, { UseDataFetcherReturn } from 'Hooks/Http/useDataFetcher';
import Video from 'Models/Videos/Video';
import { useCallback } from 'react';
import getVideoRequest from 'Utils/Http/Requests/Videos/GetVideoRequest';

const useFetchVideo = (videoId: number | null): UseDataFetcherReturn<Video> => {
	const id = videoId ?? 0;
	const doNotFetch = id === 0;

	const fetcherFunction = useCallback((token: string | null) => getVideoRequest(token, id), [id]);
	return useDataFetcher(fetcherFunction, doNotFetch);
};

export default useFetchVideo;
