import useIndexDispatch from 'Hooks/Redux/useIndexDispatch';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'Redux/Reducers';
import logoutRequest from 'Utils/Http/Requests/Profile/LogoutRequest';
import { clearStorage } from 'Utils/LocalStorageHelper';

type UseLogout = () => () => Promise<void>;

const useLogout: UseLogout = () => {
	const token = useSelector<AppState, string | null>((state) => state.profile.token);
	const { dispatchLogout } = useIndexDispatch();

	return useCallback(async () => {
		try {
			await logoutRequest(token);
		} finally {
			// Delete all redux data.
			dispatchLogout();
			// Delete all Storage data.
			clearStorage();
		}
	}, [dispatchLogout, token]);
};

export default useLogout;
