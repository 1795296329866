import { createStyles, Theme } from '@material-ui/core';
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useAppSnackbarMessageStyles = makeStyles((theme: Theme) =>
	createStyles({
		success: {
			backgroundColor: green[600],
		},
		error: {
			backgroundColor: theme.palette.error.dark,
		},
		info: {
			backgroundColor: theme.palette.primary.dark,
		},
		warning: {
			backgroundColor: amber[700],
		},
		icon: {
			fontSize: 20,
			opacity: 0.9,
			marginRight: theme.spacing(),
		},
		message: {
			display: 'flex',
			alignItems: 'center',
		},
	})
);

export default useAppSnackbarMessageStyles;
