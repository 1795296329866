import { ListItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EditorDetails from 'Components/Editor/Details/EditorDetails';
import EditorDetectionsActions from 'Components/Editor/EditorDetectionsActions';
import EditorNewDetectionsActions from 'Components/Editor/EditorNewDetectionsActions';
import VideoPlayer from 'Components/Editor/VideoPlayer/VideoPlayer';
import useSaveEditorTime from 'Hooks/Editor/useSaveEditorTime';
import useSetVideoEditorTime from 'Hooks/Editor/useSetVideoEditorTime';
import useVideoPlayerInstance from 'Hooks/Editor/useVideoPlayerInstance';
import Layout from 'Layout';
import { ReactElement } from 'react';

const useClasses = makeStyles({
	root: {
		flexGrow: 1,
	},
});
const EditorView = (): ReactElement => {
	const classes = useClasses();
	const videoPlayerInstance = useVideoPlayerInstance();
	useSaveEditorTime(videoPlayerInstance.videoNode);
	useSetVideoEditorTime(videoPlayerInstance.videoNode);

	const video = videoPlayerInstance.video;

	return (
		<Layout title={video !== null ? `Editor - ${video.name} (${video.hash})` : 'Editor'}>
			<Grid container spacing={2} className={classes.root}>
				<Grid item xs={9}>
					<Grid container={true}>
						<Grid item={true} xs={12}>
							<VideoPlayer instance={videoPlayerInstance} />
						</Grid>
						<Grid item={true} xs={12}>
							<EditorDetails instance={videoPlayerInstance} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={3}>
					<List>
						<ListItem>
							<EditorDetectionsActions instance={videoPlayerInstance} />
						</ListItem>
						<ListItem>
							<EditorNewDetectionsActions instance={videoPlayerInstance} />
						</ListItem>
					</List>
				</Grid>
			</Grid>
		</Layout>
	);
};

export default EditorView;
