import { AxiosPromise } from 'axios';
import BrandGroup from 'Models/Brands/BrandGroup';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export interface GetBrandGroupsResponse {
	groups: BrandGroup[];
}

export type GetBrandGroupsRequest = (token: string | null, refresh?: boolean) => AxiosPromise<GetBrandGroupsResponse>;

const getBrandGroupsRequest: GetBrandGroupsRequest = async (token, refresh) => {
	const url = getApiUrl(ApiUrl.BrandGroups);
	const params = { refresh: !!refresh };
	const axios = getAxiosInstance(token);
	return axios.get<GetBrandGroupsResponse>(url, { params });
};

export default getBrandGroupsRequest;
