import { AxiosPromise } from 'axios';
import Project from 'Models/Project';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

const postProjectRequest = (token: string | null, project: Omit<Project, 'id'>): AxiosPromise<Project> => {
	const url = getApiUrl(ApiUrl.Projects);
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.post<Project>(url, project);
};

export default postProjectRequest;
