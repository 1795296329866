import ConfirmModal from 'Components/Common/Modals/ConfirmModal';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import Video from 'Models/Videos/Video';
import { ReactElement } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import postVideoRefreshRequest from 'Utils/Http/Requests/Videos/PostVideoRefreshRequest';

interface RefreshVideoModalProps {
	isOpen: boolean;
	onClose: () => void;
	video: Video | null;
}

const RefreshVideoModal = (props: RefreshVideoModalProps): ReactElement => {
	const { dispatchStartLoading, dispatchStopLoading } = useLoadingDispatch();
	const { dispatchSetAxiosErrorMessage, dispatchSetMessageAction } = useMessagesDispatch();
	const token = useAppSelector<string | null>((state) => state.profile.token);

	const handleConfirmButtonClicked = async (): Promise<void> => {
		if (props.video === null) return;

		dispatchStartLoading();
		try {
			await postVideoRefreshRequest(token, props.video.id);
			dispatchSetMessageAction(
				`Started the request to refresh data for video ${props.video.name} [${props.video.id}]`,
				MessageStatusLevel.Info
			);
			props.onClose();
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		} finally {
			dispatchStopLoading();
		}
	};

	return (
		<ConfirmModal
			onConfirmClicked={handleConfirmButtonClicked}
			title="Refresh detections"
			open={props.isOpen}
			confirmButtonText={'Refresh'}
			onClose={props.onClose}
		>
			<p>
				Do you wish to refresh reload all detections for video{' '}
				<b>
					{props.video?.name} [{props.video?.id}]
				</b>
				?
			</p>
			<p>
				<b>Warning:</b> This will delete all existing detections for this video and reload all detections if video data
				is still available.
			</p>
		</ConfirmModal>
	);
};

export default RefreshVideoModal;
