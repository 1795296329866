import { Fade } from '@material-ui/core';
import { ReactElement, ReactNode } from 'react';

interface ConditionalDisplayProps {
	displayContent: boolean;
	children: ReactNode;
}

const ConditionalDisplay = (props: ConditionalDisplayProps): ReactElement => {
	return (
		<Fade in={props.displayContent}>
			<div>{props.children} </div>
		</Fade>
	);
};

export default ConditionalDisplay;
