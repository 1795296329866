import { Slider, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { isArray } from '@material-ui/data-grid';
import ChangeAllPlacementCard from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCard';
import { DetectionAngleSpecification } from 'Models/Project/ProjectFilterSpecifications';
import { ChangeEvent, ReactElement } from 'react';

interface ChangeAllPlacementCardAngleProps {
	angle: DetectionAngleSpecification | null;
	onAngleChanged: (angle: DetectionAngleSpecification | null) => void;
}

const INITIAL_ANGLE_VALUE: DetectionAngleSpecification = { from: 0, to: 0 };

const ChangeAllPlacementCardAngle = (props: ChangeAllPlacementCardAngleProps): ReactElement => {
	const fromValue = props.angle?.from ?? 0;
	const toValue = props.angle?.to ?? 0;

	const marks = Array.from(Array(9).keys()).map((x) => ({
		value: x * 45,
		label: `${x * 45}°`,
	}));

	const handlePlacementCardCheckedChanged = (checked: boolean): void => {
		if (checked) props.onAngleChanged(INITIAL_ANGLE_VALUE);
		else props.onAngleChanged(null);
	};

	const setValues = (from: number, to: number): void => {
		props.onAngleChanged({ from, to });
	};

	const handleSliderChanged = (_: ChangeEvent<{}>, newValue: number | number[]): void => {
		if (isArray(newValue)) setValues(newValue[0], newValue[1]);
		else console.error(`Cannot set angle. Given value '${newValue}' is invalid. Expected array with length 2.`);
	};

	const handleFromChanged = ({ target }: ChangeEvent<HTMLInputElement>) => {
		const value = Number(target.value);
		if (!isNaN(value) && value <= toValue) setValues(value, toValue);
	};

	const handleToChanged = ({ target }: ChangeEvent<HTMLInputElement>) => {
		const value = Number(target.value);
		if (!isNaN(value) && value >= fromValue) setValues(fromValue, value);
	};

	return (
		<ChangeAllPlacementCard name="Select angle" onCheckedChanged={handlePlacementCardCheckedChanged}>
			<FormControl fullWidth={true} margin="normal">
				<Typography id="range-slider" gutterBottom>
					Angle
				</Typography>
				<Slider
					value={[fromValue, toValue]}
					onChange={handleSliderChanged}
					defaultValue={0}
					marks={marks}
					valueLabelDisplay="auto"
					min={0}
					max={360}
				/>
			</FormControl>
			<FormControl fullWidth={true} margin="normal">
				<InputLabel required={true}>From</InputLabel>
				<Input
					onChange={handleFromChanged}
					value={fromValue}
					required={true}
					inputProps={{
						step: 1,
						min: 0,
						max: toValue,
						type: 'number',
					}}
				/>
			</FormControl>
			<FormControl fullWidth={true} margin="normal">
				<InputLabel required={true}>To</InputLabel>
				<Input
					onChange={handleToChanged}
					value={toValue}
					required={true}
					inputProps={{
						step: 1,
						min: fromValue,
						max: 360,
						type: 'number',
					}}
				/>
			</FormControl>
		</ChangeAllPlacementCard>
	);
};

export default ChangeAllPlacementCardAngle;
