import { Checkbox, InputLabel, MenuItem, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import ChangeAllPlacementCard from 'Components/Editor/Details/ChangeAllPlacements/ChangeAllPlacementCard';
import VideoDetectionPlacement from 'Models/Videos/VideoDetectionPlacement';
import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { isNumberArray } from 'Utils/TypeGuards';

interface ChangeAllPlacementCardPlacementsProps {
	placements: VideoDetectionPlacement[];
	selectedIds: number[] | null;
	onSelectedIdsChanged: (ids: number[] | null) => void;
}

const ChangeAllPlacementCardPlacements = (props: ChangeAllPlacementCardPlacementsProps): ReactElement => {
	const handleCheckedChanged = (checked: boolean): void => {
		if (checked) props.onSelectedIdsChanged([]);
		else props.onSelectedIdsChanged(null);
	};

	const handlePlacementSelectionChanged = ({ target }: React.ChangeEvent<{ value: unknown }>): void => {
		if (isNumberArray(target.value)) props.onSelectedIdsChanged(target.value);
		else console.error(`expected number array but instead got 'target.value'`);
	};

	const renderValue = (selected: unknown): ReactNode => {
		if (!isNumberArray(selected)) return null;
		const placementNames = props.placements.filter((x) => selected.includes(x.id)).map((x) => x.name);
		return placementNames.join(', ');
	};

	return (
		<ChangeAllPlacementCard name={'Select placements'} onCheckedChanged={handleCheckedChanged}>
			<FormControl fullWidth={true} margin="normal">
				<InputLabel id="change-all-placement-placements-label">Placements</InputLabel>
				<Select
					labelId="change-all-placement-placements-label"
					value={props.selectedIds ?? []}
					onChange={handlePlacementSelectionChanged}
					multiple={true}
					renderValue={renderValue}
				>
					{props.placements.map((x) => (
						<MenuItem value={x.id} key={x.id}>
							<Checkbox checked={props.selectedIds?.includes(x.id)} />
							<ListItemText primary={x.name} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</ChangeAllPlacementCard>
	);
};

export default ChangeAllPlacementCardPlacements;
