import { User } from 'Models/User';
import { Action } from 'redux';

export enum ProfileActionType {
	Login = 'PROFILE_LOGIN',
	SetUser = 'PROFILE_SET_USER',
}

export interface LoginAction extends Action<ProfileActionType.Login> {
	token: string;
}

export interface SetUserAction extends Action<ProfileActionType.SetUser> {
	user: User;
}

export type ProfileAction = LoginAction | SetUserAction;
