import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useIndexDispatch from 'Hooks/Redux/useIndexDispatch';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import { ErrorResponse } from 'Models/ErrorResponse';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import { deleteToken } from 'Utils/LocalStorageHelper';
import { createNewGuid } from 'Utils/uuid';

type UseDataFetcherFunction<TResponse> = (token: string | null) => AxiosPromise<TResponse>;

export interface UseDataFetcherReturn<TResponse> {
	data: TResponse | null;
	loading: boolean;
	error: ErrorResponse | null;
	setData: Dispatch<SetStateAction<TResponse | null>>;
}

const useDataFetcher = <TResponse>(
	fetcher: UseDataFetcherFunction<TResponse>,
	doNotFetch?: boolean
): UseDataFetcherReturn<TResponse> => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<ErrorResponse | null>(null);
	const [data, setData] = useState<TResponse | null>(null);

	const requestTokens = useRef<string[]>([]);

	const token = useAppSelector((state) => state.profile.token);
	const { dispatchLogout } = useIndexDispatch();
	const { dispatchSetMessageAction } = useMessagesDispatch();

	const createNewRequestToken = useCallback((): string => {
		const guid = createNewGuid();
		requestTokens.current.push(guid);
		return guid;
	}, []);

	const removeRequestToken = useCallback((token: string): void => {
		requestTokens.current = requestTokens.current.filter((x) => x !== token);
	}, []);

	const executeIfTokenExist = useCallback((func: () => void, requestToken: string): void => {
		if (requestTokens.current.includes(requestToken)) {
			func();
		}
	}, []);

	const setLoadingIfTokenExist = useCallback(
		(loading: boolean, tokenRequest: string): void => {
			const func = () => setLoading(loading);
			executeIfTokenExist(func, tokenRequest);
		},
		[executeIfTokenExist]
	);

	const setErrorIfTokenExist = useCallback(
		(error: ErrorResponse | null, tokenRequest: string): void => {
			const func = () => setError(error);
			executeIfTokenExist(func, tokenRequest);
		},
		[executeIfTokenExist]
	);

	const setDataIfTokenExist = useCallback(
		(data: TResponse, tokenRequest: string): void => {
			const func = () => setData(data);
			executeIfTokenExist(func, tokenRequest);
		},
		[executeIfTokenExist]
	);

	const responseHandler = useCallback(
		(response: AxiosResponse<TResponse>, requestToken: string): void => {
			setErrorIfTokenExist(null, requestToken);
			setDataIfTokenExist(response.data, requestToken);
		},
		[setDataIfTokenExist, setErrorIfTokenExist]
	);

	const errorHandler = useCallback(
		(error: AxiosError<ErrorResponse>, requestToken: string): void => {
			const errorResponse = error.response?.data;
			if (!!errorResponse) {
				setErrorIfTokenExist(errorResponse, requestToken);
			} else {
				setErrorIfTokenExist(
					{
						code: Number(error.code),
						message: error.message,
						url: '',
					},
					requestToken
				);
			}
		},
		[setErrorIfTokenExist]
	);

	const finallyHandler = useCallback(
		(requestToken: string): void => {
			setLoadingIfTokenExist(false, requestToken);
			removeRequestToken(requestToken);
		},
		[removeRequestToken, setLoadingIfTokenExist]
	);

	useEffect(() => {
		// If token is not set we don't want to retrieve data. This will 100% fail.
		if (token === null || doNotFetch) return;

		const requestToken = createNewRequestToken();
		setLoadingIfTokenExist(true, requestToken);
		fetcher(token)
			.then((response) => responseHandler(response, requestToken))
			.catch((error: AxiosError<ErrorResponse>) => errorHandler(error, requestToken))
			.finally(() => finallyHandler(requestToken));
		return () => {
			removeRequestToken(requestToken);
		};
	}, [
		createNewRequestToken,
		doNotFetch,
		errorHandler,
		fetcher,
		finallyHandler,
		removeRequestToken,
		responseHandler,
		setLoadingIfTokenExist,
		token,
	]);

	useEffect(
		// If we get 401 response we must log out user as he is not authorized to use this app anymore. He needs to login again.
		() => {
			if (error !== null && error.code === 401) {
				dispatchLogout();
				deleteToken();
			}
		},
		[dispatchLogout, error]
	);

	useEffect(() => {
		if (!!error) {
			dispatchSetMessageAction(error.message, MessageStatusLevel.Error, 3000);
		}
	}, [dispatchSetMessageAction, error]);

	return {
		data,
		error,
		loading,
		setData,
	};
};

export default useDataFetcher;
