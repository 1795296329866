import { Card, CardActions, Tooltip } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIconButton from 'Components/Common/Buttons/DeleteIconButton';
import EditIconButton from 'Components/Common/Buttons/EditIconButton';
import useVideoDispatch from 'Hooks/Redux/useVideoDispatch';
import useCommonClasses from 'Hooks/Styles/useCommonClasses';
import { ReactElement } from 'react';

interface VideoFolderCardProps {
	name: string;
	id: number;
	onDeleteButtonClicked: (id: number) => void;
	onEditButtonClicked: (id: number) => void;
}

const CARD_CONTENT_SIZE = 96;
const useClasses = makeStyles({
	content: {
		height: CARD_CONTENT_SIZE,
		textAlign: 'center',
	},
	icon: {
		fontSize: '64px',
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
});

const VideoFolderCard = ({
	id,
	name,
	onDeleteButtonClicked,
	onEditButtonClicked,
}: VideoFolderCardProps): ReactElement => {
	const commonClasses = useCommonClasses();
	const classes = useClasses();

	const { dispatchSelectFolder } = useVideoDispatch();

	const handleCardClick = (): void => {
		dispatchSelectFolder(id);
	};

	const handleDeleteButtonClicked = (): void => {
		onDeleteButtonClicked(id);
	};

	const handleEditButtonClicked = (): void => {
		onEditButtonClicked(id);
	};

	return (
		<Card className={commonClasses.cardBackground}>
			<CardActionArea onClick={handleCardClick}>
				<Tooltip title={name} placement="top">
					<CardContent>
						<div className={classes.content}>
							<FolderIcon className={classes.icon} />
							<Typography variant="h5" component="h2" noWrap>
								{name}
							</Typography>
						</div>
					</CardContent>
				</Tooltip>
			</CardActionArea>
			<CardActions className={classes.actionsContainer}>
				<EditIconButton onClick={handleEditButtonClicked} />
				<DeleteIconButton onClick={handleDeleteButtonClicked} />
			</CardActions>
		</Card>
	);
};

export default VideoFolderCard;
