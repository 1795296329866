import classNames from 'classnames';
import useValueRef from 'Hooks/useValueRef';
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { Button, createStyles, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isExpectedKey, Key } from 'Utils/Keys';

type EditorButtonClicked = () => void;

interface EditorButtonHotkey {
	key: Key;
	withAlt?: boolean;
	withCtrl?: boolean;
	withShift?: boolean;
}

interface EditorButtonProps {
	title: string;
	onClick?: EditorButtonClicked;
	hotkey?: EditorButtonHotkey | Key;
	disabled?: boolean;
	size?: 'small' | 'medium' | 'large';
	active?: boolean;
}

type DecoratedEditorButtonProps = React.PropsWithChildren<EditorButtonProps>;

const EditorButton = React.forwardRef<HTMLButtonElement, DecoratedEditorButtonProps>((props, ref) => {
	const { title, onClick, children, hotkey, disabled, size, active } = props;

	const onClickRef = useValueRef(onClick);
	const hotkeyRef = useValueRef(hotkey);
	const classes = useStyles();

	const getHotkeyTitle = (): string | null => {
		if (!!hotkey && isEditorButtonHotkey(hotkey)) {
			let title: string = '';
			if (hotkey.withCtrl) title += 'ctrl + ';
			if (hotkey.withAlt) title += 'alt + ';
			if (hotkey.withShift) title += 'shift + ';
			return title + hotkey.key;
		}

		return hotkey || null;
	};

	const handleKeyDown = useCallback(
		(event: KeyboardEvent): void => {
			if (!hotkeyRef.current) {
				return;
			}
			let hotkeyPressed: boolean;
			if (isEditorButtonHotkey(hotkeyRef.current)) {
				const { key, withAlt = false, withCtrl = false, withShift = false } = hotkeyRef.current;
				hotkeyPressed =
					isExpectedKey(event, key) &&
					withAlt === event.altKey &&
					withCtrl === event.ctrlKey &&
					withShift === event.shiftKey;
			} else {
				hotkeyPressed = isExpectedKey(event, hotkeyRef.current);
			}

			if (hotkeyPressed && onClickRef.current) {
				onClickRef.current();
			}
		},
		[onClickRef, hotkeyRef]
	);

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown, false);
		return () => window.removeEventListener('keydown', handleKeyDown);
	}, [handleKeyDown]);

	const hotkeyTitle = getHotkeyTitle();
	const titleWithHotkey = !!hotkeyTitle ? `${title} (hotkey: ${hotkeyTitle})` : title;
	const buttonClasses = useMemo(
		() =>
			classNames({
				[classes.root]: true,
				[classes.active]: active === true,
			}),
		[classes.root, classes.active, active]
	);

	return useMemo(
		(): JSX.Element => (
			<Tooltip title={titleWithHotkey} placement="left">
				<div>
					{' '}
					{/* Div suppresses errors for disabled buttons. */}
					<Button variant="text" onClick={onClick} disabled={disabled} size={size} ref={ref} className={buttonClasses}>
						{children}
					</Button>
				</div>
			</Tooltip>
		),
		[titleWithHotkey, onClick, disabled, size, ref, buttonClasses, children]
	);
});

export default EditorButton;

const isEditorButtonHotkey = (param: Key | EditorButtonHotkey): param is EditorButtonHotkey => {
	return (param as EditorButtonHotkey).key !== undefined;
};

const useStyles = makeStyles(({ palette }) =>
	createStyles({
		root: {
			minWidth: '32px',
		},
		active: {
			backgroundColor: palette.grey.A400,
			color: palette.common.white,
			'&:hover': { backgroundColor: palette.grey.A200 },
		},
	})
);
