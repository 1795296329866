import { AxiosPromise } from 'axios';
import VideoFolder from 'Models/Videos/VideoFolder';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';
import { PostFolderRequest } from 'Utils/Http/Requests/Folder/PostFolderRequest';

export interface PatchFolderRequest extends Omit<PostFolderRequest, 'name'> {
	name?: string;
}

const patchFolderRequest = (
	token: string | null,
	folder: PatchFolderRequest,
	folderId: number
): AxiosPromise<VideoFolder> => {
	const url = getApiUrl(ApiUrl.FoldersFolder).replace(':folderId', folderId.toString());
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.patch(url, folder);
};

export default patchFolderRequest;
