import { createNewGuid } from 'Utils/uuid';

interface DownloadBlobOptions {
	filename?: string;
}

const createLinkElementAndSetAttributes = (blob: Blob, options?: DownloadBlobOptions): HTMLAnchorElement => {
	const url = window.URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = url;
	link.type = 'hidden';
	link.download = options?.filename ?? createNewGuid();
	return link;
};

const downloadBlob = (blob: Blob, options?: DownloadBlobOptions): void => {
	const link = createLinkElementAndSetAttributes(blob, options);
	document.body.appendChild(link);
	link.click();
	link.remove();
};

export default downloadBlob;
