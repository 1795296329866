export interface BrandActivation {
	id: number;
	name: string;
	exampleUrl: string;
	requestHash: string;
	status: string;
	message: string;
	requested: number;
	processed: number;
	created: number;
	exampleImagesCount: number;
}

export const BRAND_ACTIVATION_STATUS_PROCESSING = 'Processing';

export default BrandActivation;
