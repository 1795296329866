import { AxiosPromise } from 'axios';
import BrandActivation from 'Models/Brands/BrandActivation';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export interface GetBrandActivationsResponse {
	brandActivations: BrandActivation[];
}

export type GetBrandActivationsRequest = (token: string | null) => AxiosPromise<GetBrandActivationsResponse>;

const getBrandActivationsRequest: GetBrandActivationsRequest = async (token) => {
	const url = getApiUrl(ApiUrl.BrandActivations);
	const axios = getAxiosInstance(token);
	return await axios.get<GetBrandActivationsResponse>(url);
};

export default getBrandActivationsRequest;
