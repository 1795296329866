import { ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SaveModal from 'Components/Common/Modals/SaveModal';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import useVideoDispatch from 'Hooks/Redux/useVideoDispatch';
import { ChangeEvent, MouseEvent, ReactElement, useState } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';

interface CreateVideoModalProps {
	isOpen: boolean;
	onClose: () => void;
}

const CreateVideoModal = (props: CreateVideoModalProps): ReactElement => {
	const [files, setFiles] = useState<File[]>([]);

	const { dispatchAddVideosToUpload } = useVideoDispatch();
	const { dispatchSetMessageAction } = useMessagesDispatch();

	const isSaveButtonDisabled = files.length === 0;

	const handleModalSaveClicked = (): void => {
		dispatchAddVideosToUpload(files);
		dispatchSetMessageAction(`Added ${files.length} new videos to upload queue`, MessageStatusLevel.Info);
		props.onClose();
	};

	const handleModalExited = (): void => {
		setFiles([]);
	};

	const handleInputFieldChanged = (event: ChangeEvent<HTMLInputElement>): void => {
		const files = event.target.files;

		setFiles((prev) => {
			if (!files) return prev;

			const newState = [...prev];
			for (let i = 0; i < files.length; i++) {
				let f = files[i];
				if (!newState.find((x) => x.name === f.name)) newState.push(f);
			}
			return newState;
		});
	};

	const handleRemoveUploadedImage = (
		event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>,
		idx: number
	): void => {
		setFiles((prev) => {
			const newState = [...prev];
			newState.splice(idx, 1);
			return newState;
		});
	};

	return (
		<SaveModal
			onSave={handleModalSaveClicked}
			open={props.isOpen}
			onClose={props.onClose}
			saveButtonDisabled={isSaveButtonDisabled}
			onModalExited={handleModalExited}
		>
			<FormControl margin="normal" fullWidth={true}>
				<input
					accept="video/mp4"
					style={{ display: 'none' }}
					id="contained-button-file"
					multiple={true}
					type="file"
					onChange={handleInputFieldChanged}
				/>
				<label htmlFor="contained-button-file">
					<Button variant="contained" component="span">
						Add Video
					</Button>
				</label>
			</FormControl>
			<Typography>Selected videos: {files.length}</Typography>
			<List>
				{files.map((x, i) => {
					return (
						<ListItem key={x.name}>
							<ListItemText>{x.name}</ListItemText>
							<ListItemSecondaryAction>
								<IconButton color="inherit" onClick={(e) => handleRemoveUploadedImage(e, i)}>
									<CloseIcon />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					);
				})}
			</List>
		</SaveModal>
	);
};

export default CreateVideoModal;
