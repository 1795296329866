import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ItemCardsSkeleton from 'Components/Common/ItemCardsSkeleton';
import Loader from 'Components/Common/Loader';
import { MainMenuListItemSubItem } from 'Components/Layout/MainMenuListItem';
import EditPlacementModal from 'Components/Placements/EditPlacementModal';
import PlacementCardItem from 'Components/Placements/PlacementCardItem';
import useFetchPlacements from 'Hooks/Http/Videos/Placements/useFetchPlacements';
import Layout from 'Layout';
import { UserClaimFlag } from 'Models/User';
import VideoDetectionPlacement from 'Models/Videos/VideoDetectionPlacement';
import { ReactElement, useState } from 'react';

const DEFAULT_SKELETON_ITEMS_COUNT = 64;

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
	},
});

const PlacementsView = (): ReactElement => {
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [editingPlacement, setEditingPlacement] = useState<VideoDetectionPlacement | null>(null);

	const placementsState = useFetchPlacements();
	const classes = useStyles();

	const placements = placementsState?.data ?? [];

	const handleOnCreatePlacementClicked = (): void => {
		setIsEditModalOpen(true);
	};

	const handlePlacementEditClicked = (placementId: number): void => {
		const placement = placements.find((x) => x.id === placementId);
		if (placement === undefined) return;

		setEditingPlacement(placement);
		setIsEditModalOpen(true);
	};

	const handleClosePlacementModal = (): void => {
		setEditingPlacement(null);
		setIsEditModalOpen(false);
	};

	const handleSavedPlacement = (isCreate: boolean, placement: VideoDetectionPlacement): void => {
		if (isCreate) placementsState.setData((prev) => (prev !== null ? [...prev, placement] : null));
		else {
			placementsState.setData(
				(prev) =>
					prev?.map((x) => {
						if (x.id === placement.id) return placement;
						return x;
					}) ?? null
			);
		}
	};

	const layoutSubItems: MainMenuListItemSubItem[] = [
		{ primary: 'Create Placement', onClick: handleOnCreatePlacementClicked, claimFlag: UserClaimFlag.VideoWrite },
	];

	return (
		<Layout title="Placements" subItems={layoutSubItems}>
			<Loader
				isLoading={placementsState.loading}
				loaderComponent={
					<ItemCardsSkeleton elementCount={DEFAULT_SKELETON_ITEMS_COUNT} rectHeight={146} textWidth={64} />
				}
			>
				<Grid container={true} className={classes.root} spacing={2}>
					{placements.map((x) => (
						<Grid item={true} xs={12} sm={6} md={4} lg={3} xl={2} key={x.id}>
							<PlacementCardItem placement={x} onPlacementEditClicked={handlePlacementEditClicked} />
						</Grid>
					))}
				</Grid>
			</Loader>
			<EditPlacementModal
				isOpen={isEditModalOpen}
				onClose={handleClosePlacementModal}
				onSave={handleSavedPlacement}
				placement={editingPlacement}
			/>
		</Layout>
	);
};

export default PlacementsView;
