import { AxiosPromise, AxiosRequestConfig, CancelToken } from 'axios';
import Video from 'Models/Videos/Video';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';
import { PatchVideoRequest } from 'Utils/Http/Requests/Videos/PatchVideoRequest';

export type PostVideoRequest = PatchVideoRequest;

export type onVideoUploadProgress = (progressEvent: ProgressEvent) => void;

export const POST_VIDEO_REQUEST_CANCEL_MESSAGE = 'PostVideoRequestCancelled';

const postVideoRequest = (
	token: string | null,
	data: PostVideoRequest,
	videoFile: File,
	onUploadProgress: onVideoUploadProgress,
	cancelToken: CancelToken
): AxiosPromise<Video> => {
	const url = getApiUrl(ApiUrl.Videos);

	const formData = constructFormData(data, videoFile);
	const config: AxiosRequestConfig = { onUploadProgress, cancelToken, timeout: 0 };

	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.post<Video>(url, formData, config);
};

const constructFormData = (data: PostVideoRequest, videoFile: File): FormData => {
	const formData = new FormData();
	if (data.name !== undefined) formData.append('name', data.name);
	if (data.folderId !== null && data.folderId !== undefined) formData.append('folderId', data.folderId.toString());

	formData.append('file', videoFile);
	return formData;
};

export default postVideoRequest;
