import { TextField } from '@material-ui/core';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { getTimestampFromJiraStyleTime, validateJiraStyleTime } from 'Utils/DateHelper';

interface JiraTimeTextFieldProps {
	label: string;
	onChange: (value: number | null) => void;
	fullWidth?: boolean;
	onValidation?: (isValid: boolean) => void;
}

const JiraTimeTextField = (props: JiraTimeTextFieldProps): ReactElement => {
	const [inputValue, setInputValue] = useState('');
	const [isValueValid, setIsValueValid] = useState(inputValue.length === 0 || validateJiraStyleTime(inputValue));

	useEffect(() => {
		if (props.onValidation !== undefined) props.onValidation(isValueValid);
	}, [isValueValid, props]);

	useEffect(() => {
		const timestamp = getTimestampFromJiraStyleTime(inputValue);
		props.onChange(timestamp);
	}, [inputValue, props]);

	const handleValueChanged = (event: ChangeEvent<HTMLInputElement>): void => {
		setInputValue(event.target.value);
	};

	const handleBlurred = (): void => {
		const isValid = inputValue.length === 0 || validateJiraStyleTime(inputValue);
		setIsValueValid(isValid);
	};

	const handleFocused = (): void => {
		setIsValueValid(true);
	};

	return (
		<TextField
			variant="standard"
			label={props.label}
			placeholder="1h 30m 22s"
			fullWidth={props.fullWidth}
			value={inputValue}
			onChange={handleValueChanged}
			helperText="Format: 1h 30m 22s"
			error={!isValueValid}
			onBlur={handleBlurred}
			onFocus={handleFocused}
		/>
	);
};

export default JiraTimeTextField;
