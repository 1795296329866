import axios, { CancelTokenSource } from 'axios';
import { AppAction } from 'Redux/Actions';
import {
	AddVideosToUploadAction,
	CancelVideoUploadAction,
	CompleteVideoUploadAction,
	SelectFolderAction,
	SelectVideoAction,
	StartUploadingVideoAction,
	UpdateVideoUploadProgressAction,
	VideoActionType,
} from 'Redux/Actions/Video';
import { getSelectedVideoId } from 'Utils/LocalStorageHelper';
import { createNewGuid } from 'Utils/uuid';

export enum VideoFileToUploadStatus {
	Queued,
	Uploading,
	Completed,
	Canceled,
	Failed,
}

export interface VideoFileToUpload {
	id: string;
	folderId: number | null;
	videoFile: File;
	progress: number;
	status: VideoFileToUploadStatus;
	cancelTokenSource: CancelTokenSource;
}

export interface VideoState {
	selectedFolderId: number | null;
	videoFilesToUpload: VideoFileToUpload[];
	selectedVideoId: number | null;
}

const initialState: VideoState = {
	selectedFolderId: null,
	videoFilesToUpload: [],
	selectedVideoId: getSelectedVideoId(),
};

const selectFolder = (state: VideoState, { selectedFolderId }: SelectFolderAction): VideoState => ({
	...state,
	selectedFolderId,
});

const addVideosToUpload = (state: VideoState, { videoFiles }: AddVideosToUploadAction): VideoState => {
	const newVideoFilesToUpload = videoFiles.map<VideoFileToUpload>((x) => ({
		id: createNewGuid(),
		folderId: state.selectedFolderId,
		videoFile: x,
		progress: 0,
		status: VideoFileToUploadStatus.Queued,
		cancelTokenSource: axios.CancelToken.source(),
	}));
	return {
		...state,
		videoFilesToUpload: [...state.videoFilesToUpload, ...newVideoFilesToUpload],
	};
};

const startUploadingVideo = (state: VideoState, { id }: StartUploadingVideoAction): VideoState => ({
	...state,
	videoFilesToUpload: state.videoFilesToUpload.map((x) => {
		if (x.id !== id) return x;

		return {
			id: x.id,
			status: VideoFileToUploadStatus.Uploading,
			videoFile: x.videoFile,
			folderId: x.folderId,
			progress: x.progress,
			cancelTokenSource: x.cancelTokenSource,
		};
	}),
});

const completeVideoUpload = (state: VideoState, { id, successful }: CompleteVideoUploadAction): VideoState => ({
	...state,
	videoFilesToUpload: state.videoFilesToUpload.map((x) => {
		if (x.id !== id) return x;

		return {
			id: x.id,
			status: successful ? VideoFileToUploadStatus.Completed : VideoFileToUploadStatus.Failed,
			videoFile: x.videoFile,
			folderId: x.folderId,
			progress: x.progress,
			cancelTokenSource: x.cancelTokenSource,
		};
	}),
});

const updateVideoUploadProgress = (
	state: VideoState,
	{ id, progress }: UpdateVideoUploadProgressAction
): VideoState => ({
	...state,
	videoFilesToUpload: state.videoFilesToUpload.map((x) => {
		if (x.id !== id) return x;

		return {
			id: x.id,
			status: x.status,
			videoFile: x.videoFile,
			folderId: x.folderId,
			progress,
			cancelTokenSource: x.cancelTokenSource,
		};
	}),
});

const cancelVideoUpload = (state: VideoState, { id }: CancelVideoUploadAction): VideoState => {
	return {
		...state,
		videoFilesToUpload: state.videoFilesToUpload.map((x) => {
			if (x.id !== id) return x;

			return {
				id: x.id,
				status: VideoFileToUploadStatus.Canceled,
				videoFile: x.videoFile,
				folderId: x.folderId,
				progress: x.progress,
				cancelTokenSource: x.cancelTokenSource,
			};
		}),
	};
};

const selectVideo = (state: VideoState, { id }: SelectVideoAction): VideoState => ({
	...state,
	selectedVideoId: id,
});

const video = (state: VideoState = initialState, action: AppAction): VideoState => {
	switch (action.type) {
		case VideoActionType.SelectFolder:
			return selectFolder(state, action);

		case VideoActionType.AddVideosToUpload:
			return addVideosToUpload(state, action);

		case VideoActionType.StartUploadingVideo:
			return startUploadingVideo(state, action);

		case VideoActionType.CompleteVideoUpload:
			return completeVideoUpload(state, action);

		case VideoActionType.UpdateVideoUploadProgress:
			return updateVideoUploadProgress(state, action);

		case VideoActionType.CancelVideoUpload:
			return cancelVideoUpload(state, action);

		case VideoActionType.SelectVideo:
			return selectVideo(state, action);

		default:
			return state;
	}
};

export default video;
