import AppSnackbarMessage from 'Components/App/AppSnackbarMessage';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import { ReactElement } from 'react';

const AppSnackbarMessageQueue = (): ReactElement | null => {
	const messages = useAppSelector((state) => state.messages);

	const messageToDisplay = messages.length > 0 ? messages[0] : null;

	if (messageToDisplay === null) return null;

	return <AppSnackbarMessage snackbarMessage={messageToDisplay} />;
};

export default AppSnackbarMessageQueue;
