import { Checkbox, FormControlLabel, Switch, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { ChangeEvent, ReactElement } from 'react';

export interface CheckboxSelectorData {
	id: number;
	name: string;
	selected: boolean;
}

type OnCheckboxSelected = (id: number, checked: boolean) => void;

interface CheckboxSelectorProps {
	data: CheckboxSelectorData[];
	onCheckboxSelected: OnCheckboxSelected;
	selectAll?: (on: boolean) => void;
	useSwitches?: true;
	/** Set invert prop so that the selection boxes will be inverted. Eg if row is selected than an empty box will be shown. */
	invert?: true;
}

const useStyles = makeStyles((styles) => ({
	buttonsContainer: {
		margin: styles.spacing(2),
	},
}));

const CheckboxSelectorElement = (props: {
	data: CheckboxSelectorData;
	useSwitches?: true;
	onCheckboxSelected: OnCheckboxSelected;
	invert?: true;
}): ReactElement => {
	const isSelected = props.invert ? !props.data.selected : props.data.selected;

	const handleSwitchChange = ({ target }: ChangeEvent<HTMLInputElement>): void => {
		const checked = props.invert ? !target.checked : target.checked;
		props.onCheckboxSelected(props.data.id, checked);
	};
	if (props.useSwitches) return <Switch checked={isSelected} onChange={handleSwitchChange} />;
	return <Checkbox checked={isSelected} onChange={handleSwitchChange} />;
};

const CheckboxSelector = (props: CheckboxSelectorProps): ReactElement => {
	const classes = useStyles();

	const selectedData = props.data.filter((x) => x.selected);
	const areAllSelected = props.invert ? selectedData.length === 0 : selectedData.length === props.data.length;

	const handleAllButtonClicked = (): void => {
		if (props.selectAll !== undefined) props.selectAll(props.invert ? areAllSelected : !areAllSelected);
	};

	return (
		<>
			{props.selectAll && (
				<div className={classes.buttonsContainer}>
					<Button variant="text" onClick={handleAllButtonClicked} color="primary">
						{areAllSelected ? 'Deselect all' : 'Select all'}
					</Button>
				</div>
			)}
			<div>
				<Grid container={true}>
					{props.data.map((x) => (
						<Grid item={true} key={x.id} xs={12} md={6}>
							<Tooltip title={x.name} placement="bottom">
								<FormControlLabel
									control={
										<CheckboxSelectorElement
											data={x}
											onCheckboxSelected={props.onCheckboxSelected}
											useSwitches={props.useSwitches}
											invert={props.invert}
										/>
									}
									label={
										<Typography noWrap={true} display="block">
											{x.name}
										</Typography>
									}
								/>
							</Tooltip>
						</Grid>
					))}
				</Grid>
			</div>
		</>
	);
};

export default CheckboxSelector;
