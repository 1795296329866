import axios, { AxiosInstance } from 'axios';

export const getAxiosInstance = (token: string | null): AxiosInstance => {
	const headers = { ...getAuthorizationHeader(token) };
	return axios.create({ headers });
};

const getAuthorizationHeader = (token: string | null): { Authorization?: string } => {
	if (!!token) {
		return { Authorization: token };
	}
	return {};
};
