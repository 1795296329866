import { DataGrid, GridCellParams, GridColDef, GridRowId } from '@material-ui/data-grid';
import CancelIconButton from 'Components/Common/Buttons/CancelIconButton';
import TitledBox from 'Components/Common/TitledBox';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import useVideoDispatch from 'Hooks/Redux/useVideoDispatch';
import VideoFolder from 'Models/Videos/VideoFolder';
import { ReactElement } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import { VideoFileToUpload, VideoFileToUploadStatus } from 'Redux/Reducers/Video';
import { POST_VIDEO_REQUEST_CANCEL_MESSAGE } from 'Utils/Http/Requests/Videos/PostVideoRequest';

interface VideoUploadsTableProps {
	folders: VideoFolder[];
	foldersLoading: boolean;
}

const VideoUploadsTable = (props: VideoUploadsTableProps): ReactElement => {
	const videoFilesToUpload = useAppSelector<VideoFileToUpload[]>((state) => state.video.videoFilesToUpload);
	const { dispatchCancelVideoUpload } = useVideoDispatch();
	const { dispatchSetMessageAction } = useMessagesDispatch();

	const handleCancelClick = (id: GridRowId): void => {
		// Id should always be string
		if (typeof id !== 'string') return;

		const videoFile = videoFilesToUpload.find((x) => x.id === id);
		if (videoFile) {
			videoFile.cancelTokenSource.cancel(POST_VIDEO_REQUEST_CANCEL_MESSAGE);
			dispatchSetMessageAction(`Uploading of video ${videoFile.videoFile.name} was cancelled`, MessageStatusLevel.Info);
		}
		dispatchCancelVideoUpload(id);
	};

	const rows = videoFilesToUpload.map((x) => ({
		id: x.id,
		name: x.videoFile.name,
		folder: props.folders.find((f) => f.id === x.folderId)?.name ?? '/',
		progress: `${x.progress}%`,
		status: VideoFileToUploadStatus[x.status],
	}));

	const renderCancelCell = (params: GridCellParams): JSX.Element => {
		const disabled =
			params.row.status !== VideoFileToUploadStatus[VideoFileToUploadStatus.Queued] &&
			params.row.status !== VideoFileToUploadStatus[VideoFileToUploadStatus.Uploading];
		console.log(params.row.status, disabled);
		return <CancelIconButton onClick={() => handleCancelClick(params.id)} disabled={disabled} />;
	};

	const columns: GridColDef[] = [
		{ field: 'name', headerName: 'Name', width: 250 },
		{ field: 'folder', headerName: 'Folder', width: 250 },
		{ field: 'status', headerName: 'Status', width: 150 },
		{ field: 'progress', headerName: 'Progress', width: 110, align: 'center', headerAlign: 'center' },
		{
			field: 'cancel',
			headerName: 'Cancel',
			width: 100,
			align: 'center',
			headerAlign: 'center',
			renderCell: renderCancelCell,
		},
	];

	return (
		<TitledBox title="Video Uploads" border={1}>
			<DataGrid
				autoHeight={true}
				rows={rows}
				columns={columns}
				disableSelectionOnClick={true}
				loading={props.foldersLoading}
			/>
		</TitledBox>
	);
};

export default VideoUploadsTable;
