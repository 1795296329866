import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useAppLoadingModalStyles = makeStyles((theme: Theme) =>
	createStyles({
		overlay: {
			position: 'fixed',
			padding: 0,
			margin: 0,
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			background: 'rgba(255,255,255,0.8)',
			zIndex: theme.zIndex.tooltip + 10, // Tooltip ima najvecji zindex.
		},
		middle: {
			position: 'fixed',
			width: '20%',
			height: '20%',
			top: '50%',
			left: '50%',
			transform: `translate(-50%, -80%)`,
			textAlign: 'center',
		},
		progress: {
			margin: theme.spacing(2),
		},
	})
);

export default useAppLoadingModalStyles;
