import downloadBlob from 'Utils/DownloadBlob';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';
import { PostCreateAndExportDefaultDetectionsRequest } from 'Utils/Http/Requests/Videos/Detections/Export/PostCreateAndExportDefaultDetectionsRequest';

const postCreateAndExportVisuaDetectionsRequest: (
	token: string | null,
	request: PostCreateAndExportDefaultDetectionsRequest,
	filename: string
) => Promise<void> = async (token, request, filename) => {
	const url = getApiUrl(ApiUrl.VideosDetectionsExportVisua);
	const axiosInstance = getAxiosInstance(token);
	const response = await axiosInstance.post<Blob>(url, request, { responseType: 'blob' });
	downloadBlob(response.data, { filename });
};

export default postCreateAndExportVisuaDetectionsRequest;
