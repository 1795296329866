import { colors } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ActionCardImageContent from 'Components/Common/Cards/ActionCardImageContent';
import SelectableActionCard from 'Components/Common/Cards/SelectableActionCard';
import ColorDot from 'Components/Common/ColorDot';
import { BrandStatus } from 'Models/Brands/Brand';
import BrandGroup from 'Models/Brands/BrandGroup';
import { UserClaimFlag } from 'Models/User';
import { ReactElement, useMemo } from 'react';

interface BrandGroupCardProps {
	brandGroup: BrandGroup;
	onCardClick: (id: number) => void;
	isSelected: boolean;
	onCardSelected: (id: number, checked: boolean) => void;
}

const BrandGroupCard = ({ brandGroup, onCardClick, isSelected, onCardSelected }: BrandGroupCardProps): ReactElement => {
	const imageUrl = brandGroup.brands.find((x) => x.iconUrl !== null)?.iconUrl ?? null;

	const brandCount = brandGroup.brands.length;
	const activeBrandCount = useMemo(() => brandGroup.brands.filter((x) => x.status === BrandStatus.Activated).length, [
		brandGroup.brands,
	]);
	const areAllActive = activeBrandCount === brandCount;
	const noneActive = activeBrandCount === 0;

	const getColorDot = (): string => {
		if (areAllActive) return colors.green[600];
		if (noneActive) return colors.red[600];
		return colors.blue[600];
	};
	const colorDotColor = getColorDot();

	const handleCardClick = (): void => {
		onCardClick(brandGroup.id);
	};

	const handleSelectedChanged = (checked: boolean): void => {
		onCardSelected(brandGroup.id, checked);
	};

	return (
		<SelectableActionCard
			isSelected={isSelected}
			onSelectionChanged={handleSelectedChanged}
			onClick={handleCardClick}
			claimFlag={UserClaimFlag.BrandWrite}
			title={brandGroup.name}
		>
			<ActionCardImageContent imageUrl={imageUrl} />

			<Typography variant="h5" component="h2" noWrap>
				<ColorDot color={colorDotColor} />
				{brandCount > 1 && `(${activeBrandCount}/${brandCount}) - `}
				{brandGroup.name}
			</Typography>
		</SelectableActionCard>
	);
};

export default BrandGroupCard;
