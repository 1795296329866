import Typography from '@material-ui/core/Typography';
import ConfirmModal from 'Components/Common/Modals/ConfirmModal';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import Video from 'Models/Videos/Video';
import { ReactElement } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import deleteVideoRequest from 'Utils/Http/Requests/Videos/DeleteVideoRequest';

interface DeleteVideoModalProps {
	isOpen: boolean;
	onClose: () => void;
	video: Video;
	onVideoDeleted: (id: number) => void;
}

const DeleteVideoModal = ({ onVideoDeleted, video, isOpen, onClose }: DeleteVideoModalProps): ReactElement => {
	const token = useAppSelector<string | null>((state) => state.profile.token);
	const { dispatchStopLoading, dispatchStartLoading } = useLoadingDispatch();
	const { dispatchSetAxiosErrorMessage, dispatchSetMessageAction } = useMessagesDispatch();

	const handleConfirmClicked: () => void = async () => {
		dispatchStartLoading();
		try {
			await deleteVideoRequest(token, video.id);
			dispatchSetMessageAction(`Deleted video "${video.name}" and it's detections.`, MessageStatusLevel.Success);
			onVideoDeleted(video.id);
			onClose();
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		} finally {
			dispatchStopLoading();
		}
	};

	return (
		<ConfirmModal
			onConfirmClicked={handleConfirmClicked}
			title={`Delete video ${video.name}`}
			open={isOpen}
			onClose={onClose}
			confirmButtonText="Delete"
			closeButtonText="Cancel"
		>
			<Typography variant="body1">
				Are you sure you want to delete video{' '}
				<b>
					{video.name} [{video.hash ?? video.id}]
				</b>
				? This action will also delete all detections.
			</Typography>
		</ConfirmModal>
	);
};

export default DeleteVideoModal;
