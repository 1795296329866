import { ListItem, TextField, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { Autocomplete } from '@material-ui/lab';
import SaveModal from 'Components/Common/Modals/SaveModal';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import Project from 'Models/Project';
import Video from 'Models/Videos/Video';
import { ChangeEvent, ReactElement, useState } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import putVideoProjects from 'Utils/Http/Requests/Videos/Projects/PutVideoProjects';

interface SetProjectModalProps {
	projects: Project[];
	selectedVideoIds: number[];
	isOpen: boolean;
	onProjectSet: (videos: Video[]) => void;
	onClose: () => void;
}

const SetProjectModal = (props: SetProjectModalProps): ReactElement => {
	const [projectId, setProjectId] = useState<number | null>(null);

	const token = useAppSelector((state) => state.profile.token);
	const { dispatchSetAxiosErrorMessage, dispatchSetMessageAction } = useMessagesDispatch();
	const { dispatchStartLoading, dispatchStopLoading } = useLoadingDispatch();

	const selectedProject = props.projects.find((x) => x.id === projectId) ?? null;

	const handleModalSaveClicked: () => void = async () => {
		dispatchStartLoading();
		try {
			const response = await putVideoProjects(token, props.selectedVideoIds, projectId);
			props.onProjectSet(response.data);
			dispatchSetMessageAction(`Set select project to ${response.data.length} videos.`, MessageStatusLevel.Success);
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		} finally {
			dispatchStopLoading();
		}
	};

	const handleProjectIdSelectChanged = (event: ChangeEvent<{}>, value: Project | null): void => {
		setProjectId(value?.id ?? null);
	};

	return (
		<SaveModal onSave={handleModalSaveClicked} open={props.isOpen} onClose={props.onClose}>
			<Typography variant="h5">Set project to selected videos</Typography>
			<Divider />
			<List>
				<ListItem>
					<Autocomplete
						renderInput={(params) => <TextField {...params} label="Project" fullWidth={true} />}
						options={props.projects}
						fullWidth={true}
						getOptionLabel={(option) => option.name}
						autoHighlight={true}
						getOptionSelected={(option) => option.id === projectId}
						onChange={handleProjectIdSelectChanged}
						value={selectedProject}
					/>
				</ListItem>
			</List>
		</SaveModal>
	);
};

export default SetProjectModal;
