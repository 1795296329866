import { AxiosPromise } from 'axios';
import Video from 'Models/Videos/Video';
import VideoFolder from 'Models/Videos/VideoFolder';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export interface GetFolderContentResponse {
	videos: Video[];
	folders: VideoFolder[];
	id: number | null;
	name: string;
	parentFolders: VideoFolder[];
}

const getFolderContentRequest = (token: string | null, folderId: number): AxiosPromise<GetFolderContentResponse> => {
	const url = getApiUrl(ApiUrl.FolderContent).replace(':folderId', folderId.toString());
	const axios = getAxiosInstance(token);
	return axios.get<GetFolderContentResponse>(url);
};

export default getFolderContentRequest;
