import { Card, CardActions, Checkbox, ListItem, ListItemText } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EditIcon from '@material-ui/icons/Edit';
import VideoDetectionPlacement from 'Models/Videos/VideoDetectionPlacement';
import { ReactElement } from 'react';

interface PlacementCardItemProps {
	placement: VideoDetectionPlacement;
	onPlacementEditClicked: (placementId: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	colorContainer: {
		width: '100%',
		height: '30px',
	},
	dataList: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	deleteButton: {
		color: theme.palette.error.dark,
	},
	manualCheckbox: {
		padding: theme.spacing(1),
	},
}));

const PlacementCardItem = (props: PlacementCardItemProps): ReactElement => {
	const isManualPlacement = props.placement.visuaId === null;

	const classes = useStyles();

	const handleEditButtonClicked = (): void => {
		props.onPlacementEditClicked(props.placement.id);
	};

	return (
		<Card>
			<div className={classes.colorContainer} style={{ backgroundColor: props.placement.color }} />
			<Divider />
			<CardContent>
				<List className={classes.dataList}>
					<ListItem alignItems="flex-start">
						<ListItemText
							primary="Name:"
							secondary={props.placement.name}
							secondaryTypographyProps={{ noWrap: true, variant: 'h5' }}
						/>
					</ListItem>
					<ListItem alignItems="flex-start">
						<ListItemText primary="Color:" secondary={props.placement.color} />
					</ListItem>
					<ListItem alignItems="flex-start">
						<ListItemText primary="Manual:" />
						<Checkbox edge="end" checked={isManualPlacement} disabled={true} className={classes.manualCheckbox} />
					</ListItem>
				</List>
			</CardContent>
			<CardActions disableSpacing>
				<IconButton onClick={handleEditButtonClicked}>
					<EditIcon />
				</IconButton>
			</CardActions>
		</Card>
	);
};

export default PlacementCardItem;
