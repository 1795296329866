import { InputBase } from '@material-ui/core';
import classNames from 'classnames';
import useSearchBarClasses from 'Hooks/Styles/Common/useSearchBarClasses';
import SearchIcon from '@material-ui/icons/Search';
import { ReactElement } from 'react';

interface SearchBarProps {
	onSearchChanged: (value: string) => void;
	withoutBottomMargin?: boolean;
}

const SearchBar = ({ onSearchChanged, withoutBottomMargin }: SearchBarProps): ReactElement => {
	const classes = useSearchBarClasses();

	const searchWrapperClasses = classNames({
		[classes.search]: true,
		[classes.searchWithoutBottomMargin]: withoutBottomMargin,
	});
	return (
		<div className={searchWrapperClasses}>
			<div className={classes.searchIcon}>
				<SearchIcon />
			</div>
			<InputBase
				placeholder="Search…"
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput,
				}}
				onChange={(e) => onSearchChanged(e.target.value)}
				fullWidth
			/>
		</div>
	);
};

export default SearchBar;
