import VideoDetectionPlacement from 'Models/Videos/VideoDetectionPlacement';
import { useMemo } from 'react';
import * as React from 'react';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';

interface SelectMultiplePlacementsProps {
	id: string;
	selectedPlacementIds: number[];
	onChange: (selectedPlacementIds: number[]) => void;
	placements: VideoDetectionPlacement[];
}

const SelectMultiplePlacements: React.FC<SelectMultiplePlacementsProps> = ({
	id,
	placements,
	selectedPlacementIds,
	onChange,
}) => {
	const handlePlacementsSelected = ({ target }: React.ChangeEvent<{ value: unknown }>): void => {
		if (Array.isArray(target.value)) {
			const values = target.value.map((v) => Number(v)).filter((v) => !isNaN(v));
			onChange(values);
		}
	};

	const sortedPlacements = useMemo(() => placements.sort((a, b) => a.name.localeCompare(b.name)), [placements]);

	return (
		<FormControl fullWidth={true} margin="normal">
			<InputLabel id={`${id}-label`}>Placements</InputLabel>
			<Select
				labelId={`${id}-label`}
				id={id}
				value={selectedPlacementIds}
				onChange={handlePlacementsSelected}
				multiple={true}
				fullWidth={true}
				renderValue={(selected) => {
					const placementNames = (selected as number[]).map((s) => {
						const selectedPlacement = placements.find((p) => p.id === s);
						return selectedPlacement && selectedPlacement.name;
					});
					return placementNames.join(', ');
				}}
			>
				{sortedPlacements.map((p) => (
					<MenuItem value={p.id} key={p.id}>
						<Checkbox checked={!!selectedPlacementIds && selectedPlacementIds.indexOf(p.id) > -1} />
						<ListItemText primary={p.name} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SelectMultiplePlacements;
