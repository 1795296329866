import { AxiosPromise } from 'axios';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export type DeleteBrandRequest = (token: string | null, brandId: number) => AxiosPromise;
const deleteBrandRequest: DeleteBrandRequest = (token, brandId) => {
	const url = getApiUrl(ApiUrl.BrandsBrand).replace(':brandId', brandId.toString());
	const axios = getAxiosInstance(token);
	return axios.delete(url);
};

export default deleteBrandRequest;
