import useAppDispatch from 'Hooks/Redux/useAppDispatch';
import { useCallback } from 'react';
import { IndexActionType } from 'Redux/Actions';

type UseIndexDispatch = () => {
	dispatchLogout: () => void;
};

const useIndexDispatch: UseIndexDispatch = () => {
	const dispatch = useAppDispatch();

	const dispatchLogout = useCallback((): void => dispatch({ type: IndexActionType.Logout }), [dispatch]);

	return {
		dispatchLogout,
	};
};

export default useIndexDispatch;
