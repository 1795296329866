import { useCallback, useEffect, useState } from 'react';

const checkInterval = 60;

const useIsVideoBuffering = (video: HTMLVideoElement | null): boolean => {
	const [isBuffering, setIsBuffering] = useState(video === null);

	const bufferingCheck = useCallback((): void => {
		if (video === null) return;

		const isVideoBuffering = video.networkState === video.NETWORK_LOADING && video.readyState < video.HAVE_FUTURE_DATA;
		setIsBuffering(isVideoBuffering);
	}, [video]);

	useEffect(() => {
		const interval = setInterval(bufferingCheck, checkInterval);
		return () => clearInterval(interval);
	}, [bufferingCheck]);

	return isBuffering;
};

export default useIsVideoBuffering;
