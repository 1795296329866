import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import NewDetectionDrawerCanvas from 'Components/Editor/VideoPlayer/NewDetectionDrawerCanvas';
import PlayerControls from 'Components/Editor/VideoPlayer/PlayerControls/PlayerControls';
import VideoDetections from 'Components/Editor/VideoPlayer/VideoDetections';
import VideoProgressBar from 'Components/Editor/VideoPlayer/VideoProgressBar';
import VideoTimecode from 'Components/Editor/VideoPlayer/VideoTimecode';
import { UseVideoPlayerInstance } from 'Hooks/Editor/useVideoPlayerInstance';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

interface VideoPlayerProps {
	instance: UseVideoPlayerInstance;
}

const useClasses = makeStyles({
	wrapper: {
		position: 'relative',
	},
	videoWrapper: {
		position: 'absolute',
		zIndex: 10,
	},
	detectionsWrapper: {
		position: 'absolute',
		zIndex: 20,
	},
	videoRoot: {
		flex: 1,
	},
});

const VideoPlayer = ({ instance }: VideoPlayerProps): ReactElement | null => {
	const [wrapperNode, setWrapperNode] = useState<HTMLDivElement | null>(null);
	const [videoSize, setVideoSize] = useState<[number, number]>([0, 0]);
	const [videoScale, setVideoScale] = useState(1);

	const { videoNode, setVideoNode, video } = instance;

	const updateSize = useCallback(() => {
		const maxWidth = videoNode?.videoWidth ?? 0;
		const wrapperWidth = wrapperNode?.offsetWidth ?? 0;
		const width = Math.min(maxWidth, wrapperWidth);

		if (width <= 0) return;

		const scale = width / maxWidth;
		const height = Math.round(scale * (videoNode?.videoHeight ?? 1));

		setVideoSize([width, height]);
		setVideoScale(scale);
	}, [videoNode?.videoHeight, videoNode?.videoWidth, wrapperNode?.offsetWidth]);

	const resizeObserver = useMemo(() => new ResizeObserver(() => updateSize()), [updateSize]);

	const classes = useClasses();

	useEffect(
		// The effect handles the setting of the size of the and the scale of the video.
		() => {
			// Update as soon as selected video changes!
			updateSize();

			if (resizeObserver === undefined || !wrapperNode) window.addEventListener('resize', updateSize);
			else resizeObserver.observe(wrapperNode);
			return () => {
				if (resizeObserver === undefined || !wrapperNode) window.removeEventListener('resize', updateSize);
				else resizeObserver.unobserve(wrapperNode);
			};
		},
		[resizeObserver, updateSize, wrapperNode]
	);

	if (video === null) {
		return null;
	}

	return (
		<Grid container={true} spacing={1}>
			<Grid item={true}>
				<PlayerControls instance={instance} />
			</Grid>
			<Grid item={true} className={classes.videoRoot}>
				<div className={classes.wrapper} ref={setWrapperNode} style={{ height: `${videoSize[1]}px` }}>
					<div
						className={classes.detectionsWrapper}
						style={{ height: `${videoSize[1]}px`, width: `${videoSize[0]}px` }}
					>
						<VideoDetections width={videoSize[0]} height={videoSize[1]} scale={videoScale} instance={instance} />
					</div>
					<div className={classes.videoWrapper}>
						<video
							preload="auto"
							width={videoSize[0] > 0 ? videoSize[0] : undefined}
							height={videoSize[1] > 0 ? videoSize[1] : undefined}
							ref={setVideoNode}
						>
							<source src={getApiUrl(ApiUrl.VideosVideoMedia).replace(':videoGuid', video.guid)} type="video/mp4" />
						</video>
					</div>
					<NewDetectionDrawerCanvas scale={videoScale} instance={instance} />
				</div>
				<VideoProgressBar
					width={videoSize[0]}
					videoNode={videoNode}
					retrievedTimeSpans={instance.retrievedTimeSpans}
					selectedVideo={instance.video}
				/>
				<VideoTimecode selectedVideo={instance.video} video={videoNode} />
			</Grid>
		</Grid>
	);
};

export default VideoPlayer;
