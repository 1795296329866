import { AxiosPromise } from 'axios';
import EditorVideoNewDetection from 'Models/Videos/Detections/EditorVideoNewDetection';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

type PostNewDetectionRequest = EditorVideoNewDetection;

const postNewDetectionRequest = (
	token: string | null,
	request: PostNewDetectionRequest,
	videoId: number
): AxiosPromise => {
	const url = getApiUrl(ApiUrl.VideosVideoDetectionsNew).replace(':videoId', videoId.toString());
	const axios = getAxiosInstance(token);
	return axios.post(url, request);
};

export default postNewDetectionRequest;
