import { createStyles, fade, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useSearchBarClasses = makeStyles((theme: Theme) =>
	createStyles({
		search: {
			position: 'relative',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: fade(theme.palette.primary.main, 0.15),
			'&:hover': {
				backgroundColor: fade(theme.palette.primary.main, 0.25),
			},
			marginRight: theme.spacing(2),
			marginLeft: 0,
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(3),
				width: 'auto',
			},
			marginBottom: theme.spacing(3),
		},
		searchWithoutBottomMargin: {
			marginBottom: 0,
		},
		searchIcon: {
			width: theme.spacing(9),
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		inputRoot: {
			color: 'inherit',
			width: '100%',
		},
		inputInput: {
			paddingTop: theme.spacing(1),
			paddingRight: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(10),
			transition: theme.transitions.create('width'),
			width: '100%',
			[theme.breakpoints.up('md')]: {
				width: 200,
			},
		},
	})
);

export default useSearchBarClasses;
