import { Action } from 'redux';

export enum MessageStatusLevel {
	Success,
	Info,
	Warning,
	Error,
}

export enum MessageActionType {
	SetMessage = 'SET_MESSAGE',
	CloseMessage = 'CLOSE_MESSAGE',
	ClearMessage = 'CLEAR_MESSAGE',
}

export interface SetMessageAction extends Action<MessageActionType.SetMessage> {
	message: string;
	level: MessageStatusLevel;
	duration: number;
}

export interface CloseMessageAction extends Action<MessageActionType.CloseMessage> {
	guid: string;
}

export interface ClearMessageAction extends Action<MessageActionType.ClearMessage> {
	guid: string;
}

export type MessageAction = SetMessageAction | CloseMessageAction | ClearMessageAction;
