import CheckboxSelector, { CheckboxSelectorData } from 'Components/Common/CheckboxSelector';
import Brand from 'Models/Brands/Brand';
import VideoDetectionPlacement from 'Models/Videos/VideoDetectionPlacement';
import { ReactElement } from 'react';

interface FilterDialogContentProps {
	data: (Brand | VideoDetectionPlacement)[];
	filteredDataIds: number[];
	onSelectionChanged: (id: number, selected: boolean) => void;
	onAllSelected: (checked: boolean) => void;
}

const FilterDialogContent = (props: FilterDialogContentProps): ReactElement => {
	const data: CheckboxSelectorData[] = props.data.map((x) => ({
		name: x.name,
		id: x.id,
		selected: props.filteredDataIds.includes(x.id),
	}));

	const handleSwitchChange = (id: number, checked: boolean): void => {
		props.onSelectionChanged(id, checked);
	};

	const handleSelectAll = (on: boolean): void => {
		props.onAllSelected(on);
	};

	return (
		<CheckboxSelector
			data={data}
			onCheckboxSelected={handleSwitchChange}
			selectAll={handleSelectAll}
			useSwitches={true}
			invert={true}
		/>
	);
};

export default FilterDialogContent;
