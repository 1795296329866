import { FormControlLabel, Switch } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ConditionalDisplay from 'Components/Common/ConditionalDisplay';
import * as React from 'react';
import { ReactElement, ReactNode, useState } from 'react';

export interface SwitchConditionalDisplayProps {
	children: ReactNode;
	name: string;
	onCheckedChanged: (checked: boolean) => void;
}

const SwitchConditionalDisplay = (props: SwitchConditionalDisplayProps): ReactElement => {
	const [isFilterEnabled, setIsFilterEnabled] = useState(false);

	const handleFilterEnableSwitchChanged = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
		props.onCheckedChanged(checked);
		setIsFilterEnabled(checked);
	};

	return (
		<div>
			<FormControlLabel
				control={<Switch checked={isFilterEnabled} onChange={handleFilterEnableSwitchChanged} />}
				label={
					<Typography noWrap={true} display="block">
						{props.name}
					</Typography>
				}
			/>
			<ConditionalDisplay displayContent={isFilterEnabled}>{props.children}</ConditionalDisplay>
		</div>
	);
};

export default SwitchConditionalDisplay;
