import { Action } from 'redux';
import { LoadingAction } from 'Redux/Actions/Loading';
import { MessageAction } from 'Redux/Actions/Messages';
import { ProfileAction } from 'Redux/Actions/Profile';
import { VideoAction } from 'Redux/Actions/Video';

export enum IndexActionType {
	Logout = 'INDEX_LOGOUT',
}

export type LogoutAction = Action<IndexActionType.Logout>;

export type AppAction = ProfileAction | LogoutAction | LoadingAction | MessageAction | VideoAction;
