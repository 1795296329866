import { createStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import classNames from 'classnames';
import { ReactElement } from 'react';

interface DeleteButtonProps {
	onClick: () => void;
	className?: string;
}

const useClasses = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			color: theme.palette.error.dark,
		},
	})
);

const DeleteIconButton = ({ onClick, className }: DeleteButtonProps): ReactElement => {
	const classes = useClasses();

	const buttonClasses = classNames([classes.root, className]);

	return (
		<IconButton onClick={onClick} className={buttonClasses}>
			<DeleteIcon />
		</IconButton>
	);
};

export default DeleteIconButton;
