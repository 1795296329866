import { Action } from 'redux';

export enum VideoActionType {
	SelectFolder = 'SELECT_FOLDER',
	AddVideosToUpload = 'ADD_VIDEOS_TO_UPLOAD',
	StartUploadingVideo = 'START_UPLOADING_VIDEO',
	CompleteVideoUpload = 'COMPLETE_VIDEO_UPLOAD',
	UpdateVideoUploadProgress = 'UPDATE_VIDEO_UPLOAD_PROGRESS',
	CancelVideoUpload = 'CANCEL_VIDEO_UPLOAD',
	SelectVideo = 'SELECT_VIDEO',
}

export interface SelectFolderAction extends Action<VideoActionType.SelectFolder> {
	selectedFolderId: number | null;
}

export interface AddVideosToUploadAction extends Action<VideoActionType.AddVideosToUpload> {
	videoFiles: File[];
}

export interface StartUploadingVideoAction extends Action<VideoActionType.StartUploadingVideo> {
	id: string;
}

export interface CompleteVideoUploadAction extends Action<VideoActionType.CompleteVideoUpload> {
	id: string;
	successful: boolean;
}

export interface UpdateVideoUploadProgressAction extends Action<VideoActionType.UpdateVideoUploadProgress> {
	id: string;
	progress: number;
}

export interface CancelVideoUploadAction extends Action<VideoActionType.CancelVideoUpload> {
	id: string;
}

export interface SelectVideoAction extends Action<VideoActionType.SelectVideo> {
	id: number;
}

export type VideoAction =
	| SelectFolderAction
	| AddVideosToUploadAction
	| StartUploadingVideoAction
	| CompleteVideoUploadAction
	| UpdateVideoUploadProgressAction
	| CancelVideoUploadAction
	| SelectVideoAction;
