import { useCallback, useState } from 'react';

type ForceRerender = () => void;

/**
 * This hook provides you with function that forces a rerender. Internally it works by simply incrementing a counter state.
 */
const useForceRerender = (): ForceRerender => {
	const [, setFakeCounter] = useState(0);

	const forceRerender = useCallback(() => {
		setFakeCounter((fakeCounter) => fakeCounter + 1);
	}, []);

	return forceRerender;
};

export default useForceRerender;
