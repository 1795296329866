import Typography from '@material-ui/core/Typography';
import ConfirmModal from 'Components/Common/Modals/ConfirmModal';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import VideoFolder from 'Models/Videos/VideoFolder';
import { ReactElement } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import deleteFolderRequest from 'Utils/Http/Requests/Folder/DeleteFolderRequest';

interface DeleteVideoFolderModalProps {
	isOpen: boolean;
	onClose: () => void;
	folder: VideoFolder;
	onFolderDeleted: (id: number) => void;
}

const DeleteVideoFolderModal = ({
	isOpen,
	onClose,
	folder,
	onFolderDeleted,
}: DeleteVideoFolderModalProps): ReactElement | null => {
	const token = useAppSelector<string | null>((state) => state.profile.token);
	const { dispatchStopLoading, dispatchStartLoading } = useLoadingDispatch();
	const { dispatchSetAxiosErrorMessage, dispatchSetMessageAction } = useMessagesDispatch();

	const handleConfirmClicked: () => void = async () => {
		dispatchStartLoading();
		try {
			await deleteFolderRequest(token, folder.id);
			dispatchSetMessageAction(`Deleted folder "${folder.name}" and it's content.`, MessageStatusLevel.Success);
			onFolderDeleted(folder.id);
			onClose();
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		} finally {
			dispatchStopLoading();
		}
	};

	return (
		<ConfirmModal
			onConfirmClicked={handleConfirmClicked}
			title={`Delete folder ${folder.name}`}
			open={isOpen}
			onClose={onClose}
			confirmButtonText="Delete"
			closeButtonText="Cancel"
		>
			<Typography variant="body1">
				Are you sure you want to delete folder{' '}
				<b>
					{folder.name} [{folder.id}]
				</b>
				? This will delete all videos and and subfolders.
			</Typography>
		</ConfirmModal>
	);
};

export default DeleteVideoFolderModal;
