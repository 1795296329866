import { AxiosPromise } from 'axios';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export enum DetectFeedbackType {
	FalsePositive = 'FalsePositive',
	FalseNegative = 'FalseNegative',
	TruePositive = 'TruePositive',
}

export interface PostVideoDetectionFeedbackRequest {
	type: DetectFeedbackType;
}

const postVideoDetectionFeedbackRequest = (
	token: string | null,
	videoId: number,
	detectionId: number,
	request: PostVideoDetectionFeedbackRequest
): AxiosPromise => {
	const url = getApiUrl(ApiUrl.VideosVideoDetectionsDetectionFeedback)
		.replace(':videoId', videoId.toString())
		.replace(':detectionId', detectionId.toString());
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.post(url, request);
};

export default postVideoDetectionFeedbackRequest;
