import { AxiosPromise } from 'axios';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

const postVideoRefreshRequest = (token: string | null, videoId: number): AxiosPromise => {
	const url = getApiUrl(ApiUrl.VideosVideoRefresh).replace(':videoId', videoId.toString());
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.post(url);
};

export default postVideoRefreshRequest;
