import { useEffect } from 'react';
import { setEditorTime } from 'Utils/LocalStorageHelper';

const INTERVAL = 3000;

/**
 * This hook will update the time in storage every 3 seconds while given videoNode exists.
 * @param videoNode
 */
const useSaveEditorTime = (videoNode: HTMLVideoElement | null): void => {
	useEffect(() => {
		if (videoNode === null) return;

		let interval: NodeJS.Timeout;
		const timeout = setTimeout(() => {
			interval = setInterval(() => {
				setEditorTime(videoNode.currentTime);
			}, INTERVAL);
		}, INTERVAL);
		return () => {
			if (interval !== undefined) clearInterval(interval);
			clearTimeout(timeout);
		};
	}, [videoNode]);
};

export default useSaveEditorTime;
