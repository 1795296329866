import VideoDetectionPlacement from 'Models/Videos/VideoDetectionPlacement';
import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface EditorDetectionPlacementLabelProps {
	placement: VideoDetectionPlacement | null;
}

const EditorDetectionPlacementLabel: React.FC<EditorDetectionPlacementLabelProps> = (props) => {
	const { placement } = props;

	const classes = useStyles();

	return (
		<>
			<span className={classes.placementColorDot} style={{ backgroundColor: placement?.color ?? '#000000' }} />
			<span>{placement?.name ?? 'No data'}</span>
		</>
	);
};

const useStyles = makeStyles(({ spacing }: Theme) => ({
	placementColorDot: {
		height: '8px',
		width: '8px',
		borderRadius: '50%',
		display: 'inline-block',
		marginRight: spacing(1),
	},
}));

export default React.memo(EditorDetectionPlacementLabel);
