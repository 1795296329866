import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { ReactElement } from 'react';

interface EditIconButtonProps {
	onClick: () => void;
	className?: string;
}

const EditIconButton = ({ onClick, className }: EditIconButtonProps): ReactElement => {
	return (
		<IconButton onClick={onClick} className={className}>
			<EditIcon />
		</IconButton>
	);
};

export default EditIconButton;
