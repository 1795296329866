import useColorDotClasses from 'Hooks/Styles/Common/useColorDotClasses';
import { ReactElement } from 'react';

interface ColorDotProps {
	color: string;
}

const ColorDot = ({ color }: ColorDotProps): ReactElement => {
	const classes = useColorDotClasses({ color });
	return (
		<>
			<span className={classes.colorDotContainer}>
				<span className={classes.colorDot} />
			</span>
			<span className={classes.colorDotSpacer} />
		</>
	);
};

export default ColorDot;
