import { AxiosPromise } from 'axios';
import useDataFetcher from 'Hooks/Http/useDataFetcher';
import useValueRef from 'Hooks/useValueRef';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import getFrameDetectionRequest, {
	FrameDetectionsPerFrame,
	GetFrameDetectionsResponse,
} from 'Utils/Http/Requests/Videos/Detections/GetFrameDetectionsRequest';

export interface UseFetchFrameDetectionsOptions {
	/** The threshold in seconds where it should stop loading. Default is 5 minutes. */
	maxThreshold?: number;
	/** Minimum timespan that should be loaded in seconds. */
	minRequestTimespan?: number;
	/** The margin where time jump is not registered but instead buffers to wait for loaded data. Default is 10 seconds. */
	errorMargin?: number;
	/** How long does the pooler sleep before checking if request finished and request for new data _in seconds_. Default value is 2 seconds. */
	sleepPeriod?: number;
}

interface UseFetchFrameDetections {
	frameDetections: FrameDetectionsPerFrame;
	isBuffering: boolean;
	retrievedTimeSpans: [number, number][];
	setFrameDetections: Dispatch<SetStateAction<FrameDetectionsPerFrame>>;
	resetFetchedDetections: () => void;
	removeFrameDetections: (detectionIds: number[], fps: number) => void;
}

const DEFAULT_MAX_THRESHOLD = 5 * 60; // 10 MINUTES
const DEFAULT_MIN_REQUEST_TIMESPAN = 10; // 10 seconds should be enough and quick for first request.
const DEFAULT_ERROR_MARGIN = 10;
const DEFAULT_SLEEP_PERIOD = 2;

const useFetchFrameDetections = (
	videoId: number | null,
	videoNode: HTMLVideoElement | null,
	options?: UseFetchFrameDetectionsOptions
): UseFetchFrameDetections => {
	const maxThreshold = options?.maxThreshold ?? DEFAULT_MAX_THRESHOLD;
	const minRequestTimespan = options?.minRequestTimespan ?? DEFAULT_MIN_REQUEST_TIMESPAN;
	const errorMargin = options?.errorMargin ?? DEFAULT_ERROR_MARGIN;
	const sleepPeriod = options?.sleepPeriod ?? DEFAULT_SLEEP_PERIOD;

	const [frameDetections, setFrameDetections] = useState<FrameDetectionsPerFrame>({});
	const [retrievedTimeSpans, setRetrievedTimeSpans] = useState<[number, number][]>([]);
	const [timespanToRetrieve, setTimeSpanToRetrieve] = useState<[number, number]>([-1, -1]);
	const [isBuffering, setIsBuffering] = useState(true);
	const [retrievingFailed, setRetrievingFailed] = useState(false);

	const doNotFetch = timespanToRetrieve[0] < 0 || timespanToRetrieve[1] < 0 || retrievingFailed;
	const fetcherFunction = useCallback(
		(token: string | null): AxiosPromise<GetFrameDetectionsResponse> => {
			return getFrameDetectionRequest(token, videoId ?? 0, timespanToRetrieve[0], timespanToRetrieve[1]);
		},
		[timespanToRetrieve, videoId]
	);
	const { data, loading, error } = useDataFetcher(fetcherFunction, doNotFetch);

	const getTimespan = useCallback(
		(currentTime: number): { timespan: [number, number] | null; timespanIdx: number | null } => {
			for (let i = 0; i < retrievedTimeSpans.length; i++) {
				const t = retrievedTimeSpans[i];
				if (currentTime >= t[0] && currentTime <= t[1])
					return {
						timespan: t,
						timespanIdx: i,
					};
			}
			return {
				timespan: null,
				timespanIdx: null,
			};
		},
		[retrievedTimeSpans]
	);

	const getNextTimespan = useCallback(
		(currentTime: number): { nextTimespan: [number, number] | null; nextTimespanIdx: number | null } => {
			let timespan: [number, number] | null = null;
			let idx: number | null = null;
			for (let i = 0; i < retrievedTimeSpans.length; i++) {
				const t = retrievedTimeSpans[i];
				if (currentTime < t[0] && (timespan === null || t[0] < timespan[0])) {
					timespan = t;
					idx = i;
				}
			}
			return {
				nextTimespan: timespan,
				nextTimespanIdx: idx,
			};
		},
		[retrievedTimeSpans]
	);

	const getPreviousTimespan = useCallback(
		(currentTime: number): { previousTimespan: [number, number] | null; previousTimespanIdx: number | null } => {
			let timespan: [number, number] | null = null;
			let idx: number | null = null;
			for (let i = 0; i < retrievedTimeSpans.length; i++) {
				const t = retrievedTimeSpans[i];
				if (currentTime > t[1] && (timespan === null || t[1] > timespan[1])) {
					timespan = t;
					idx = i;
				}
			}
			return {
				previousTimespan: timespan,
				previousTimespanIdx: idx,
			};
		},
		[retrievedTimeSpans]
	);

	const addRetrievedTimespans = useCallback((timespan: [number, number]): void => {
		setRetrievedTimeSpans((prev) => [...prev, timespan]);
	}, []);

	const updateRetrievedTimespans = useCallback((timespan: [number, number], idx: number): void => {
		setRetrievedTimeSpans((prev) => {
			if (idx >= prev.length) return [...prev, timespan];
			if (idx < 0) throw new Error(`Trying to update detection timespan at index ${idx}`);

			const copiedPrev = [...prev];
			copiedPrev[idx] = timespan;
			return copiedPrev;
		});
	}, []);

	const removeRetrievedTimespans = useCallback((idx: number): void => {
		setRetrievedTimeSpans((prev) => {
			if (idx >= prev.length || idx < 0) return prev;

			const copiedPrev = [...prev];
			copiedPrev.splice(idx, 1);
			return copiedPrev;
		});
	}, []);

	const setParametersForDetections = useCallback(
		(currentTime: number, duration: number): void => {
			// Wait for current request to finish before requesting new data.
			if (loading) return;

			const { timespan } = getTimespan(currentTime);
			const { nextTimespan } = getNextTimespan(currentTime);
			const { previousTimespan } = getPreviousTimespan(currentTime);

			const previousTimespanWithinError: boolean =
				previousTimespan !== null && currentTime - previousTimespan[1] < errorMargin;

			let start: number;
			if (timespan !== null) start = timespan[1];
			else if (previousTimespan !== null && previousTimespanWithinError) start = previousTimespan[1];
			else start = currentTime;

			// If start time is equal to video duration, do nothing - we already loaded all detection we needed.
			if (start * 1000 >= Math.round(duration * 1000)) return;

			const deltaTime = start - currentTime;
			// If max threshold is reached don't do squat.
			if (deltaTime > maxThreshold) {
				return;
			}

			// f(x) = a * x + c; a->1, x-> delta, c-> minRequestTimespan.
			const chunk = deltaTime + minRequestTimespan;
			let end = chunk + start;
			// Limit end time to be no more than next timespan should be. This should also merge the two timespans.
			if (nextTimespan && end > nextTimespan[0]) end = nextTimespan[0];
			// also limit with duration
			if (end > duration) end = duration;

			// Start should be set to 1 second less, so we avoid problems with the rounding and non overlapping timespans.
			start = Math.max(0, start - 1);
			start = Math.round(start * 1000);
			end = Math.round(end * 1000);

			// If everything is okay than just retrieve the timespans
			setTimeSpanToRetrieve([start, end]);
		},
		[loading, getTimespan, getNextTimespan, getPreviousTimespan, errorMargin, maxThreshold, minRequestTimespan]
	);

	// Effect that works as poller, that checks every x seconds for new data and if request already finished.
	useEffect(() => {
		if (videoNode === null) return;

		const interval = setInterval(
			() => setParametersForDetections(videoNode.currentTime, videoNode.duration),
			sleepPeriod * 1000
		);
		return () => clearInterval(interval);
	}, [videoNode, setParametersForDetections, sleepPeriod]);

	// The effect is responsible for setting the buffer loader.
	useEffect(() => {
		if (!videoNode) return; // Video is still loading. Don't do squat.

		// The video is buffering when it is fucking downloading new data and current time is not in existing timespan.
		const { timespan } = getTimespan(videoNode.currentTime);
		const buffering = timespan == null;
		setIsBuffering(buffering);
	}, [videoNode, getTimespan]);

	const getTimespanRef = useValueRef(getTimespan);
	// The effect assigns timestamps that we have received of detections.
	useEffect(() => {
		if (data === null) return;

		// Transform from Miliseconds to seconds.
		const start = data.startTimestampMs / 1000;
		const end = data.endTimestampMs / 1000;

		const { timespan: startTimespan, timespanIdx: startIdx } = getTimespanRef.current(start);
		const { timespan: endTimespan, timespanIdx: endIdx } = getTimespanRef.current(end);
		// 1. start is at the end (or inside) existing
		if (startTimespan !== null && startIdx !== null) {
			// 1a. end is at the another one -> merge those two
			if (endTimespan !== null && endIdx !== null) {
				const newStart = startTimespan[0];
				const newEnd = endTimespan[1];
				updateRetrievedTimespans([newStart, newEnd], startIdx);
				removeRetrievedTimespans(endIdx);
			}
			// 1b. end is somewhere alone -> extend "previous"
			else {
				const newStart = startTimespan[0];
				updateRetrievedTimespans([newStart, end], startIdx);
			}
		}
		// 2. start is all alone
		else {
			// 2a. end is at the another one -> extend start from next one.
			if (endTimespan !== null && endIdx !== null) {
				const newStart = start;
				const newEnd = endTimespan[1];
				updateRetrievedTimespans([newStart, newEnd], endIdx);
			}
			// 2b. end is all alone -> create new one.
			else {
				addRetrievedTimespans([start, end]);
			}
		}
	}, [addRetrievedTimespans, data, getTimespanRef, removeRetrievedTimespans, updateRetrievedTimespans]);

	// The effect sets the retrieved detections
	useEffect(() => {
		if (data === null) return;

		setFrameDetections((prev) => ({
			...prev,
			...data.frameDetectionsPerFrame,
		}));
	}, [data]);

	useEffect(() => {
		if (error !== null) {
			setRetrievingFailed(true);
			setIsBuffering(false);
		}
	}, [error]);

	const resetFetchedDetections = useCallback(() => {
		setFrameDetections({});
		setRetrievedTimeSpans([]);
		setTimeSpanToRetrieve([-1, 1]);
		setRetrievingFailed(false);
	}, []);

	const removeFrameDetections = useCallback(
		(detectionIds: number[], fps: number): void => {
			setFrameDetections((prev) => {
				const copiedPrev = { ...prev };
				for (let i = 0; i < retrievedTimeSpans.length; i++) {
					const timespan = retrievedTimeSpans[i];
					const startTime = timespan[0];
					const endTime = timespan[1];

					for (let frame = fps * startTime; frame <= fps * endTime; frame++) {
						const detections = copiedPrev[frame];
						if (detections) {
							copiedPrev[frame] = detections.filter((x) => !detectionIds.includes(x.videoDetectionId));
						}
					}
				}
				return copiedPrev;
			});
		},
		[retrievedTimeSpans]
	);

	return {
		isBuffering,
		frameDetections,
		retrievedTimeSpans,
		setFrameDetections,
		resetFetchedDetections,
		removeFrameDetections,
	};
};

export default useFetchFrameDetections;
