import { AxiosPromise } from 'axios';
import Video from 'Models/Videos/Video';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

const getVideosProcessingRequest = (token: string | null): AxiosPromise<Video[]> => {
	const url = getApiUrl(ApiUrl.VideosVideoProcessing);
	const axios = getAxiosInstance(token);
	return axios.get(url);
};

export default getVideosProcessingRequest;
