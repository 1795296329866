import useIsVideoBuffering from 'Hooks/Editor/useIsVideoBuffering';
import useFetchBrandGroups from 'Hooks/Http/Brands/useFetchBrandGroups';
import useFetchProject from 'Hooks/Http/Projects/useFetchProject';
import useFetchEditorDetections from 'Hooks/Http/Videos/Detections/useFetchEditorDetections';
import useFetchFrameDetections from 'Hooks/Http/Videos/Detections/useFetchFrameDetections';
import useFetchPlacements from 'Hooks/Http/Videos/Placements/useFetchPlacements';
import useFetchVideo from 'Hooks/Http/Videos/useFetchVideo';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useValueRef from 'Hooks/useValueRef';
import Brand from 'Models/Brands/Brand';
import BrandGroup from 'Models/Brands/BrandGroup';
import EditorVideoDetection from 'Models/Videos/Detections/EditorVideoDetection';
import {
	EditorVideoNewDetectionWithNullableIds,
	isEditorVideoNewDetection,
} from 'Models/Videos/Detections/EditorVideoNewDetection';
import VideoDetectionCoordinate from 'Models/Videos/Detections/VideoDetectionCoordinate';
import VideoFrameDetection from 'Models/Videos/Detections/VideoFrameDetection';
import Video from 'Models/Videos/Video';
import VideoDetectionPlacement from 'Models/Videos/VideoDetectionPlacement';
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getCurrentPointFromNewDetectionCoordinates } from 'Utils/DetectionHelpers';
import { getTransformDetectionTimeErrorMargin } from 'Utils/Http/EnvReader';
import { FrameDetectionsPerFrame } from 'Utils/Http/Requests/Videos/Detections/GetFrameDetectionsRequest';
import postNewDetectionRequest from 'Utils/Http/Requests/Videos/Detections/PostNewDetectionRequest';

export enum EditorMode {
	NORMAL,
	NEW_DETECTION,
}

export enum PlayMode {
	NORMAL,
	SECONDS,
}

export enum VideoPlayerDetailsAreaMode {
	DETECTIONS,
	CHANGE_ALL_PLACEMENTS,
}

export interface UseVideoPlayerInstance {
	video: Video | null;
	frameDetections: FrameDetectionsPerFrame;
	placements: VideoDetectionPlacement[];
	loading: boolean;
	videoNode: HTMLVideoElement | null;
	setVideoNode: Dispatch<SetStateAction<HTMLVideoElement | null>>;
	isVideoPlaying: boolean;
	toggleVideoPlay: (play: boolean) => void;
	fps: number;
	selectDetectionIds: (detectionIds: number[], includePrevious: boolean) => void;
	unselectDetectionId: (detectionId: number) => void;
	selectedDetectionIds: number[];
	editorMode: EditorMode;
	detections: EditorVideoDetection[];
	getCurrentFrame: () => number;
	getCurrentFrameDetections: () => VideoFrameDetection[];
	brandGroups: BrandGroup[];
	brands: Brand[];
	filteredBrandIds: number[];
	filteredPlacementIds: number[];
	handleBrandFilterChanged: (id: number, checked: boolean) => void;
	handleAllBrandFilterChanged: (selected: boolean) => void;
	handlePlacementFilterChanged: (id: number, checked: boolean) => void;
	handleAllPlacementFilterChanged: (selected: boolean) => void;
	showFilteredDetections: boolean;
	toggleShowFilteredDetections: () => void;
	playMode: PlayMode;
	togglePlayMode: () => void;
	retrievedTimeSpans: [number, number][];
	changeDetectionPlacement: (detectionId: number, placementId: number) => void;
	changeDetectionBrand: (detectionId: number, brandId: number) => void;
	setEditorMode: Dispatch<SetStateAction<EditorMode>>;
	addNewDetection: (point1: [number, number], point2: [number, number], scale: number) => void;
	newDetections: EditorVideoNewDetectionWithNullableIds[];
	selectNewDetections: (idxes: number[], includePrevious: boolean) => void;
	toggleNewDetectionSelection: (idx: number) => void;
	transformNewDetection: (coordinate: VideoDetectionCoordinate, idx: number) => void;
	setPlacementForNewDetections: (placementId: number) => void;
	setBrandForNewDetections: (brandId: number) => void;
	removeNewDetections: () => void;
	completeNewDetections: () => void;
	removeDetections: (detectionsIds: number[]) => void;
	clearDetections: () => void;
	currentTimeRef: MutableRefObject<number>;
	setCurrentTime: (value: number) => void;
	detailsAreaMode: VideoPlayerDetailsAreaMode;
	setDetailsAreaMode: Dispatch<SetStateAction<VideoPlayerDetailsAreaMode>>;
}

const DEFAULT_FPS = 24;

const useVideoPlayerInstance = (): UseVideoPlayerInstance => {
	const [videoNode, setVideoNode] = useState<HTMLVideoElement | null>(null);
	const [isVideoPlaying, setIsVideoPlaying] = useState(false);
	const [selectedDetectionIds, setSelectedDetectionIds] = useState<number[]>([]);
	const [editorMode, setEditorMode] = useState(EditorMode.NORMAL);
	const selectedVideoId = useAppSelector((state) => state.video.selectedVideoId);
	const [filteredBrandIds, setFilteredBrandIds] = useState<number[]>([]);
	const [filteredPlacementIds, setFilteredPlacementIds] = useState<number[]>([]);
	const [showFilteredDetections, setShowFilteredDetections] = useState(false);
	const [playMode, setPlayMode] = useState(PlayMode.SECONDS);
	const [newDetections, setNewDetections] = useState<EditorVideoNewDetectionWithNullableIds[]>([]);
	const [forceRefreshEditorDetections, setForceRefreshEditorDetections] = useState(0);
	const [detailsAreaMode, setDetailsAreaMode] = useState<VideoPlayerDetailsAreaMode>(
		VideoPlayerDetailsAreaMode.DETECTIONS
	);

	const currentTimeRef = useRef(videoNode?.currentTime ?? 0);

	const { dispatchStartLoading, dispatchStopLoading } = useLoadingDispatch();
	const isBuffering = useIsVideoBuffering(videoNode);

	const token = useAppSelector<string | null>((state) => state.profile.token);

	const { data: video, loading: videoLoading } = useFetchVideo(selectedVideoId);
	const {
		frameDetections,
		isBuffering: frameDetectionsBuffering,
		retrievedTimeSpans,
		setFrameDetections,
		resetFetchedDetections,
		removeFrameDetections,
	} = useFetchFrameDetections(selectedVideoId, videoNode);
	const { data: placements, loading: placementsLoading } = useFetchPlacements();
	const { data: brandGroups, loading: brandsLoading } = useFetchBrandGroups();
	const { data: detections, loading: detectionsLoading, setData: setEditorDetectionsData } = useFetchEditorDetections(
		selectedVideoId,
		forceRefreshEditorDetections
	);

	const brands =
		useMemo(() => brandGroups?.groups.reduce((acc, x) => [...acc, ...x.brands], [] as Brand[]), [
			brandGroups?.groups,
		]) ?? null;

	const projectState = useFetchProject(video?.projectId ?? null);

	const frameDetectionsRef = useValueRef(frameDetections);

	const lastNewDetectionBrandIdRef = useRef<number | null>(null);
	const lastNewDetectionPlacementIdRef = useRef<number | null>(null);

	const loading = videoLoading || placementsLoading || detectionsLoading || brandsLoading;
	const fps = video?.fps ?? DEFAULT_FPS;

	useEffect(() => {
		if (isBuffering || frameDetectionsBuffering) dispatchStartLoading('Buffering');
		else dispatchStopLoading();
	}, [dispatchStartLoading, dispatchStopLoading, frameDetectionsBuffering, isBuffering]);

	// The effect will set the default filters based on project
	useEffect(() => {
		if (projectState.data === null || brands === null) return;
		const filteredBrandIds = brands
			.filter((x) => !projectState.data?.brandGroupIds?.includes(x.groupId))
			.map((x) => x.id);
		setFilteredBrandIds(filteredBrandIds);
	}, [brands, projectState.data]);

	useEffect(() => {
		if (!isVideoPlaying || playMode !== PlayMode.SECONDS || videoNode === null) return;

		const interval = window.setInterval(() => {
			const currentTime = videoNode.currentTime;
			videoNode.currentTime = Math.floor(currentTime + 1);
		}, 1000); // Every second.
		return () => {
			window.clearInterval(interval);
		};
	}, [isVideoPlaying, playMode, videoNode]);

	// The effect will update the end times for all new detections when video time will change.
	useEffect(() => {
		if (videoNode === null || newDetections.length === 0) return;

		videoNode.ontimeupdate = () => {
			setNewDetections((prev) =>
				prev.map((x) => {
					const modifiedCoordinates = [...x.coordinates];
					modifiedCoordinates[x.coordinates.length - 1] = {
						...modifiedCoordinates[x.coordinates.length - 1],
						endTime: Math.ceil(currentTimeRef.current * 1000),
					};
					const modified: EditorVideoNewDetectionWithNullableIds = {
						...x,
						coordinates: modifiedCoordinates,
					};
					return modified;
				})
			);
		};
	}, [newDetections.length, videoNode]);

	useEffect(() => {
		if (videoNode === null) return;

		const timeUpdatedFunction = (): void => {
			if (!isBuffering) currentTimeRef.current = videoNode.currentTime;
		};
		if (!isBuffering) currentTimeRef.current = videoNode.currentTime;

		videoNode.addEventListener('timeupdate', timeUpdatedFunction);
		return () => {
			videoNode.removeEventListener('timeupdate', timeUpdatedFunction);
		};
	}, [isBuffering, videoNode]);

	const getCurrentFrame = useCallback((): number => {
		if (videoNode === null) return 0;

		return Math.ceil(currentTimeRef.current * fps);
	}, [fps, videoNode]);

	const getCurrentFrameDetections = useCallback((): VideoFrameDetection[] => {
		const currentFrame = getCurrentFrame();
		return frameDetectionsRef.current[currentFrame] ?? [];
	}, [frameDetectionsRef, getCurrentFrame]);

	const toggleVideoPlay = useCallback(
		(play: boolean): void => {
			if (videoNode === null) return;

			if (play && videoNode.paused) {
				if (playMode === PlayMode.NORMAL) videoNode.play().then(() => setIsVideoPlaying(true));
				else if (playMode === PlayMode.SECONDS) setIsVideoPlaying(true);
			} else if (!play) {
				if (playMode === PlayMode.NORMAL) videoNode.pause();
				setIsVideoPlaying(false);
			}
		},
		[playMode, videoNode]
	);

	const selectDetectionIds = useCallback((detectionIds: number[], includePrevious: boolean): void => {
		if (!includePrevious) setSelectedDetectionIds(detectionIds);
		else setSelectedDetectionIds((prev) => [...prev, ...detectionIds]);
	}, []);

	const unselectDetectionId = useCallback((detectionId: number): void => {
		setSelectedDetectionIds((prev) => prev.filter((x) => x !== detectionId));
	}, []);

	const handleBrandFilterChanged = useCallback(
		(id: number, checked: boolean): void => {
			if (checked) {
				const detectionsWithBrandIds = detections?.filter((x) => x.brandId === id)?.map((x) => x.id) ?? [];
				setSelectedDetectionIds((prev) => prev.filter((x) => !detectionsWithBrandIds.includes(x)));
			}
			setFilteredBrandIds((prev) => {
				if (checked) return [...prev, id];
				return prev.filter((x) => x !== id);
			});
		},
		[detections]
	);

	const handleAllBrandFilterChanged = useCallback(
		(selected: boolean): void => {
			if (selected) {
				setFilteredBrandIds(brands?.map((x) => x.id) ?? []);
				setSelectedDetectionIds([]);
			} else setFilteredBrandIds([]);
		},
		[brands]
	);

	const handlePlacementFilterChanged = useCallback(
		(id: number, checked: boolean): void => {
			if (checked) {
				const detectionsWithPlacementIds = detections?.filter((x) => x.placementId === id)?.map((x) => x.id) ?? [];
				setSelectedDetectionIds((prev) => prev.filter((x) => !detectionsWithPlacementIds.includes(x)));
			}
			setFilteredPlacementIds((prev) => {
				if (checked) return [...prev, id];
				return prev.filter((x) => x !== id);
			});
		},
		[detections]
	);

	const handleAllPlacementFilterChanged = useCallback(
		(selected: boolean): void => {
			if (selected) {
				setFilteredPlacementIds(placements?.map((x) => x.id) ?? []);
				setSelectedDetectionIds([]);
			} else setFilteredPlacementIds([]);
		},
		[placements]
	);

	const toggleShowFilteredDetections = useCallback((): void => {
		setShowFilteredDetections((prev) => !prev);
	}, []);

	const togglePlayMode = useCallback((): void => {
		// If video is not loaded it makes no sense to change anything.
		if (videoNode === null) return;

		// We had normal play up till now.
		if (playMode === PlayMode.NORMAL) {
			setPlayMode(PlayMode.SECONDS);
			if (!videoNode.paused) videoNode.pause();
		} else if (playMode === PlayMode.SECONDS) {
			setPlayMode(PlayMode.NORMAL);
			if (isVideoPlaying) videoNode.play();
		}
	}, [isVideoPlaying, playMode, videoNode]);

	const changeEditorDetectionPlacement = useCallback(
		(detectionId: number, placementId: number): void => {
			setEditorDetectionsData((prev) => {
				if (prev === null) return prev;

				return prev.map((x) => {
					if (x.id === detectionId) {
						return {
							...x,
							placementId,
						};
					}
					return x;
				});
			});
		},
		[setEditorDetectionsData]
	);

	const changeEditorDetectionBrand = useCallback(
		(detectionId: number, brandId: number): void => {
			setEditorDetectionsData((prev) => {
				if (prev === null) return prev;

				return prev.map((x) => {
					if (x.id === detectionId) {
						return {
							...x,
							brandId,
						};
					}
					return x;
				});
			});
		},
		[setEditorDetectionsData]
	);

	const changeFrameDetectionPlacement = useCallback(
		(detectionId: number, placementId: number): void => {
			setFrameDetections((prev) => {
				const copiedPrev: FrameDetectionsPerFrame = { ...prev };
				Object.keys(copiedPrev).forEach((k) => {
					const numberKey = Number(k);
					copiedPrev[numberKey] = copiedPrev[numberKey].map((x) => {
						if (x.videoDetectionId === detectionId) return { ...x, placementId };
						return x;
					});
				});
				return copiedPrev;
			});
		},
		[setFrameDetections]
	);

	const changeFrameDetectionBrand = useCallback(
		(detectionId: number, brandId: number): void => {
			setFrameDetections((prev) => {
				const copiedPrev: FrameDetectionsPerFrame = { ...prev };
				Object.keys(copiedPrev).forEach((k) => {
					const numberKey = Number(k);
					copiedPrev[numberKey] = copiedPrev[numberKey].map((x) => {
						if (x.videoDetectionId === detectionId) return { ...x, brandId };
						return x;
					});
				});
				return copiedPrev;
			});
		},
		[setFrameDetections]
	);

	const changeDetectionPlacement = useCallback(
		(detectionId: number, placementId: number): void => {
			changeEditorDetectionPlacement(detectionId, placementId);
			changeFrameDetectionPlacement(detectionId, placementId);
		},
		[changeEditorDetectionPlacement, changeFrameDetectionPlacement]
	);

	const changeDetectionBrand = useCallback(
		(detectionId: number, brandId: number): void => {
			changeEditorDetectionBrand(detectionId, brandId);
			changeFrameDetectionBrand(detectionId, brandId);
		},
		[changeEditorDetectionBrand, changeFrameDetectionBrand]
	);

	const addNewDetection = useCallback(
		(point1: [number, number], point2: [number, number], scale: number): void => {
			if (selectedVideoId === null) {
				alert('No video is selected.');
				return;
			}
			if (videoNode === null) {
				alert('Video is not yet initialized');
				return;
			}
			const { left, top } = videoNode.getBoundingClientRect();

			const coordinate: VideoDetectionCoordinate = {
				point1: { x: (point1[0] - left) / scale, y: (point1[1] - top) / scale },
				point2: { x: (point2[0] - left) / scale, y: (point1[1] - top) / scale },
				point3: { x: (point2[0] - left) / scale, y: (point2[1] - top) / scale },
				point4: { x: (point1[0] - left) / scale, y: (point2[1] - top) / scale },
			};
			const timeInMs = Math.ceil(currentTimeRef.current * 1000);

			const newDetection: EditorVideoNewDetectionWithNullableIds = {
				brandId: lastNewDetectionBrandIdRef.current,
				placementId: lastNewDetectionPlacementIdRef.current,
				videoId: selectedVideoId,
				coordinates: [
					{
						coordinate,
						endTime: timeInMs,
						startTime: timeInMs,
					},
				],
				selected: false,
			};
			setNewDetections((prev) => [...prev, newDetection]);
		},
		[selectedVideoId, videoNode]
	);

	const selectNewDetection = useCallback((idxes: number[], includePrevious: boolean): void => {
		if (idxes.length < 0) return;

		setNewDetections((prev) => {
			return prev.map((x, i) => {
				if (idxes.includes(i)) {
					return {
						...x,
						selected: true,
					};
				}

				return {
					...x,
					selected: includePrevious ? x.selected : false,
				};
			});
		});
	}, []);

	const toggleNewDetectionSelection = useCallback((idx: number): void => {
		if (idx < 0) return;

		setNewDetections((prev) => {
			if (idx >= prev.length) return prev;
			return prev.map((x, i) => {
				if (i === idx) {
					return {
						...x,
						selected: !x.selected,
					};
				}
				return x;
			});
		});
	}, []);

	const transformNewDetection = useCallback(
		(coordinate: VideoDetectionCoordinate, idx: number): void => {
			if (videoNode === null) return;

			setNewDetections((prev) => {
				const detections = [...prev];
				const newDetectionCoordinates = [...prev[idx].coordinates];
				const [currentCoordinate, coordinateIdx] = getCurrentPointFromNewDetectionCoordinates(
					newDetectionCoordinates,
					currentTimeRef.current
				);

				const timeInMs = Math.ceil(currentTimeRef.current * 1000);
				const errorMargin = getTransformDetectionTimeErrorMargin();

				// 1. Začetni čas je znotraj napake -> posodobi točke trenutnega
				if (Math.abs(currentCoordinate.startTime - timeInMs) <= errorMargin) {
					currentCoordinate.coordinate = coordinate; // Works cus reference type
				}
				// 2. Končni čas je znotraj napake in naslednji obstaja -> posodobi točke naslednjega
				else if (
					Math.abs(currentCoordinate.endTime - timeInMs) <= errorMargin &&
					coordinateIdx + 1 < newDetectionCoordinates.length
				) {
					newDetectionCoordinates[coordinateIdx + 1].coordinate = coordinate;
				}
				// 3. Naredi novega
				else {
					const endTime = currentCoordinate.endTime;
					currentCoordinate.endTime = timeInMs;
					newDetectionCoordinates.splice(coordinateIdx + 1, 0, {
						endTime,
						coordinate,
						startTime: timeInMs,
					});
				}
				detections[idx].coordinates = newDetectionCoordinates;
				return detections;
			});
		},
		[videoNode]
	);

	const setPlacementForNewDetections = useCallback((placementId: number): void => {
		lastNewDetectionPlacementIdRef.current = placementId;
		setNewDetections((prev) =>
			prev.map((x) => {
				if (x.selected) {
					return {
						...x,
						placementId,
					};
				}
				return x;
			})
		);
	}, []);

	const setBrandForNewDetections = useCallback((brandId: number): void => {
		lastNewDetectionBrandIdRef.current = brandId;
		setNewDetections((prev) =>
			prev.map((x) => {
				if (x.selected) {
					return {
						...x,
						brandId,
					};
				}
				return x;
			})
		);
	}, []);

	const removeNewDetections = useCallback((): void => {
		setNewDetections((prev) => prev.filter((x) => !x.selected));
	}, []);

	const clearDetections = useCallback((): void => {
		resetFetchedDetections();
		setForceRefreshEditorDetections((prev) => prev + 1);
		setSelectedDetectionIds([]);
	}, [resetFetchedDetections]);

	const completeNewDetections = useCallback((): void => {
		if (video === null) {
			alert('Unable to save new detections. Video does not seems to be loaded.');
			return;
		}

		const detectionsToSave = newDetections.filter((x) => x.selected && x.brandId !== null && x.placementId !== null);
		dispatchStartLoading('Saving new detections.');
		const promises = detectionsToSave.map((x) => {
			if (isEditorVideoNewDetection(x)) return postNewDetectionRequest(token, x, video.id);
			return new Promise(() => Promise.resolve());
		});
		Promise.all(promises)
			.then(() => {
				setNewDetections((prev) => prev.filter((x) => !x.selected));
				clearDetections();
			})
			.finally(() => {
				dispatchStopLoading();
			});
	}, [dispatchStartLoading, dispatchStopLoading, newDetections, clearDetections, token, video]);

	const removeSelectedDetections = useCallback((): void => {
		// 1. Remove them from frame detections.
		if (video?.fps !== undefined && video?.fps !== null) removeFrameDetections(selectedDetectionIds, video?.fps);
		// 2. Remove the from editor detections
		setEditorDetectionsData((prev) => {
			if (prev === null) return null;
			return prev.filter((x) => !selectedDetectionIds.includes(x.id));
		});
		// 3. Remove them from selected array
		setSelectedDetectionIds([]);
	}, [removeFrameDetections, selectedDetectionIds, setEditorDetectionsData, video?.fps]);

	const setCurrentTime = useCallback(
		(value: number): void => {
			if (videoNode === null) return;
			videoNode.currentTime = value;
		},
		[videoNode]
	);

	return {
		video,
		frameDetections: frameDetections ?? [],
		placements: placements ?? [],
		loading,
		videoNode,
		setVideoNode,
		isVideoPlaying,
		toggleVideoPlay,
		fps,
		selectDetectionIds,
		selectedDetectionIds,
		editorMode: editorMode,
		detections: detections ?? [],
		getCurrentFrame,
		getCurrentFrameDetections,
		unselectDetectionId,
		brandGroups: brandGroups?.groups ?? [],
		brands: brands ?? [],
		filteredBrandIds,
		filteredPlacementIds,
		handleBrandFilterChanged,
		handlePlacementFilterChanged,
		showFilteredDetections,
		toggleShowFilteredDetections,
		playMode,
		togglePlayMode,
		retrievedTimeSpans,
		changeDetectionPlacement,
		changeDetectionBrand,
		setEditorMode,
		addNewDetection,
		newDetections,
		selectNewDetections: selectNewDetection,
		toggleNewDetectionSelection,
		transformNewDetection,
		setPlacementForNewDetections,
		setBrandForNewDetections,
		removeNewDetections,
		completeNewDetections,
		removeDetections: removeSelectedDetections,
		clearDetections,
		currentTimeRef,
		setCurrentTime,
		handleAllBrandFilterChanged,
		handleAllPlacementFilterChanged,
		detailsAreaMode,
		setDetailsAreaMode,
	};
};

export default useVideoPlayerInstance;
