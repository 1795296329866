import { AxiosPromise } from 'axios';
import BrandGroup from 'Models/Brands/BrandGroup';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export type PostSplitBrandGroupResponse = BrandGroup;
export type PostSplitBrandGroupRequest = (
	token: string | null,
	brandId: number
) => AxiosPromise<PostSplitBrandGroupResponse>;

const postSplitBrandGroupRequest: PostSplitBrandGroupRequest = async (token, brandId) => {
	const url = getApiUrl(ApiUrl.BrandGroupsSplit);
	const body = { brandId };
	const axios = getAxiosInstance(token);
	return axios.post<PostSplitBrandGroupResponse>(url, body);
};

export default postSplitBrandGroupRequest;
