import { Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FilterDialogContent from 'Components/Common/Dialogs/FilterDialog/FilterDialogContent';
import Brand from 'Models/Brands/Brand';
import VideoDetectionPlacement from 'Models/Videos/VideoDetectionPlacement';
import { ChangeEvent, ReactElement, useState } from 'react';

interface FilterDialogProps {
	brands: Brand[];
	placements: VideoDetectionPlacement[];
	open: boolean;
	onClose: () => void;
	filteredBrandIds: number[];
	filteredPlacementIds: number[];
	onBrandSelectionChanged: (id: number, checked: boolean) => void;
	onAllBrandSelectionChanged: (selected: boolean) => void;
	onPlacementSelectionChanged: (id: number, checked: boolean) => void;
	onAllPlacementSelectionChanged: (selected: boolean) => void;
}

const FilterDialog = (props: FilterDialogProps): ReactElement => {
	const [selectedTab, setSelectedTab] = useState(0);

	const handleClose = (): void => {
		props.onClose();
	};

	const handleTabChange = (event: ChangeEvent<{}>, newValue: number): void => {
		setSelectedTab(newValue);
	};

	const handleFilteredBrandSelectionChanged = (id: number, checked: boolean): void => {
		props.onBrandSelectionChanged(id, checked);
	};

	const handleFilteredPlacementSelectionChanged = (id: number, checked: boolean): void => {
		props.onPlacementSelectionChanged(id, checked);
	};

	const handleAllFilteredBrandsSelected = (checked: boolean): void => {
		props.onAllBrandSelectionChanged(checked);
	};

	const handleAllFilteredPlacementSelected = (checked: boolean): void => {
		props.onAllPlacementSelectionChanged(checked);
	};

	return (
		<Dialog open={props.open} onClose={handleClose}>
			<DialogTitle>
				<Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered>
					<Tab label="Brands" />
					<Tab label="Placements" />
				</Tabs>
			</DialogTitle>
			<DialogContent>
				<Grid container>
					{selectedTab === 0 && (
						<FilterDialogContent
							data={props.brands}
							filteredDataIds={props.filteredBrandIds}
							onSelectionChanged={handleFilteredBrandSelectionChanged}
							onAllSelected={handleAllFilteredBrandsSelected}
						/>
					)}
					{selectedTab === 1 && (
						<FilterDialogContent
							data={props.placements}
							filteredDataIds={props.filteredPlacementIds}
							onSelectionChanged={handleFilteredPlacementSelectionChanged}
							onAllSelected={handleAllFilteredPlacementSelected}
						/>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button type="button" color="primary" variant="text" onClick={handleClose}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default FilterDialog;
