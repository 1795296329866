import downloadBlob from 'Utils/DownloadBlob';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';
import { PostCreateAndExportDefaultDetectionsRequest } from 'Utils/Http/Requests/Videos/Detections/Export/PostCreateAndExportDefaultDetectionsRequest';

export interface PostCreateAndExportSwissDetectionsRequest extends PostCreateAndExportDefaultDetectionsRequest {}

const postCreateAndExportSwissDetectionsRequest = async (
	token: string | null,
	request: PostCreateAndExportSwissDetectionsRequest,
	filename: string
): Promise<void> => {
	const url = getApiUrl(ApiUrl.VideosDetectionsExportSwiss);
	const axiosInstance = getAxiosInstance(token);
	const response = await axiosInstance.post<Blob>(url, request, { responseType: 'blob' });
	downloadBlob(response.data, { filename });
};

export default postCreateAndExportSwissDetectionsRequest;
