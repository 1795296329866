import { AxiosPromise } from 'axios';
import Brand from 'Models/Brands/Brand';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

type PutBrandResponse = Brand;

interface PutBrandRequest {
	name: string;
}

export const putBrand = (
	token: string | null,
	brandId: number,
	request: PutBrandRequest
): AxiosPromise<PutBrandResponse> => {
	const url = getApiUrl(ApiUrl.BrandsBrand).replace(':brandId', brandId.toString());
	const axios = getAxiosInstance(token);
	return axios.put<PutBrandResponse>(url, request);
};
