import { Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Modal, { ModalProps } from 'Components/Common/Modals/Modal';
import useModalClasses from 'Hooks/Styles/Common/useModalClasses';
import React, { PropsWithChildren, ReactElement } from 'react';

interface ConfirmModalProps extends ModalProps {
	onConfirmClicked: () => void;
	title: string;
	confirmButtonText?: string;
	confirmButtonDisabled?: boolean;
	closeButtonText?: string;
}

const ConfirmModal = (props: PropsWithChildren<ConfirmModalProps>): ReactElement => {
	const { title, onConfirmClicked, children, confirmButtonText, closeButtonText, ...restProps } = props;

	const classes = useModalClasses();

	const handleCloseButtonClicked = (event: React.MouseEvent<HTMLButtonElement>): void => {
		if (restProps.onClose) restProps.onClose(event, 'escapeKeyDown');
	};

	return (
		<Modal {...restProps}>
			<Typography variant="h5">{title}</Typography>
			<Divider />
			<div className={classes.confirmModalBody}>{children}</div>
			<Button
				variant="contained"
				onClick={onConfirmClicked}
				color="primary"
				className={classes.primaryModalButton}
				type="button"
			>
				{confirmButtonText ? confirmButtonText : 'Confirm'}
			</Button>
			<Button
				variant="text"
				onClick={handleCloseButtonClicked}
				color="secondary"
				className={classes.primaryModalButton}
			>
				{closeButtonText ? closeButtonText : 'Close'}
			</Button>
		</Modal>
	);
};

export default ConfirmModal;
