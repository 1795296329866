import { AxiosPromise } from 'axios';
import EditorVideoDetection from 'Models/Videos/Detections/EditorVideoDetection';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export interface PatchVideoDetectionRequest {
	placementId?: number;
	brandId?: number;
}

const patchVideoDetection = (
	token: string | null,
	detectionId: number,
	videoId: number,
	request: PatchVideoDetectionRequest
): AxiosPromise<EditorVideoDetection> => {
	const url = getApiUrl(ApiUrl.VideosVideoDetectionsDetection)
		.replace(':videoId', videoId.toString())
		.replace(':detectionId', detectionId.toString());
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.patch<EditorVideoDetection>(url, request);
};

export default patchVideoDetection;
