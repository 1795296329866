import { AxiosPromise } from 'axios';
import { Space } from 'Models/Space';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

const getSpaceRequest = (token: string | null): AxiosPromise<Space> => {
	const url = getApiUrl(ApiUrl.Space);
	const axios = getAxiosInstance(token);
	return axios.get(url);
};

export default getSpaceRequest;
