import { PlayMode } from 'Hooks/Editor/useVideoPlayerInstance';
import {
	EditorVideoNewDetectionCoordinate,
	EditorVideoNewDetectionWithNullableIds,
} from 'Models/Videos/Detections/EditorVideoNewDetection';
import VideoDetectionCoordinate, { VideoDetectionPoint } from 'Models/Videos/Detections/VideoDetectionCoordinate';
import VideoFrameDetection from 'Models/Videos/Detections/VideoFrameDetection';
import { FrameDetectionsPerFrame } from 'Utils/Http/Requests/Videos/Detections/GetFrameDetectionsRequest';

export const scaleDetections = (detections: VideoFrameDetection[], scale: number): VideoFrameDetection[] => {
	const scaledDetections: VideoFrameDetection[] = [];
	for (let i = 0; i < detections.length; i++) {
		const detection = detections[i];
		const scaledDetection: VideoFrameDetection = {
			...detection,
			coordinate: {
				point1: { x: detection.coordinate.point1.x * scale, y: detection.coordinate.point1.y * scale },
				point2: { x: detection.coordinate.point2.x * scale, y: detection.coordinate.point2.y * scale },
				point3: { x: detection.coordinate.point3.x * scale, y: detection.coordinate.point3.y * scale },
				point4: { x: detection.coordinate.point4.x * scale, y: detection.coordinate.point4.y * scale },
			},
		};
		scaledDetections.push(scaledDetection);
	}
	return scaledDetections;
};

export const scaleNewDetections = (
	newDetections: EditorVideoNewDetectionWithNullableIds[],
	scale: number
): EditorVideoNewDetectionWithNullableIds[] => {
	const scaledNewDetections: EditorVideoNewDetectionWithNullableIds[] = [];
	for (let i = 0; i < newDetections.length; i++) {
		const d: EditorVideoNewDetectionWithNullableIds = {
			...newDetections[i],
			coordinates: newDetections[i].coordinates.map((c) => ({
				...c,
				coordinate: {
					point1: { x: c.coordinate.point1.x * scale, y: c.coordinate.point1.y * scale },
					point2: { x: c.coordinate.point2.x * scale, y: c.coordinate.point2.y * scale },
					point3: { x: c.coordinate.point3.x * scale, y: c.coordinate.point3.y * scale },
					point4: { x: c.coordinate.point4.x * scale, y: c.coordinate.point4.y * scale },
				},
			})),
		};
		scaledNewDetections.push(d);
	}
	return scaledNewDetections;
};

export const isPointInCoordinate = (x: number, y: number, coordinate: VideoDetectionCoordinate): boolean => {
	const coords = [
		coordinate.point1.x,
		coordinate.point1.y,
		coordinate.point2.x,
		coordinate.point2.y,
		coordinate.point3.x,
		coordinate.point3.y,
		coordinate.point4.x,
		coordinate.point4.y,
	];
	// Borrowed from:
	// https://stackoverflow.com/questions/1119627/how-to-test-if-a-point-is-inside-of-a-convex-polygon-in-2d-integer-coordinates
	let pos = 0,
		neg = 0;
	for (let i = 0; i < coords.length / 2; i++) {
		let x1 = coords[i * 2];
		let y1 = coords[i * 2 + 1];

		let i2 = i < coords.length / 2 - 1 ? i + 1 : 0;
		let x2 = coords[i2 * 2];
		let y2 = coords[i2 * 2 + 1];

		//skalarni produkt
		let d = (x - x1) * (y2 - y1) - (y - y1) * (x2 - x1);
		if (d > 0) pos++;
		if (d < 0) neg++;

		if (pos > 0 && neg > 0) return false;
	}
	return true;
};

/**
 * This function transforms point from global in page to point relative to canvas. Do note that this video should have
 * the same position and sizes as video.
 * @see {@link globalPointToVideo}
 * @return point relative to canvas.
 */
export const globalPointToCanvas = (
	canvas: HTMLCanvasElement,
	pageX: number,
	pageY: number
): { x: number; y: number } => {
	const { left, top } = canvas.getBoundingClientRect();
	const x = pageX - left;
	const y = pageY - top;
	return { x, y };
};

export const isFilteredDetection = (
	{ placementId, brandId }: Pick<VideoFrameDetection, 'placementId' | 'brandId'>,
	filteredBrandIds: number[],
	filteredPlacementIds: number[]
) => filteredPlacementIds.indexOf(placementId) > -1 || filteredBrandIds.indexOf(brandId) > -1;

export const getNextBufferedDetectionTime = (
	fps: number,
	detections: FrameDetectionsPerFrame,
	currentTime: number,
	maxTime: number,
	filteredBrandIds: number[],
	filteredPlacementIds: number[],
	backward: boolean,
	playMode: PlayMode
): number | null => {
	let frame = Math.round(currentTime * fps);

	// Podatki o frameih trenutno prikazani
	let currentVisible = [];
	if (frame in detections) {
		let d = detections[frame];
		for (let i = 0; i < d.length; i++) {
			currentVisible.push(d[i].videoDetectionId);
		}
	}
	const endTime = Math.floor(maxTime * fps);
	// iterirajmo od trenutnega frame do maksimalnega oz. do minimalnega
	while (frame < endTime && frame >= 0) {
		// Ce najdli deteckijo, a je kaksna nova?
		if (frame in detections) {
			let d = detections[frame];
			for (let i = 0; i < d.length; i++) {
				const isFilteredDetection =
					filteredPlacementIds.includes(d[i].placementId) || filteredBrandIds.includes(d[i].brandId);
				const isCurrentDetection = currentVisible.includes(d[i].videoDetectionId);
				if (!isFilteredDetection && !isCurrentDetection) {
					// Najdli novo detekcijo, ne rabimo vec preiskovat naprej
					return frame / fps;
				}
			}
		}
		// #10: Če imamo playmode po sekundah, potem gremo na naslednjo celo sekundo naprej
		const frameInterval = playMode === PlayMode.SECONDS ? fps - (frame % fps) : 1;
		// gremo na naslednji frame (prejsnji ce je za nazaj)
		// To naredimo tudi za že najdene detekcjie (sklepamo, da so detekcije več kot en frame dolge)
		if (!backward) frame += frameInterval;
		else frame -= frameInterval;
	}

	// Nismo našli detekcije.
	return null;
};

export const getCurrentPointFromNewDetectionCoordinates = (
	coordinates: EditorVideoNewDetectionCoordinate[],
	currentTime: number
): [EditorVideoNewDetectionCoordinate, number] => {
	const timeInMs = currentTime * 1000;
	for (let i = 0; i < coordinates.length; i++) {
		const coordinateData = coordinates[i];
		const isCurrentTimeLessThanBeginTime = timeInMs <= coordinateData.startTime;
		const isCurrentTimeInsideInterval = coordinateData.startTime < timeInMs && timeInMs < coordinateData.endTime;
		if (isCurrentTimeLessThanBeginTime || isCurrentTimeInsideInterval) {
			return [coordinateData, i];
		}
	}
	// If we are here it means that no interval contains current time. Now we do want to return the last one as it
	// is the only want to be rendered.
	return [coordinates[coordinates.length - 1], coordinates.length - 1];
};

const interpolatePoint = (
	point1: VideoDetectionPoint,
	point2: VideoDetectionPoint,
	frames: number,
	currentFrame: number
): VideoDetectionPoint => {
	const deltaX = point2.x - point1.x;
	const deltaY = point2.y - point1.y;

	const stepX = deltaX / frames;
	const stepY = deltaY / frames;

	const x = stepX * currentFrame + point1.x;
	const y = stepY * currentFrame + point1.y;

	return { x, y };
};

export const getInterpolatedPointsFromCoordinates = (
	coordinates: EditorVideoNewDetectionCoordinate[],
	currentTime: number,
	fps: number
): VideoDetectionCoordinate => {
	// 1. Get current points
	const [currentCoordinate, coordinateIdx] = getCurrentPointFromNewDetectionCoordinates(coordinates, currentTime);

	// If idx is last element, no next coordinate exists.
	const hasNextCoordinate = coordinateIdx < coordinates.length - 1;
	if (!hasNextCoordinate) return currentCoordinate.coordinate;

	const nextCoordinate = coordinates[coordinateIdx + 1];

	const duration = (currentCoordinate.endTime - currentCoordinate.startTime) / 1000; // Transform to seconds
	// duration must not be less than or equal zero. in that case return original position.
	if (duration <= 0) return currentCoordinate.coordinate;
	const frames = Math.ceil(fps * duration);

	const currentFrame = Math.ceil(fps * (currentTime - currentCoordinate.startTime / 1000));

	return {
		point1: interpolatePoint(
			currentCoordinate.coordinate.point1,
			nextCoordinate.coordinate.point1,
			frames,
			currentFrame
		),
		point2: interpolatePoint(
			currentCoordinate.coordinate.point2,
			nextCoordinate.coordinate.point2,
			frames,
			currentFrame
		),
		point3: interpolatePoint(
			currentCoordinate.coordinate.point3,
			nextCoordinate.coordinate.point3,
			frames,
			currentFrame
		),
		point4: interpolatePoint(
			currentCoordinate.coordinate.point4,
			nextCoordinate.coordinate.point4,
			frames,
			currentFrame
		),
	};
};

const calculateDistanceBetweenClickAndPoint = (
	x: number,
	y: number,
	point: VideoDetectionPoint,
	scale: number
): number => {
	const px = point.x * scale;
	const py = point.y * scale;

	const deltaX = x - px;
	const deltaY = y - py;

	return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
};

export const getIdxForClickOnNewDetectionVertex = (
	x: number,
	y: number,
	newDetections: EditorVideoNewDetectionWithNullableIds[],
	currentTime: number,
	errorMargin: number,
	scale: number
): number | null => {
	for (let i = 0; i < newDetections.length; i++) {
		const newDetection = newDetections[i];
		const [coordinate] = getCurrentPointFromNewDetectionCoordinates(newDetection.coordinates, currentTime);

		if (calculateDistanceBetweenClickAndPoint(x, y, coordinate.coordinate.point1, scale) < errorMargin) return i;
		if (calculateDistanceBetweenClickAndPoint(x, y, coordinate.coordinate.point2, scale) < errorMargin) return i;
		if (calculateDistanceBetweenClickAndPoint(x, y, coordinate.coordinate.point3, scale) < errorMargin) return i;
		if (calculateDistanceBetweenClickAndPoint(x, y, coordinate.coordinate.point4, scale) < errorMargin) return i;
	}
	return null;
};

const isPointOnLine = (
	point: VideoDetectionPoint,
	line: { p1: VideoDetectionPoint; p2: VideoDetectionPoint },
	errorMargin: number,
	scale: number
): boolean => {
	const x1 = line.p1.x * scale - errorMargin;
	const x2 = line.p2.x * scale + errorMargin;
	const y1 = line.p1.y * scale - errorMargin;
	const y2 = line.p2.y * scale + errorMargin;

	// f(x) = ax + b
	// Izračunamo linearno funkcijo, s katero potem lahko ugotovimo, če je podana točka na njej (oz. z določeno napako mim).
	const a = (y2 - y1) / (x2 - x1);
	// b = f(x) - ax
	// uporabimo eno izmed obstoječih točk da izračunamo b.
	const b = y1 - a * x1;
	const fx = a * point.x + b;

	const isBetweenXes = (x1 < point.x && point.x < x2) || (x2 < point.x && point.x < x1);
	const isBetweenYs = (y1 < point.y && point.y < y2) || (y2 < point.y && point.y < y1);
	const isWithinErrorMarginOrInfinity = !isFinite(a) || Math.abs(fx - point.y) < errorMargin;

	return isBetweenXes && isBetweenYs && isWithinErrorMarginOrInfinity;
};

export const getIdxForClickOnNewDetectionEdge = (
	x: number,
	y: number,
	newDetections: EditorVideoNewDetectionWithNullableIds[],
	currentTime: number,
	errorMargin: number,
	scale: number
): number | null => {
	for (let i = 0; i < newDetections.length; i++) {
		const newDetection = newDetections[i];
		const coordinate = getCurrentPointFromNewDetectionCoordinates(newDetection.coordinates, currentTime)[0].coordinate;

		if (isPointOnLine({ x, y }, { p1: coordinate.point1, p2: coordinate.point2 }, errorMargin, scale)) return i;
		if (isPointOnLine({ x, y }, { p1: coordinate.point2, p2: coordinate.point3 }, errorMargin, scale)) return i;
		if (isPointOnLine({ x, y }, { p1: coordinate.point3, p2: coordinate.point4 }, errorMargin, scale)) return i;
		if (isPointOnLine({ x, y }, { p1: coordinate.point4, p2: coordinate.point1 }, errorMargin, scale)) return i;
	}
	return null;
};

export const getDistanceBetweenPoints = (x1: number, y1: number, x2: number, y2: number): number => {
	const x = (x1 - x2) ** 2;
	const y = (y1 - y2) ** 2;

	return Math.sqrt(x + y);
};

export const getIdxOfClosestPoint = (x: number, y: number, coordinates: VideoDetectionCoordinate): number => {
	const d1 = getDistanceBetweenPoints(x, y, coordinates.point1.x, coordinates.point1.y);
	const d2 = getDistanceBetweenPoints(x, y, coordinates.point2.x, coordinates.point2.y);
	const d3 = getDistanceBetweenPoints(x, y, coordinates.point3.x, coordinates.point3.y);
	const d4 = getDistanceBetweenPoints(x, y, coordinates.point4.x, coordinates.point4.y);

	const minDistance = Math.min(d1, d2, d3, d4);
	if (minDistance === d1) return 0;
	if (minDistance === d2) return 1;
	if (minDistance === d3) return 2;
	if (minDistance === d4) return 3;

	return -1;
};
