export enum VideoStatus {
	Processing,
	Completed,
	Failed,
}

export interface Video {
	id: number;
	name: string;
	hash: string | null;
	videoStatus: VideoStatus;
	progress: number;
	folderId: number | null;
	guid: string;
	width: number | null;
	height: number | null;
	fps: number | null;
	projectId: number | null;
}

export default Video;
