import { AxiosPromise } from 'axios';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

const deleteProjectRequest = (token: string | null, projectId: number): AxiosPromise => {
	const url = getApiUrl(ApiUrl.ProjectsProject).replace(':projectId', projectId.toString());
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.delete(url);
};

export default deleteProjectRequest;
