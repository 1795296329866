import { AxiosPromise } from 'axios';
import Video from 'Models/Videos/Video';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export interface PatchVideoRequest {
	name?: string;
	folderId?: number | null;
	projectId?: number | null;
}

const patchVideoRequest = (token: string | null, data: PatchVideoRequest, videoId: number): AxiosPromise<Video> => {
	const url = getApiUrl(ApiUrl.VideosVideo).replace(':videoId', videoId.toString());
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.patch<Video>(url, data);
};

export default patchVideoRequest;
