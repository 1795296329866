import {
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import JiraTimeTextField from 'Components/Common/JiraTimeTextField';
import SelectBrands from 'Components/Common/SelectBrands';
import SelectMultiplePlacements from 'Components/Common/SelectMultiplePlacements';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import BrandGroup from 'Models/Brands/BrandGroup';
import Video from 'Models/Videos/Video';
import VideoDetectionPlacement from 'Models/Videos/VideoDetectionPlacement';
import { ChangeEvent, ReactElement, useState } from 'react';
import postCreateAndExportDefaultDetectionsRequest, {
	PostCreateAndExportDefaultDetectionsRequest,
} from 'Utils/Http/Requests/Videos/Detections/Export/PostCreateAndExportDefaultDetectionsRequest';
import postCreateAndExportSwissDetectionsRequest from 'Utils/Http/Requests/Videos/Detections/Export/PostCreateAndExportSwissDetectionsRequest';
import postCreateAndExportVisuaDetectionsRequest from 'Utils/Http/Requests/Videos/Detections/Export/PostCreateAndExportVisuaDetectionsRequest';

interface ReportExportWizardProps {
	isOpen: boolean;
	onClose: () => void;
	placements: VideoDetectionPlacement[];
	brandGroups: BrandGroup[];
	selectedVideoIds: number[];
	videos: Video[];
}

enum ExportType {
	DEFAULT,
	TAGS,
	WITHOUT_PLACEMENT,
}

enum ExcelType {
	VISUA,
	DEFAULT,
	SWISS,
}

const ReportExportWizard = (props: ReportExportWizardProps): ReactElement => {
	const [exportType, setExportType] = useState<ExportType>(ExportType.DEFAULT);
	const [selectedBrandIds, setSelectedBrandIds] = useState<number[]>([]);
	const [selectedPlacementIds, setSelectedPlacementIds] = useState<number[]>([]);
	const [excelType, setExcelType] = useState(ExcelType.DEFAULT);
	const [combineExcels, setCombineExcels] = useState(true);
	const [startTime, setStartTime] = useState<number | null>(null);
	const [endTime, setEndTime] = useState<number | null>(null);
	const [separateText, setSeparateText] = useState(false);

	const token = useAppSelector<string | null>((state) => state.profile.token);
	const { dispatchStartLoading, dispatchStopLoading } = useLoadingDispatch();
	const { dispatchSetAxiosErrorMessage } = useMessagesDispatch();

	const handleExportTagSwitchChanged = (): void => {
		alert('Not yet implemented!');
	};

	const handleExportWithoutPlacementSwitchChanged = ({ target }: ChangeEvent<HTMLInputElement>): void => {
		setExportType(target.checked ? ExportType.WITHOUT_PLACEMENT : ExportType.DEFAULT);
	};

	const handleCombineExcelCheckboxClicked = ({ target }: ChangeEvent<HTMLInputElement>): void => {
		setCombineExcels(target.checked);
	};

	const handleSeparateTextDetectionCheckboxClicked = ({ target }: ChangeEvent<HTMLInputElement>): void => {
		setSeparateText(target.checked);
	};

	const handleBrandsSelected = (value: number | number[]): void => {
		if (Array.isArray(value)) {
			setSelectedBrandIds(value);
		}
	};

	const handlePlacementsSelected = setSelectedPlacementIds;

	const handleExcelTypeSelectClicked = ({ target }: ChangeEvent<{ value: unknown }>): void => {
		if (target.value === ExcelType.VISUA || target.value === ExcelType.DEFAULT || target.value === ExcelType.SWISS)
			setExcelType(target.value);
	};

	const getFilename = (videoIds: number[]): string => {
		if (videoIds.length === 1) {
			const video = props.videos.find((x) => x.id === videoIds[0]);
			if (video) return `${video.name}.xlsx`; // Some videos have .mp4 ending or something. with that we override that.
		}
		return 'Multisheet';
	};

	const downloadExcel: (videoIds: number[]) => void = async (videoIds) => {
		const request: PostCreateAndExportDefaultDetectionsRequest = {
			brandGroupIds: selectedBrandIds,
			placementIds: selectedPlacementIds,
			videoIds: videoIds,
			withoutPlacement: exportType === ExportType.WITHOUT_PLACEMENT,
			separateText: separateText,
			startTime: startTime,
			endTime: endTime,
		};

		const filename = getFilename(videoIds);
		switch (excelType) {
			case ExcelType.VISUA:
				await postCreateAndExportVisuaDetectionsRequest(token, request, filename);
				break;
			case ExcelType.DEFAULT:
				await postCreateAndExportDefaultDetectionsRequest(token, request, filename);
				break;
			case ExcelType.SWISS:
				await postCreateAndExportSwissDetectionsRequest(token, request, filename);
				break;
			default:
				throw new Error('Unknown excel type selected.');
		}
	};

	const handleStartTimeChanged = (value: number | null) => {
		setStartTime(value);
	};

	const handleEndTimeChanged = (value: number | null) => {
		setEndTime(value);
	};

	const handleDownloadClick: () => void = async () => {
		dispatchStartLoading('Exporting detections');
		try {
			if (combineExcels) await downloadExcel(props.selectedVideoIds);
			else {
				for (let i = 0; i < props.selectedVideoIds.length; i++) {
					await downloadExcel([props.selectedVideoIds[i]]);
				}
			}
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		} finally {
			dispatchStopLoading();
		}
	};

	const renderSelections = (): JSX.Element => {
		if (exportType === ExportType.TAGS) {
			return <>Not Implemented!</>;
		}
		return (
			<>
				<SelectBrands
					brands={props.brandGroups}
					id="detection-wizard-brands"
					onChange={handleBrandsSelected}
					value={selectedBrandIds}
					multiple={true}
				/>
				<SelectMultiplePlacements
					id="detection-wizard-placements"
					selectedPlacementIds={selectedPlacementIds}
					onChange={handlePlacementsSelected}
					placements={props.placements}
				/>
			</>
		);
	};

	return (
		<Dialog open={props.isOpen} onClose={props.onClose} fullWidth={true}>
			<DialogTitle>Export detection report</DialogTitle>
			<DialogContent>
				<FormControl fullWidth={true} margin="normal">
					<FormControlLabel
						control={
							<Checkbox
								checked={exportType === ExportType.TAGS}
								onChange={handleExportTagSwitchChanged}
								name="checkedExportTags"
								color="primary"
							/>
						}
						label="Export with tags"
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={exportType === ExportType.WITHOUT_PLACEMENT}
								onChange={handleExportWithoutPlacementSwitchChanged}
								name="checkedWithoutPlacement"
								color="primary"
							/>
						}
						label="Export without placement"
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={combineExcels}
								onChange={handleCombineExcelCheckboxClicked}
								name="combineExcelsCheckbox"
								color="primary"
							/>
						}
						label="Combine reports into single excel"
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={separateText}
								onChange={handleSeparateTextDetectionCheckboxClicked}
								name="separateTextDetections"
								color="primary"
							/>
						}
						label="Separate text detections"
					/>
				</FormControl>
				<FormControl fullWidth={true} margin="normal">
					<InputLabel id="export-type">Export type</InputLabel>
					<Select labelId="export-type" id="export-type" value={excelType} onChange={handleExcelTypeSelectClicked}>
						<MenuItem value={ExcelType.DEFAULT}>Default</MenuItem>
						<MenuItem value={ExcelType.VISUA}>Visua</MenuItem>
						<MenuItem value={ExcelType.SWISS}>Swiss</MenuItem>
					</Select>
				</FormControl>
				{renderSelections()}
				<FormControl fullWidth={true} margin="normal">
					<JiraTimeTextField label="Time from" onChange={handleStartTimeChanged} fullWidth={true} />
				</FormControl>
				<FormControl fullWidth={true}>
					<JiraTimeTextField label={'Time to'} onChange={handleEndTimeChanged} fullWidth={true} />
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button type="button" color="primary" variant="contained" onClick={handleDownloadClick}>
					Download
				</Button>
				<Button type="button" variant="text" onClick={props.onClose}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ReportExportWizard;
