import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Skeleton } from '@material-ui/lab';
import useCommonClasses from 'Hooks/Styles/useCommonClasses';
import { ReactElement, useCallback, useMemo } from 'react';

interface BrandGroupCardsSkeletonProps {
	elementCount: number;
	rectHeight: number;
	textWidth: number;
}

const ItemCardsSkeleton = (props: BrandGroupCardsSkeletonProps): ReactElement => {
	const commonClasses = useCommonClasses();

	const factory = useCallback((): JSX.Element[] => {
		const skeleton: JSX.Element[] = [];
		for (let i = 0; i < props.elementCount; i++) {
			skeleton.push(
				<Grid item={true} xs={12} sm={6} md={4} lg={3} xl={2} key={i}>
					<Card className={commonClasses.cardBackground}>
						<CardContent>
							<Skeleton variant="rect" height={props.rectHeight} />
							<Skeleton variant="text" width={props.textWidth} height={24} />
						</CardContent>
					</Card>
				</Grid>
			);
		}
		return skeleton;
	}, [commonClasses.cardBackground, props.elementCount, props.rectHeight, props.textWidth]);

	const skeletons = useMemo(factory, [factory]);
	return (
		<Grid container={true} spacing={2}>
			{skeletons}
		</Grid>
	);
};

export default ItemCardsSkeleton;
