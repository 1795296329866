import makeStyles from '@material-ui/core/styles/makeStyles';
import { EditorMode, UseVideoPlayerInstance } from 'Hooks/Editor/useVideoPlayerInstance';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { globalPointToCanvas } from 'Utils/DetectionHelpers';
import { isExpectedKey } from 'Utils/Keys';

interface NewDetectionDrawerCanvasProps {
	scale: number;
	instance: UseVideoPlayerInstance;
}

const useStyles = makeStyles(() => ({
	drawingModeOverlay: {
		zIndex: 5,
		position: 'fixed',
		padding: 0,
		margin: 0,
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		background: '#000000',
		opacity: 0.8,
	},
	newDetectionsWrapper: {
		position: 'fixed',
		width: '100%',
		height: '100%',
		top: 0,
		left: 0,
		zIndex: 300,
	},
	newDetectionsCanvas: {
		position: 'relative',
	},
}));

const NewDetectionDrawerCanvas = ({ scale, instance }: NewDetectionDrawerCanvasProps): ReactElement | null => {
	const [canvasSize, setCanvasSizes] = useState<[number, number]>([0, 0]);
	const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
	const [mouseClickPos, setMouseClickPos] = useState<[number, number] | null>(null);
	const canvasContext2DRef = useRef<CanvasRenderingContext2D | null>(null);

	const classes = useStyles();

	const setCanvasContext = (canvas: HTMLCanvasElement | null): void => {
		setCanvas(canvas);
		if (canvas) canvasContext2DRef.current = canvas.getContext('2d');
	};

	const clearNewDetectionState = useCallback(() => {
		setMouseClickPos(null);
	}, []);

	useEffect(
		// Effect sets thew height and width of the canvas
		() => {
			function updateCanvasSizes() {
				setCanvasSizes([window.innerWidth, window.innerHeight]);
			}

			updateCanvasSizes();

			window.addEventListener('resize', updateCanvasSizes);
			return () => window.removeEventListener('resize', updateCanvasSizes);
		},
		[]
	);

	useEffect(
		// The effect listens for ESC key and cancels the input.
		() => {
			if (instance.editorMode !== EditorMode.NEW_DETECTION) return;

			const handleKeyPress = (event: KeyboardEvent) => {
				console.log('event!', event);
				if (!isExpectedKey(event, 'Escape')) return;

				clearNewDetectionState();
			};

			document.addEventListener('keyup', handleKeyPress);
			return () => document.removeEventListener('keyup', handleKeyPress);
		},
		[clearNewDetectionState, instance.editorMode]
	);

	// The effect handles clicks on canvas.
	useEffect(() => {
		if (instance.editorMode !== EditorMode.NEW_DETECTION || canvas === null) return;

		const handleMouseDown = ({ pageX, pageY }: MouseEvent) => {
			const { x, y } = globalPointToCanvas(canvas, pageX, pageY);
			setMouseClickPos([x, y]);
		};

		canvas.addEventListener('mousedown', handleMouseDown);
		return () => canvas.removeEventListener('mousedown', handleMouseDown);
	}, [canvas, instance.editorMode]);

	// The effect listens for release of mouse on canvas. This will create new detection in the editor instance.
	useEffect(() => {
		if (instance.editorMode !== EditorMode.NEW_DETECTION || canvas === null || mouseClickPos == null) return;

		const handleMouseUp = ({ pageX, pageY }: MouseEvent): void => {
			const { x, y } = globalPointToCanvas(canvas, pageX, pageY);
			instance.addNewDetection(mouseClickPos, [x, y], scale);
			instance.setEditorMode(EditorMode.NORMAL);
			setMouseClickPos(null);
		};
		canvas.addEventListener('mouseup', handleMouseUp);
		return () => canvas.removeEventListener('mouseup', handleMouseUp);
	}, [canvas, instance, mouseClickPos, scale, instance.addNewDetection]);

	// This effect is used to draw the square for new detection
	useEffect(() => {
		if (instance.editorMode !== EditorMode.NEW_DETECTION || canvas === null || mouseClickPos == null) return;

		const ctx = canvasContext2DRef.current;
		if (ctx === null) return;

		const drawNewDetection = ({ pageX, pageY }: MouseEvent): void => {
			ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
			ctx.save();
			ctx.lineWidth = 3;
			ctx.strokeStyle = '#ff0000';
			ctx.beginPath();
			ctx.moveTo(mouseClickPos[0], mouseClickPos[1]);
			ctx.lineTo(pageX, mouseClickPos[1]);
			ctx.lineTo(pageX, pageY);
			ctx.lineTo(mouseClickPos[0], pageY);
			ctx.lineTo(mouseClickPos[0], mouseClickPos[1]);

			ctx.stroke();
			ctx.restore();
		};

		canvas.addEventListener('mousemove', drawNewDetection);
		return () => canvas.removeEventListener('mousemove', drawNewDetection);
	}, [canvas, instance.editorMode, mouseClickPos]);

	if (instance.editorMode !== EditorMode.NEW_DETECTION) return null;

	return (
		<>
			<div className={classes.newDetectionsWrapper}>
				<canvas
					width={canvasSize[0]}
					height={canvasSize[1]}
					ref={setCanvasContext}
					className={classes.newDetectionsCanvas}
				/>
			</div>
		</>
	);
};

export default NewDetectionDrawerCanvas;
