export const secondsToHHMMSS = (seconds: number, miliseconds: boolean = false): string => {
	const hours = Math.floor(seconds / 3600);
	seconds %= 3600;
	const minutes = Math.floor(seconds / 60);
	seconds %= 60;
	let secondsAsString: string = miliseconds ? seconds.toFixed(3) : Math.floor(seconds).toString();
	if (hours > 0) {
		return `${hours}:${padNumber(minutes, 2)}:${padNumber(secondsAsString, 2)}`;
	}
	return `${padNumber(minutes, 2)}:${padNumber(secondsAsString, 2)}`;
};

export const secondsToHHMMSSWithFrameCount = (totalSeconds: number, fps: number): string => {
	const timeString = secondsToHHMMSS(totalSeconds);
	const miliseconds = (totalSeconds - Math.floor(totalSeconds)) * 1000;
	const frame = Math.round((fps * miliseconds) / 1000);
	return `${timeString}.${frame}`;
};

export const padNumber = (number: string | number, size: number): string => {
	let s = number.toString();
	while (s.length < (size || 2)) {
		s = '0' + s;
	}
	return s;
};
