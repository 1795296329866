import { AxiosPromise } from 'axios';
import BrandGroup from 'Models/Brands/BrandGroup';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export type PostMergeBrandGroupResponse = BrandGroup;
export type PostMergeBrandGroupRequest = (
	token: string | null,
	name: string,
	groupIds: number[]
) => AxiosPromise<PostMergeBrandGroupResponse>;

const postBrandGroupRequest: PostMergeBrandGroupRequest = async (token, name, groupIds) => {
	const url = getApiUrl(ApiUrl.BrandGroupsMerge);
	const body = { name, groupIds };
	const axios = getAxiosInstance(token);
	return axios.post<PostMergeBrandGroupResponse>(url, body);
};

export default postBrandGroupRequest;
