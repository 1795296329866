import { getBaseApiUrl } from 'Utils/Http/EnvReader';

export enum ApiUrl {
	User = 'user',
	Login = 'user/login',
	Logout = 'user/logout',

	Brands = 'brands',
	BrandsBrand = 'brands/:brandId',
	BrandsBrandDeactivate = 'brands/:brandId/deactivate',

	BrandGroups = 'brands/groups',
	BrandGroupsGroup = 'brands/groups/:groupId',
	BrandGroupsMerge = 'brands/groups/merge',
	BrandGroupsSplit = 'brands/groups/split',

	BrandActivations = 'brands/activations',
	BrandActivationsUpdate = 'brands/activations/:brandActivationId/update',

	Folders = 'folders',
	FoldersFolder = 'folders/:folderId',
	FolderContent = 'folders/:folderId/content',

	Videos = 'videos',
	VideosVideo = 'videos/:videoId',
	VideosVideoRefresh = 'videos/:videoId/refresh',
	VideosVideoProcessing = 'videos/processing',
	VideosVideoDetections = 'videos/:videoId/detections',
	VideosVideoDetectionsNew = 'videos/:videoId/detections/new',
	VideosVideoDetectionsFrames = 'videos/:videoId/detections/frames',
	VideosVideoDetectionsEditor = 'videos/:videoId/detections/editor',
	VideosVideoDetectionsEditorChangeAllPlacements = 'videos/:videoId/detections/changeAllPlacements',
	VideosVideoDetectionsDetection = 'videos/:videoId/detections/:detectionId',
	VideosVideoDetectionsDetectionFeedback = 'videos/:videoId/detections/:detectionId/feedback',
	VideosVideoMedia = 'videos/media/:videoGuid',
	VideosDetectionsExportDefault = 'videos/detections/export/default',
	VideosDetectionsExportSwiss = 'videos/detections/export/swiss',
	VideosDetectionsExportVisua = 'videos/detections/export/Visua',
	VideosProjects = 'videos/projects',

	Placements = 'placements',
	PlacementsPlacement = 'placements/:placementId',

	Projects = 'projects',
	ProjectsProject = 'projects/:projectId',

	Space = 'space',
}

export const getApiUrl = (path: ApiUrl): string => {
	const baseUrl = getBaseApiUrl();
	return `${baseUrl}/api/${path}`;
};
