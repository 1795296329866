import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import BrandGroup from 'Models/Brands/BrandGroup';
import * as React from 'react';
import { ReactNode, useMemo } from 'react';

interface SelectBrandsProps {
	brands: BrandGroup[];
	value?: number[] | number;
	id: string;
	onChange: (value: number[] | number) => void;
	multiple?: boolean;
}

const SelectBrands: React.FC<SelectBrandsProps> = ({ onChange, brands, value, id, multiple }) => {
	const sortedBrands = useMemo(() => brands.sort((a, b) => a.name.localeCompare(b.name)), [brands]);

	const handleBrandSelected = ({ target }: React.ChangeEvent<{ value: unknown }>) => {
		if (multiple) {
			if (Array.isArray(target.value)) {
				const values = target.value.map((v) => Number(v)).filter((v) => !isNaN(v));
				onChange(values);
			}
		} else {
			const value = Number(target.value);
			if (!isNaN(value)) {
				onChange(value);
			}
		}
	};

	const renderValue = (selected: any): ReactNode => {
		if (!multiple) {
			const brand = brands.find((b) => b.id === selected);
			return brand && brand.name;
		}

		const brandNames = (selected as number[]).map((s) => {
			const selectedBrand = brands.find((b) => b.id === s);
			return selectedBrand && selectedBrand.name;
		});
		return brandNames.join(', ');
	};

	const renderItem = (brand: BrandGroup): JSX.Element => {
		if (multiple && Array.isArray(value)) {
			return (
				<MenuItem value={brand.id} key={brand.id}>
					<Checkbox checked={!!value && value.indexOf(brand.id) > -1} />
					<ListItemText primary={brand.name} />
				</MenuItem>
			);
		}

		return (
			<MenuItem value={brand.id} key={brand.id}>
				{brand.name}
			</MenuItem>
		);
	};

	return (
		<FormControl fullWidth={true} margin="normal">
			<InputLabel id={`${id}-label`}>Brand</InputLabel>
			<Select
				labelId={`${id}-label`}
				id={id}
				value={value}
				onChange={handleBrandSelected}
				multiple={multiple}
				renderValue={renderValue}
			>
				{sortedBrands.map(renderItem)}
			</Select>
		</FormControl>
	);
};

export default SelectBrands;
