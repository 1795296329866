import { AxiosPromise } from 'axios';
import BrandGroup from 'Models/Brands/BrandGroup';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export interface PutBrandGroupRequest {
	name: string;
}

const putBrandGroupRequest = (
	token: string | null,
	brandGroupId: number,
	request: PutBrandGroupRequest
): AxiosPromise<BrandGroup> => {
	const url = getApiUrl(ApiUrl.BrandGroupsGroup).replace(':groupId', brandGroupId.toString());
	const axios = getAxiosInstance(token);
	return axios.put(url, request);
};

export default putBrandGroupRequest;
