import { AxiosPromise } from 'axios';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

const deleteVideoDetectionRequest = (
	token: string | null,
	videoId: number,
	detectionId: number
): AxiosPromise<void> => {
	const url = getApiUrl(ApiUrl.VideosVideoDetectionsDetection)
		.replace(':videoId', videoId.toString())
		.replace(':detectionId', detectionId.toString());
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.delete(url);
};

export default deleteVideoDetectionRequest;
