import { createStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CancelIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';
import { ReactElement } from 'react';

interface CancelIconButtonProps {
	onClick: () => void;
	className?: string;
	disabled?: boolean;
}

const useClasses = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			color: theme.palette.error.dark,
		},
	})
);

const CancelIconButton = ({ onClick, className, disabled }: CancelIconButtonProps): ReactElement => {
	const classes = useClasses();
	const buttonClasses = classNames([classes.root, className]);

	return (
		<IconButton onClick={onClick} className={buttonClasses} disabled={disabled}>
			<CancelIcon />
		</IconButton>
	);
};

export default CancelIconButton;
