import downloadBlob from 'Utils/DownloadBlob';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export interface PostCreateAndExportDefaultDetectionsRequest {
	videoIds: number[];
	brandGroupIds: number[];
	placementIds: number[];
	withoutPlacement: boolean;
	separateText: boolean;
	startTime: number | null;
	endTime: number | null;
}

const postCreateAndExportDefaultDetectionsRequest: (
	token: string | null,
	request: PostCreateAndExportDefaultDetectionsRequest,
	filename: string
) => Promise<void> = async (token, request, filename) => {
	const url = getApiUrl(ApiUrl.VideosDetectionsExportDefault);
	const axiosInstance = getAxiosInstance(token);
	const response = await axiosInstance.post<Blob>(url, request, { responseType: 'blob' });
	downloadBlob(response.data, { filename });
};

export default postCreateAndExportDefaultDetectionsRequest;
