import { AxiosPromise } from 'axios';
import VideoFrameDetection from 'Models/Videos/Detections/VideoFrameDetection';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export interface FrameDetectionsPerFrame {
	[frame: number]: VideoFrameDetection[];
}

export interface GetFrameDetectionsResponse {
	frameDetectionsPerFrame: FrameDetectionsPerFrame;
	startTimestampMs: number;
	endTimestampMs: number;
}

const getFrameDetectionRequest = (
	token: string | null,
	videoId: number,
	fromTimestampMs: number,
	toTimestampMs: number
): AxiosPromise<GetFrameDetectionsResponse> => {
	const url = getApiUrl(ApiUrl.VideosVideoDetectionsFrames).replace(':videoId', videoId.toString());
	const axiosInstance = getAxiosInstance(token);
	const params = { fromTimestampMs, toTimestampMs };
	return axiosInstance.get<GetFrameDetectionsResponse>(url, { params });
};

export default getFrameDetectionRequest;
