const DEFAULT_VERTEX_CLICK_ERROR_MARGIN = 20;
const DEFAULT_EDGE_CLICK_ERROR_MARGIN = 20;
const DEFAULT_TRANSFORM_DETECTION_TIME_ERROR_MARGIN = 20;

export const getBaseApiUrl = (): string => {
	const apiUrl = process.env.REACT_APP_API_URL;
	if (!apiUrl) throw new Error('REACT_APP_API_URL is not set.');
	return apiUrl ?? '';
};

const getGenericNumberValue = (defaultValue: number, envValue?: string): number => {
	if (!envValue) return defaultValue;
	const intValue = parseInt(envValue);
	if (isNaN(intValue)) return defaultValue;
	return intValue;
};

export const getVertexClickErrorMargin = (): number => {
	return getGenericNumberValue(DEFAULT_VERTEX_CLICK_ERROR_MARGIN, process.env.REACT_APP_EDGE_CLICK_ERROR_MARGIN);
};

export const getEdgeClickErrorMargin = (): number => {
	return getGenericNumberValue(DEFAULT_EDGE_CLICK_ERROR_MARGIN, process.env.REACT_APP_VERTEX_CLICK_ERROR_MARGIN);
};

export const getTransformDetectionTimeErrorMargin = (): number => {
	return getGenericNumberValue(
		DEFAULT_TRANSFORM_DETECTION_TIME_ERROR_MARGIN,
		process.env.TRANSFORM_DETECTION_TIME_ERROR_MARGIN
	);
};
