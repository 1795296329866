import { Organization } from 'Models/Organization';

export enum UserClaimFlag {
	BrandRead = 1 << 0,
	BrandWrite = 1 << 1,
	BrandDelete = 1 << 2,
	VideoRead = 1 << 3,
	VideoWrite = 1 << 4,
	VideoDelete = 1 << 5,
}

export interface User {
	name: string;
	email: string;
	organization: Organization;
	claimFlag: UserClaimFlag;
}

export default User;
