import { fade } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useDrawDetections from 'Hooks/Editor/useDrawDetections';
import useSelectDetections from 'Hooks/Editor/useSelectDetections';
import { UseVideoPlayerInstance } from 'Hooks/Editor/useVideoPlayerInstance';
import { ReactElement, useRef } from 'react';

interface VideoDetectionsProps {
	width: number;
	height: number;
	scale: number;
	instance: UseVideoPlayerInstance;
}

const useStyles = makeStyles(({ zIndex, palette }: Theme) =>
	createStyles({
		bufferOverlay: {
			zIndex: zIndex.modal,
			background: fade(palette.grey.A400, 0.4),
			width: '100%',
			height: '100%',
			position: 'fixed',
			top: '0px',
			left: '0px',
			'& > div': {
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				textAlign: 'center',
				color: palette.text.secondary,
			},
		},
		canvas: {
			position: 'absolute',
		},
		selectionCanvas: {
			position: 'absolute',
			zIndex: 20,
		},
	})
);

const VideoDetections = (props: VideoDetectionsProps): ReactElement => {
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const canvasContextRef = useRef<CanvasRenderingContext2D | null>(null);
	const selectionBoxCanvasRef = useRef<HTMLCanvasElement | null>(null);

	const classes = useStyles();
	useDrawDetections(props.instance, canvasContextRef.current, props.scale);
	useSelectDetections(props.instance, selectionBoxCanvasRef.current, props.scale);

	const setCanvasRef = (ref: HTMLCanvasElement | null) => {
		canvasRef.current = ref;
		canvasContextRef.current = !!ref ? ref.getContext('2d') : null;
	};

	return (
		<>
			<canvas width={props.width} height={props.height} ref={setCanvasRef} className={classes.canvas} />
			<canvas
				width={props.width}
				height={props.height}
				ref={selectionBoxCanvasRef}
				className={classes.selectionCanvas}
			/>
		</>
	);
};

export default VideoDetections;
