import { useTheme } from '@material-ui/core';
import { EditorMode, UseVideoPlayerInstance } from 'Hooks/Editor/useVideoPlayerInstance';
import { EditorVideoNewDetectionWithNullableIds } from 'Models/Videos/Detections/EditorVideoNewDetection';
import VideoDetectionCoordinate from 'Models/Videos/Detections/VideoDetectionCoordinate';
import VideoFrameDetection from 'Models/Videos/Detections/VideoFrameDetection';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
	getCurrentPointFromNewDetectionCoordinates,
	getIdxForClickOnNewDetectionEdge,
	getIdxForClickOnNewDetectionVertex,
	getIdxOfClosestPoint,
	getInterpolatedPointsFromCoordinates,
	globalPointToCanvas,
	isFilteredDetection,
	isPointInCoordinate,
	scaleDetections,
	scaleNewDetections,
} from 'Utils/DetectionHelpers';
import { getEdgeClickErrorMargin, getVertexClickErrorMargin } from 'Utils/Http/EnvReader';
import { isExpectedKey } from 'Utils/Keys';

enum DetectionHandleType {
	TRANSLATE,
	RESIZE,
	SELECT,
}

const useSelectDetections = (
	instance: UseVideoPlayerInstance,
	canvas: HTMLCanvasElement | null,
	scale: number
): void => {
	const {
		videoNode,
		isVideoPlaying,
		toggleVideoPlay,
		selectDetectionIds,
		editorMode,
		getCurrentFrameDetections,
		selectNewDetections,
		newDetections,
	} = instance;

	const [isCtrlDown, setIsCtrlDown] = useState(false);
	const [transformedNewDetectionIdx, setTransformedNewDetectionIdx] = useState<number | null>(null);
	const [transformedNewDetection, setTransformedNewDetection] = useState<EditorVideoNewDetectionWithNullableIds | null>(
		null
	);

	const mouseDownPositionRef = useRef<[number, number] | null>(null);
	const initialMouseDownPositionRef = useRef<[number, number] | null>(null);
	const mouseDownHandleTypeRef = useRef<DetectionHandleType | null>(null);
	const wasPlaying = useRef(false);

	const drawContextRef = useRef<CanvasRenderingContext2D | null>(null);

	const theme = useTheme();

	const clearCanvas = useCallback(() => {
		const ctx = drawContextRef.current;
		if (!ctx) return;

		const { width, height } = ctx.canvas;
		ctx.clearRect(0, 0, width, height);
	}, [drawContextRef]);

	const getDetectionAtCoords = useCallback(
		(x: number, y: number, detectionsOnFrame: VideoFrameDetection[]): VideoFrameDetection | null => {
			for (let i = 0; i < detectionsOnFrame.length; i++) {
				const d = detectionsOnFrame[i];
				if (isFilteredDetection(d, instance.filteredBrandIds, instance.filteredPlacementIds)) continue; // Don't even attempt to calculate if it's filtered out.
				if (isPointInCoordinate(x, y, d.coordinate)) return d;
			}
			return null;
		},
		[instance.filteredBrandIds, instance.filteredPlacementIds]
	);

	const getNewDetectionIdxAtCoords = useCallback(
		(
			x: number,
			y: number,
			newDetections: EditorVideoNewDetectionWithNullableIds[],
			currentTime: number
		): number | null => {
			for (let i = 0; i < newDetections.length; i++) {
				const d = newDetections[i];
				const points = getInterpolatedPointsFromCoordinates(d.coordinates, currentTime, instance.fps);
				if (isPointInCoordinate(x, y, points)) return i;
			}
			return null;
		},
		[instance.fps]
	);

	const handleMouseDown = useCallback(
		(canvas: HTMLCanvasElement, event: MouseEvent): void => {
			const { pageX, pageY } = event;
			const { x, y } = globalPointToCanvas(canvas, pageX, pageY);

			mouseDownPositionRef.current = [x, y];
			initialMouseDownPositionRef.current = [x, y];
			if (videoNode !== null) {
				const vertexErrorMargin = getVertexClickErrorMargin();
				const newDetectionIdxVertexClicked = getIdxForClickOnNewDetectionVertex(
					x,
					y,
					newDetections,
					videoNode.currentTime,
					vertexErrorMargin,
					scale
				);
				if (newDetectionIdxVertexClicked !== null) {
					setTransformedNewDetectionIdx(newDetectionIdxVertexClicked);
					setTransformedNewDetection(newDetections[newDetectionIdxVertexClicked]);
					mouseDownHandleTypeRef.current = DetectionHandleType.RESIZE;
				} else {
					const edgeErrorMargin = getEdgeClickErrorMargin();
					const newDetectionIdxEdgeClicked = getIdxForClickOnNewDetectionEdge(
						x,
						y,
						newDetections,
						videoNode.currentTime,
						edgeErrorMargin,
						scale
					);
					if (newDetectionIdxEdgeClicked !== null) {
						setTransformedNewDetectionIdx(newDetectionIdxEdgeClicked);
						setTransformedNewDetection(newDetections[newDetectionIdxEdgeClicked]);
						mouseDownHandleTypeRef.current = DetectionHandleType.TRANSLATE;
					} else {
						mouseDownHandleTypeRef.current = DetectionHandleType.SELECT;
					}
				}
			} else {
				mouseDownHandleTypeRef.current = DetectionHandleType.SELECT;
			}

			// Pause video if it is playing.
			wasPlaying.current = isVideoPlaying;
			if (!!videoNode && isVideoPlaying) {
				toggleVideoPlay(false);
			}
		},
		[newDetections, isVideoPlaying, scale, toggleVideoPlay, videoNode]
	);

	const handleMouseMove = useCallback(
		(event: MouseEvent): void => {
			const ctx = drawContextRef.current;
			if (ctx === null || mouseDownPositionRef.current === null) return;

			const { pageX, pageY } = event;
			const { x, y } = globalPointToCanvas(ctx.canvas, pageX, pageY);
			mouseDownPositionRef.current = [x, y];

			// only draw if selection mode is set
			if (mouseDownHandleTypeRef.current !== DetectionHandleType.SELECT) return;

			clearCanvas();
			ctx.save();
			ctx.lineWidth = 2;
			ctx.strokeStyle = theme.palette.primary.light;

			const [startX, startY] = initialMouseDownPositionRef.current ?? [0, 0];
			const w = x - startX;
			const h = y - startY;

			ctx.beginPath();
			ctx.strokeRect(startX, startY, w, h);
			ctx.globalAlpha = 0.2;
			ctx.fillRect(startX, startY, w, h);
			ctx.closePath();

			ctx.stroke();
			ctx.restore();
		},
		[clearCanvas, drawContextRef, theme.palette.primary.light]
	);

	const selectDetections = useCallback(
		(x1: number, y1: number, x2: number, y2: number): boolean => {
			// Declare detection variables n such.
			const detectionsInFrame = getCurrentFrameDetections();
			let scaledDetectionsInFrame = scaleDetections(detectionsInFrame, scale);
			let scaledNewDetections = scaleNewDetections(newDetections, scale);
			const newlySelectedDetections: number[] = [];
			const newlySelectedNewDetectionsIdxes: number[] = [];

			// So it will always "read" from top left corner to bottom right corner.
			const yMin = Math.min(y1, y2);
			const yMax = Math.max(y1, y2);
			const xMin = Math.min(x1, x2);
			const xMax = Math.max(x1, x2);

			for (let y = yMin; y <= yMax; y++) {
				for (let x = xMin; x <= xMax; x++) {
					// For regular detections
					const d = getDetectionAtCoords(x, y, scaledDetectionsInFrame);
					if (d) {
						scaledDetectionsInFrame = scaledDetectionsInFrame.filter(
							(det) => det.videoDetectionId !== d.videoDetectionId
						);
						newlySelectedDetections.push(d.videoDetectionId);
					}
					// For new detections
					const ndIdx = getNewDetectionIdxAtCoords(x, y, scaledNewDetections, videoNode?.currentTime ?? 0);
					if (ndIdx !== null && !newlySelectedNewDetectionsIdxes.includes(ndIdx)) {
						newlySelectedNewDetectionsIdxes.push(ndIdx);
					}
				}
			}
			selectDetectionIds(newlySelectedDetections, isCtrlDown);
			selectNewDetections(newlySelectedNewDetectionsIdxes, isCtrlDown);
			return newlySelectedDetections.length > 0 || newlySelectedNewDetectionsIdxes.length > 0;
		},
		[
			getCurrentFrameDetections,
			scale,
			newDetections,
			selectDetectionIds,
			isCtrlDown,
			selectNewDetections,
			getDetectionAtCoords,
			getNewDetectionIdxAtCoords,
			videoNode?.currentTime,
		]
	);

	const getTranslatedPoints = useCallback(
		(newDetection: EditorVideoNewDetectionWithNullableIds, video: HTMLVideoElement): VideoDetectionCoordinate => {
			const coordinate = getCurrentPointFromNewDetectionCoordinates(newDetection.coordinates, video.currentTime)[0]
				.coordinate;
			if (mouseDownPositionRef.current === null || initialMouseDownPositionRef.current === null) return coordinate;

			const translatedX = mouseDownPositionRef.current[0] / scale - initialMouseDownPositionRef.current[0] / scale;
			const translatedY = mouseDownPositionRef.current[1] / scale - initialMouseDownPositionRef.current[1] / scale;

			return {
				point1: {
					x: coordinate.point1.x + translatedX,
					y: coordinate.point1.y + translatedY,
				},
				point2: {
					x: coordinate.point2.x + translatedX,
					y: coordinate.point2.y + translatedY,
				},
				point3: {
					x: coordinate.point3.x + translatedX,
					y: coordinate.point3.y + translatedY,
				},
				point4: {
					x: coordinate.point4.x + translatedX,
					y: coordinate.point4.y + translatedY,
				},
			};
		},
		[mouseDownPositionRef, initialMouseDownPositionRef, scale]
	);

	const getResizedPoints = useCallback(
		(newDetections: EditorVideoNewDetectionWithNullableIds, video: HTMLVideoElement): VideoDetectionCoordinate => {
			const coordinate = getCurrentPointFromNewDetectionCoordinates(newDetections.coordinates, video.currentTime)[0]
				.coordinate;
			if (mouseDownPositionRef.current === null || initialMouseDownPositionRef.current === null) return coordinate;

			const transformedPoints = { ...coordinate };

			const scaledX = initialMouseDownPositionRef.current[0] / scale;
			const scaledY = initialMouseDownPositionRef.current[1] / scale;
			const clickedIdx = getIdxOfClosestPoint(scaledX, scaledY, coordinate);

			const translatedX = mouseDownPositionRef.current[0] / scale - scaledX;
			const translatedY = mouseDownPositionRef.current[1] / scale - scaledY;

			switch (clickedIdx) {
				case 0:
					transformedPoints.point1.x += translatedX;
					transformedPoints.point1.y += translatedY;
					break;
				case 1:
					transformedPoints.point2.x += translatedX;
					transformedPoints.point2.y += translatedY;
					break;
				case 2:
					transformedPoints.point3.x += translatedX;
					transformedPoints.point3.y += translatedY;
					break;
				case 3:
					transformedPoints.point4.x += translatedX;
					transformedPoints.point4.y += translatedY;
					break;
			}
			return transformedPoints;
		},
		[mouseDownPositionRef, initialMouseDownPositionRef, scale]
	);

	const handleMouseUp = useCallback(
		(canvas: HTMLCanvasElement, event: MouseEvent) => {
			if (!mouseDownPositionRef.current) return;
			if (!videoNode) {
				console.warn('Could not find the video. It might not be initialized yet. Detection selection is ignored.');
				return;
			}
			// Get coordinates n such
			const { pageX, pageY } = event;
			const [x1, y1] = initialMouseDownPositionRef.current ?? [0, 0];
			const { x, y } = globalPointToCanvas(canvas, pageX, pageY);
			mouseDownPositionRef.current = [x, y];

			let changeOccurred = false;
			// Handle the data and invoke correct function.
			switch (mouseDownHandleTypeRef.current) {
				case DetectionHandleType.TRANSLATE:
					if (transformedNewDetectionIdx !== null && transformedNewDetection !== null) {
						instance.transformNewDetection(
							getTranslatedPoints(transformedNewDetection, videoNode),
							transformedNewDetectionIdx
						);
						changeOccurred = true;
					}
					break;

				case DetectionHandleType.RESIZE:
					if (transformedNewDetectionIdx !== null && transformedNewDetection !== null) {
						instance.transformNewDetection(
							getResizedPoints(transformedNewDetection, videoNode),
							transformedNewDetectionIdx
						);
						changeOccurred = true;
					}
					break;

				case DetectionHandleType.SELECT:
					changeOccurred = selectDetections(x1, y1, x, y);
					break;

				default:
					throw new Error(`Unknown HandleType for detection manipulations: ${mouseDownHandleTypeRef.current}`);
			}

			// clear draw data n such
			mouseDownPositionRef.current = null;
			clearCanvas();
			mouseDownHandleTypeRef.current = null;
			setTransformedNewDetectionIdx(null);
			setTransformedNewDetection(null);
			initialMouseDownPositionRef.current = null;

			// Resume video if no detections selected and was previously playing.
			if (wasPlaying.current && !changeOccurred) {
				toggleVideoPlay(true);
			}
		},
		[
			clearCanvas,
			getResizedPoints,
			getTranslatedPoints,
			instance,
			selectDetections,
			toggleVideoPlay,
			transformedNewDetection,
			transformedNewDetectionIdx,
			videoNode,
		]
	);

	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (isExpectedKey(event, 'Control') || isExpectedKey(event, 'Meta')) setIsCtrlDown(true);
		},
		[setIsCtrlDown]
	);

	const handleKeyUp = useCallback(
		(event: KeyboardEvent) => {
			if (isExpectedKey(event, 'Control') || isExpectedKey(event, 'Meta')) setIsCtrlDown(false);
		},
		[setIsCtrlDown]
	);

	useEffect(() => {
		// Only set event listeners if we want to.
		if (editorMode !== EditorMode.NORMAL) return;

		if (!canvas) return;

		function mouseDownFunc(this: HTMLCanvasElement, event: MouseEvent) {
			handleMouseDown(this, event);
		}

		function endDrag(this: HTMLCanvasElement, event: MouseEvent) {
			handleMouseUp(this, event);
		}

		canvas.addEventListener('mousedown', mouseDownFunc);
		canvas.addEventListener('mousemove', handleMouseMove);
		canvas.addEventListener('mouseleave', endDrag);
		canvas.addEventListener('mouseup', endDrag);
		return () => {
			canvas.removeEventListener('mousedown', mouseDownFunc);
			canvas.removeEventListener('mousemove', handleMouseMove);
			canvas.removeEventListener('mouseleave', endDrag);
			canvas.removeEventListener('mouseup', endDrag);
		};
	}, [canvas, handleMouseDown, handleMouseMove, handleMouseUp, editorMode]);

	useEffect(() => {
		// Only set event listeners if we want to.
		if (editorMode !== EditorMode.NORMAL) return;

		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('keyup', handleKeyUp);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('keyup', handleKeyUp);
		};
	}, [handleKeyDown, handleKeyUp, editorMode]);

	useEffect(
		// The effect sets context for this canvas used here.
		() => {
			if (canvas) {
				drawContextRef.current = canvas.getContext('2d');
			}
		},
		[canvas]
	);
};

export default useSelectDetections;
