import { AxiosPromise } from 'axios';
import VideoFolder from 'Models/Videos/VideoFolder';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export interface PostFolderRequest {
	name: string;
	parentFolderId: number | null;
	projectId: number | null;
}

const postFolderRequest = (token: string | null, folder: PostFolderRequest): AxiosPromise<VideoFolder> => {
	const url = getApiUrl(ApiUrl.Folders);
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.post(url, folder);
};

export default postFolderRequest;
