import { AxiosPromise } from 'axios';
import VideoDetectionPlacement from 'Models/Videos/VideoDetectionPlacement';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

const getVideoDetectionPlacements = (token: string | null): AxiosPromise<VideoDetectionPlacement[]> => {
	const url = getApiUrl(ApiUrl.Placements);
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.get<VideoDetectionPlacement[]>(url);
};

export default getVideoDetectionPlacements;
