import Grid from '@material-ui/core/Grid';
import DeleteVideoFolderModal from 'Components/Videos/Folders/Modals/DeleteVideoFolderModal';
import EditVideoFolderModal from 'Components/Videos/Folders/Modals/EditVideoFolderModal';
import VideoFolderCard from 'Components/Videos/Folders/VideoFolderCard';
import Project from 'Models/Project';
import VideoFolder from 'Models/Videos/VideoFolder';
import { ReactElement, useEffect, useState } from 'react';

interface FolderModalState {
	isOpen: boolean;
	folder: VideoFolder | null;
}

interface VideoFolderCardGridItemsProps {
	folders: VideoFolder[];
	onFolderDataChanged: (folders: VideoFolder[]) => void;
	currentFolderId: number | null;
	isCreateModalOpen: boolean;
	onEditModalClose: () => void;
	projects: Project[];
}

const folderModalInitialState: FolderModalState = {
	isOpen: false,
	folder: null,
};

const VideoFolderCardGridItems = (props: VideoFolderCardGridItemsProps): ReactElement => {
	const [deleteFolderModalState, setDeleteFolderModalState] = useState<FolderModalState>(folderModalInitialState);
	const [editFolderModalState, setEditFolderModalState] = useState<FolderModalState>(folderModalInitialState);

	useEffect(() => {
		if (props.isCreateModalOpen) {
			setEditFolderModalState({
				folder: null,
				isOpen: true,
			});
		}
	}, [props.isCreateModalOpen]);

	const handleFolderDeleteButtonClicked = (id: number): void => {
		const folder = props.folders.find((x) => x.id === id);
		if (!folder) {
			alert('Unknown error occured');
			return;
		}

		setDeleteFolderModalState({ isOpen: true, folder });
	};

	const handleFolderEditButtonClicked = (id: number): void => {
		const folder = props.folders?.find((x) => x.id === id);
		if (!folder) {
			alert('Unknown error occured');
			return;
		}

		setEditFolderModalState({ isOpen: true, folder });
	};

	const handleDeleteVideoFolderModalClose = () => {
		setDeleteFolderModalState((prev) => ({
			...prev,
			isOpen: false,
		}));
	};

	const handleFolderDeleted = (id: number): void => {
		props.onFolderDataChanged(props.folders.filter((x) => x.id !== id));
	};

	const handleEditVideoFolderModalClose = () => {
		props.onEditModalClose();
		setEditFolderModalState((prev) => ({
			...prev,
			isOpen: false,
		}));
	};

	const handleFolderEdited = (folder: VideoFolder): void => {
		const folderIndex = props.folders.findIndex((x) => x.id === folder.id);
		if (folderIndex < 0) return;

		if (folder.parentId !== props.folders[folderIndex].parentId) {
			props.onFolderDataChanged(props.folders.filter((x) => x.id !== folder.id));
		} else {
			const folders = [...props.folders];
			folders[folderIndex] = folder;
			props.onFolderDataChanged(folders);
		}
	};

	const handleFolderCreated = (folder: VideoFolder): void => {
		console.log(folder, props.currentFolderId);
		if (folder.parentId !== props.currentFolderId) return;
		props.onFolderDataChanged([folder, ...props.folders]);
	};

	return (
		<>
			{props.folders.map((x) => (
				<Grid item={true} xs={12} sm={6} md={4} lg={3} xl={2} key={x.id}>
					<VideoFolderCard
						name={x.name}
						id={x.id}
						onDeleteButtonClicked={handleFolderDeleteButtonClicked}
						onEditButtonClicked={handleFolderEditButtonClicked}
					/>
				</Grid>
			))}
			<DeleteVideoFolderModal
				isOpen={deleteFolderModalState.isOpen}
				onClose={handleDeleteVideoFolderModalClose}
				folder={deleteFolderModalState.folder ?? { name: '?', id: 0, parentId: null, projectId: null }}
				onFolderDeleted={handleFolderDeleted}
			/>
			<EditVideoFolderModal
				isOpen={editFolderModalState.isOpen}
				onClose={handleEditVideoFolderModalClose}
				folder={editFolderModalState.folder}
				onFolderEdited={handleFolderEdited}
				onFolderCreated={handleFolderCreated}
				projects={props.projects}
				currentFolderId={props.currentFolderId}
			/>
		</>
	);
};

export default VideoFolderCardGridItems;
