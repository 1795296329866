import Grid from '@material-ui/core/Grid';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PlayerUtilityControls from 'Components/Editor/VideoPlayer/PlayerControls/PlayerUtilityControls';
import PlayerVideoControls from 'Components/Editor/VideoPlayer/PlayerControls/PlayerVideoControls';
import { UseVideoPlayerInstance } from 'Hooks/Editor/useVideoPlayerInstance';
import { ReactElement } from 'react';

interface PlayerControlsProps {
	instance: UseVideoPlayerInstance;
}

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
	root: {
		'& > div': {
			marginLeft: spacing(1),
		},
		[breakpoints.up('md')]: {
			'& > div': {
				marginLeft: 0,
				marginBottom: spacing(1),
			},
			'& > div:last-child': {
				marginBottom: spacing(0),
			},
		},
	},
}));

const PlayerControls = ({ instance }: PlayerControlsProps): ReactElement => {
	const classes = useStyles();

	return (
		<Grid container className={classes.root}>
			<Grid item md={12} xs={6}>
				<PlayerVideoControls instance={instance} />
			</Grid>
			<Grid item md={12} xs={6}>
				<PlayerUtilityControls instance={instance} />
			</Grid>
		</Grid>
	);
};

export default PlayerControls;
