import { Fade } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import { ModalProps as MaterialModalProps, default as MaterialModal } from '@material-ui/core/Modal';
import useModalClasses from 'Hooks/Styles/Common/useModalClasses';
import { PropsWithChildren, ReactElement } from 'react';

export interface ModalProps
	extends Omit<MaterialModalProps, 'BackdropProps' | 'BackdropComponent' | 'closeAfterTransition' | 'children'> {
	onModalExited?: () => void;
}

const Modal = (props: PropsWithChildren<ModalProps>): ReactElement => {
	const classes = useModalClasses();

	return (
		<MaterialModal
			open={props.open}
			onClose={props.onClose}
			BackdropProps={{ timeout: 500, in: props.open }}
			closeAfterTransition={true}
			BackdropComponent={Backdrop}
		>
			<Fade in={props.open} onExited={props.onModalExited}>
				<div className={classes.modalBody}>{props.children}</div>
			</Fade>
		</MaterialModal>
	);
};

export default Modal;
