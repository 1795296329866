import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import SaveModal from 'Components/Common/Modals/SaveModal';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import VideoDetectionPlacement from 'Models/Videos/VideoDetectionPlacement';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import postVideoDetectionPlacement from 'Utils/Http/Requests/Videos/Placements/PostVideoDetectionPlacement';
import putVideoDetectionPlacement from 'Utils/Http/Requests/Videos/Placements/PutVideoDetectionPlacement';

interface EditPlacementModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSave: (isCreate: boolean, placement: VideoDetectionPlacement) => void;
	placement: VideoDetectionPlacement | null;
}

const EditPlacementModal = (props: EditPlacementModalProps): ReactElement => {
	const [name, setName] = useState(props.placement?.name ?? '');
	const [color, setColor] = useState(props.placement?.color ?? '#FF0000');

	const token = useAppSelector((state) => state.profile.token);
	const { dispatchSetAxiosErrorMessage, dispatchSetMessageAction } = useMessagesDispatch();

	// The effect will set name and color to the editing placement.
	useEffect(() => {
		setName(props.placement?.name ?? '');
		setColor(props.placement?.color ?? '');
	}, [props.placement?.color, props.placement?.name]);

	const createVideo: () => void = async () => {
		const data = { name, color };
		const response = await postVideoDetectionPlacement(token, data);
		dispatchSetMessageAction(`Created new placement ${name}.`, MessageStatusLevel.Success);
		props.onSave(true, response.data);
	};

	const updateVideo: () => void = async () => {
		if (props.placement === null) return;

		const data = { name, color };
		const response = await putVideoDetectionPlacement(token, props.placement.id, data);
		dispatchSetMessageAction(`Updated placement ${name}`, MessageStatusLevel.Success);
		props.onSave(false, response.data);
	};

	const handleSave: () => void = async () => {
		try {
			if (props.placement === null) await createVideo();
			else await updateVideo();
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		}
		props.onClose();
	};

	const handleNameInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
		setName(event.target.value);
	};

	const handleColorInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
		setColor(event.target.value);
	};

	return (
		<SaveModal onSave={handleSave} open={props.isOpen} onClose={props.onClose}>
			<Typography variant="h5">Create new placement.</Typography>
			<Divider />
			<List>
				<ListItem>
					<FormControl fullWidth={true}>
						<InputLabel required={true}>Name</InputLabel>
						<Input onChange={handleNameInputChanged} type="text" value={name} required={true} />
					</FormControl>
				</ListItem>
				<ListItem>
					<FormControl fullWidth={true}>
						<InputLabel>Localized name</InputLabel>
						<Input onChange={handleColorInputChanged} type="color" value={color} required={true} />
					</FormControl>
				</ListItem>
			</List>
		</SaveModal>
	);
};
export default EditPlacementModal;
