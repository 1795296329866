import { IconButton } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import GrowablePopper from 'Components/Common/GrowablePopper';
import useLogout from 'Hooks/Http/Profile/useLogout';
import useLayoutAppBarClasses from 'Hooks/Styles/Layout/useLayoutAppBarClasses';
import { FC, useRef, useState } from 'react';

interface LayoutAppBarProps {
	expanded: boolean;
	onExpandedChanged: (expanded: boolean) => void;
	drawerWidth: number;
}

const LayoutAppBar: FC<LayoutAppBarProps> = ({ children, expanded, onExpandedChanged, drawerWidth }) => {
	const [isProfilePopperOpen, setIsProfilePopperOpen] = useState(false);
	const profileButtonRef = useRef<HTMLButtonElement | null>(null);

	const doLogout = useLogout();

	const classes = useLayoutAppBarClasses({ drawerWidth });

	const appBarClasses = classNames({
		[classes.appBar]: true,
		[classes.appBarShift]: expanded,
	});

	const iconButtonClasses = classNames({
		[classes.menuButton]: true,
		[classes.menuButtonHidden]: expanded,
	});

	const handleExpandedIconClicked = (): void => {
		onExpandedChanged(!expanded);
	};

	const handleProfileMenuToggle = (): void => {
		setIsProfilePopperOpen((prevState) => !prevState);
	};

	const handlePopperClose = (): void => {
		setIsProfilePopperOpen(false);
	};

	const handleLogout = async (): Promise<void> => {
		await doLogout();
	};

	return (
		<AppBar position="fixed" className={appBarClasses}>
			<Toolbar disableGutters={!expanded} className={classes.toolbar}>
				<IconButton onClick={handleExpandedIconClicked} className={iconButtonClasses}>
					<MenuIcon />
				</IconButton>
				{children}
				<GrowablePopper
					open={isProfilePopperOpen}
					anchorEl={profileButtonRef.current}
					onClose={handlePopperClose}
					disablePortal={true}
				>
					<MenuList>
						<MenuItem onClick={handleLogout}>Logout</MenuItem>
					</MenuList>
				</GrowablePopper>
				{/* TODO: Show user data n such or something. I guess not needed yet. */}
				<IconButton color="inherit" onClick={handleProfileMenuToggle} ref={profileButtonRef}>
					<AccountCircleIcon />
				</IconButton>
			</Toolbar>
		</AppBar>
	);
};

export default LayoutAppBar;
