import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import classNames from 'classnames';
import BrandEditModal from 'Components/Brand/BrandEditModal';
import BrandGroupNameTextField from 'Components/Brand/BrandGroupNameTextField';
import ClaimPermissionComponent from 'Components/Common/ClaimPermissionComponent';
import ConfirmModal from 'Components/Common/Modals/ConfirmModal';
import Modal from 'Components/Common/Modals/Modal';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import useCommonClasses from 'Hooks/Styles/useCommonClasses';
import Brand, { BrandStatus } from 'Models/Brands/Brand';
import BrandGroup from 'Models/Brands/BrandGroup';
import { UserClaimFlag } from 'Models/User';
import { Fragment, ReactElement, useEffect, useMemo, useState } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import deleteBrandRequest from 'Utils/Http/Requests/Brands/DeleteBrandRequest';
import postSplitBrandGroupRequest from 'Utils/Http/Requests/Brands/PostSplitBrandGroupRequest';
import putDeactivateBrandRequest from 'Utils/Http/Requests/Brands/PutDeactivateBrandRequest';

interface ConfirmModalState {
	isOpen: boolean;
	brand: Brand | null;
}

interface BrandGroupModalProps {
	isOpen: boolean;
	group: BrandGroup | null;
	onClose: () => void;
	onBrandRemovedFromGroup: (groupId: number, brandId: number, newGroup: BrandGroup) => void;
	onBrandDeactivated: (groupId: number, brand: Brand) => void;
	onBrandDeleted: (groupId: number, brandId: number) => void;
	onBrandEdited: (groupId: number, brand: Brand) => void;
	onBrandGroupEdited: (brandGroup: BrandGroup) => void;
}

const BrandGroupModal = (props: BrandGroupModalProps): ReactElement => {
	const [brandIdOfExpanded, setBrandIdOfExpanded] = useState<number | null>(null);
	const [deactivateConfirmModalState, setDeactivateConfirmModalState] = useState<ConfirmModalState>({
		isOpen: false,
		brand: null,
	});
	const [deleteConfirmModalState, setDeleteConfirmModalState] = useState<ConfirmModalState>({
		isOpen: false,
		brand: null,
	});
	const [brandEditModalState, setBrandEditModalState] = useState<ConfirmModalState>({ isOpen: false, brand: null });

	const isOpen = props.isOpen && props.group !== null;

	const brands = useMemo(() => props.group?.brands ?? [], [props.group?.brands]);
	const hasOnlyOneBrand = brands.length === 1;
	const showRemoveFromGroupButton = brands.length > 1;

	const commonClasses = useCommonClasses();
	const token = useAppSelector((x) => x.profile.token);
	const { dispatchStopLoading, dispatchStartLoading } = useLoadingDispatch();
	const { dispatchSetAxiosErrorMessage } = useMessagesDispatch();
	const { dispatchSetMessageAction } = useMessagesDispatch();

	useEffect(() => {
		if (hasOnlyOneBrand) {
			setBrandIdOfExpanded(brands[0].id);
		}
	}, [brands, hasOnlyOneBrand]);

	const handleGroupNameChanged = (brandGroup: BrandGroup): void => {
		props.onBrandGroupEdited(brandGroup);
	};

	const handleBrandClicked = (id: number): void => {
		setBrandIdOfExpanded((prev) => {
			if (prev === id) return null;
			return id;
		});
	};

	const handleEditButtonClicked = (brand: Brand): void => {
		setBrandEditModalState({
			brand,
			isOpen: true,
		});
	};

	const handleRemoveFromGroupButtonClicked: (brandId: number) => void = async (brandId) => {
		dispatchStartLoading();
		try {
			const response = await postSplitBrandGroupRequest(token, brandId);
			props.onBrandRemovedFromGroup(props.group?.id ?? 0, brandId, response.data);
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		} finally {
			dispatchStopLoading();
		}
	};

	const handleDeactivateButtonClicked = (brand: Brand): void => {
		setDeactivateConfirmModalState({
			brand,
			isOpen: true,
		});
	};

	const handleDeleteButtonClicked = (brand: Brand): void => {
		setDeleteConfirmModalState({
			brand,
			isOpen: true,
		});
	};

	const handleConfirmedDeactivationClicked: () => void = async () => {
		const brandId = deactivateConfirmModalState.brand?.id;
		if (!brandId) return;

		dispatchStartLoading();
		try {
			const response = await putDeactivateBrandRequest(token, brandId);
			props.onBrandDeactivated(props.group?.id ?? 0, response.data);
			dispatchSetMessageAction(
				`Brand ${deactivateConfirmModalState.brand?.name} was deactivated.`,
				MessageStatusLevel.Success,
				5000
			);
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		} finally {
			dispatchStopLoading();
			handleDeactivateModalClose();
		}
	};

	const handleDeactivateModalClose = (): void => {
		setDeactivateConfirmModalState((prev) => ({ ...prev, isOpen: false }));
	};

	const handleConfirmedDeleteClicked: () => void = async () => {
		const brandId = deleteConfirmModalState.brand?.id;
		if (!brandId) return;

		dispatchStartLoading();
		try {
			await deleteBrandRequest(token, brandId);
			props.onBrandDeleted(props.group?.id ?? 0, brandId);
			dispatchSetMessageAction(
				`Brand ${deleteConfirmModalState.brand?.name} was deleted.`,
				MessageStatusLevel.Success,
				5000
			);
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		} finally {
			dispatchStopLoading();
			handleDeleteModalClose();
		}
	};

	const handleDeleteModalClose = (): void => {
		setDeleteConfirmModalState((prev) => ({ ...prev, isOpen: false }));
	};

	const handleBrandEditModalOpen = (): void => {
		setBrandEditModalState((prev) => ({ ...prev, isOpen: false }));
	};

	const handleBrandEdited = (brand: Brand): void => {
		props.onBrandEdited(props.group?.id ?? 0, brand);
	};

	return (
		<Modal open={isOpen} onClose={props.onClose}>
			<BrandGroupNameTextField
				defaultName={props.group?.name}
				groupId={props.group?.id}
				onNameChanged={handleGroupNameChanged}
			/>
			<Divider />
			<List component={'div'}>
				{brands.map((x) => {
					const isExpanded = brandIdOfExpanded === x.id;
					const nameClasses = classNames({
						[commonClasses.error]: x.status === BrandStatus.Deactivated,
						[commonClasses.success]: x.status === BrandStatus.Activated,
					});
					return (
						<Fragment key={x.id}>
							<ListItem button={true} onClick={() => handleBrandClicked(x.id)}>
								<ListItemText primary={x.name} className={nameClasses} />
								{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
							</ListItem>
							<Collapse in={isExpanded}>
								<Card className={commonClasses.cardBackground}>
									<CardContent>
										<List>
											<ListItem>
												<ListItemText primary="Status" secondary={BrandStatus[x.status]} />
											</ListItem>
										</List>
										<Divider />
										<List>
											{x.logos.map((y) => (
												<ListItem key={y.id}>
													<Grid container spacing={1}>
														<Grid item xs={9}>
															<img src={y.iconUrl} alt={y.iconUrl} />
														</Grid>
														<Grid item xs={3}>
															<ListItemText primary="Precision" secondary={y.precision ?? 'No data'} />
														</Grid>
													</Grid>
												</ListItem>
											))}
											{x.texts.length > 0 && (
												<>
													<Divider />
													{x.texts.map((y) => (
														<ListItem key={y.id}>
															<Grid container spacing={1}>
																<Grid item xs={9}>
																	<Typography variant="h6">{y.text}</Typography>
																</Grid>
																<Grid item xs={3}>
																	<ListItemText primary="Threshold" secondary={y.threshold ?? 'No data'} />
																</Grid>
															</Grid>
														</ListItem>
													))}
												</>
											)}
										</List>
									</CardContent>
									<CardActions>
										<ClaimPermissionComponent claimFlag={UserClaimFlag.BrandWrite}>
											<Tooltip title="Edit">
												<span>
													<IconButton onClick={() => handleEditButtonClicked(x)}>
														<EditIcon />
													</IconButton>
												</span>
											</Tooltip>
											<Tooltip title="Remove from group">
												<span>
													<IconButton
														onClick={() => handleRemoveFromGroupButtonClicked(x.id)}
														className={commonClasses.errorLight}
														disabled={!showRemoveFromGroupButton}
													>
														<RemoveCircleOutlineIcon />
													</IconButton>
												</span>
											</Tooltip>
										</ClaimPermissionComponent>
										<ClaimPermissionComponent claimFlag={UserClaimFlag.BrandDelete}>
											<Tooltip title={'Deactivate'}>
												<span>
													<IconButton
														onClick={() => handleDeactivateButtonClicked(x)}
														className={commonClasses.error}
														disabled={x.status !== BrandStatus.Activated}
													>
														<CancelIcon />
													</IconButton>
												</span>
											</Tooltip>
											<Tooltip title={'Delete'}>
												<span>
													<IconButton onClick={() => handleDeleteButtonClicked(x)} className={commonClasses.errorDark}>
														<DeleteIcon />
													</IconButton>
												</span>
											</Tooltip>
										</ClaimPermissionComponent>
									</CardActions>
								</Card>
							</Collapse>
						</Fragment>
					);
				})}
			</List>
			<ConfirmModal
				open={deactivateConfirmModalState.isOpen}
				onConfirmClicked={handleConfirmedDeactivationClicked}
				title={`Deactivate ${deactivateConfirmModalState.brand?.name}`}
				onClose={handleDeactivateModalClose}
			>
				<Typography variant="body1">
					Are you sure you want to deactivate brand <b>{deactivateConfirmModalState.brand?.name}</b>? New videos will
					not display this brand.
				</Typography>
			</ConfirmModal>
			<ConfirmModal
				open={deleteConfirmModalState.isOpen}
				onConfirmClicked={handleConfirmedDeleteClicked}
				title={`Delete ${deleteConfirmModalState.brand?.name}`}
				onClose={handleDeleteModalClose}
			>
				<Typography variant="body1">
					Are you sure you want to delete brand <b>{deleteConfirmModalState.brand?.name}</b>? New videos will not
					display this brand.
				</Typography>
				<Typography variant="body1">
					<i>
						Warning: This will also delete all detections with this brands from existing videos. If you need to keep
						existing detections deactivate brand instead.
					</i>
				</Typography>
			</ConfirmModal>
			<BrandEditModal
				isOpen={brandEditModalState.isOpen}
				brand={brandEditModalState.brand}
				onClose={handleBrandEditModalOpen}
				onBrandEdited={handleBrandEdited}
			/>
		</Modal>
	);
};

export default BrandGroupModal;
