import { AxiosPromise } from 'axios';
import BrandActivation from 'Models/Brands/BrandActivation';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export type PostBrandActivationResponse = BrandActivation;

export interface PostBrandActivationRequestProps {
	name: string;
	exampleUrl: string;
	localizedName: string | null;
}

export type PostBrandActivationRequest = (
	token: string | null,
	request: PostBrandActivationRequestProps,
	exampleImages: File[]
) => AxiosPromise<PostBrandActivationResponse>;

const postBrandActivationRequest: PostBrandActivationRequest = async (token, request, exampleImages) => {
	const url = getApiUrl(ApiUrl.BrandActivations);
	const axios = getAxiosInstance(token);
	const data = new FormData();
	data.append('request', JSON.stringify(request));
	exampleImages.forEach((x) => data.append('exampleImages', x));
	return await axios.post<PostBrandActivationResponse>(url, data);
};

export default postBrandActivationRequest;
