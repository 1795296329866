import { TextField } from '@material-ui/core';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import BrandGroup from 'Models/Brands/BrandGroup';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import putBrandGroupRequest from 'Utils/Http/Requests/Brands/PutBrandGroupRequest';

interface BrandGroupNameTextFieldProps {
	groupId?: number;
	defaultName?: string;
	onNameChanged: (brandGroup: BrandGroup) => void;
}

const BrandGroupNameTextField = (props: BrandGroupNameTextFieldProps): ReactElement => {
	const [name, setName] = useState(props.defaultName);
	const [isLoading, setIsLoading] = useState(false);

	const token = useAppSelector((state) => state.profile.token);

	const { dispatchSetAxiosErrorMessage } = useMessagesDispatch();

	// The effect will update to default name whenever default name is changed.
	useEffect(() => {
		if (props.defaultName !== undefined) setName(props.defaultName);
	}, [props.defaultName]);

	const handleTextFieldBlurred: () => void = async () => {
		// Do not do anything if the name was not actually changed
		if (name === props.defaultName || props.groupId === undefined || name === undefined) return;

		setIsLoading(true);
		try {
			const response = await putBrandGroupRequest(token, props.groupId, { name });
			props.onNameChanged(response.data);
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
			setName(props.defaultName);
		} finally {
			setIsLoading(false);
		}
	};

	const handleTextFieldChanged = (e: ChangeEvent<HTMLInputElement>): void => {
		const { value } = e.target;
		setName(value);
	};

	return (
		<TextField value={name} onBlur={handleTextFieldBlurred} disabled={isLoading} onChange={handleTextFieldChanged} />
	);
};
export default BrandGroupNameTextField;
