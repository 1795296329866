import { Drawer } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import NoteIcon from '@material-ui/icons/Note';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import PlaceIcon from '@material-ui/icons/Place';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import classNames from 'classnames';
import MainMenuListItem, { MainMenuListItemSubItem } from 'Components/Layout/MainMenuListItem';
import useMainMenuClasses from 'Hooks/Styles/Layout/useMainMenuClasses';
import { UserClaimFlag } from 'Models/User';
import { ReactElement } from 'react';
import { AppUrl } from 'Utils/Http/AppUrls';

interface MainMenuProps {
	isExpanded: boolean;
	onCloseDrawer: () => void;
	drawerWidth: number;
	subItems?: MainMenuListItemSubItem[];
}

const MainMenu = ({ isExpanded, onCloseDrawer, drawerWidth, subItems }: MainMenuProps): ReactElement => {
	const classes = useMainMenuClasses({ drawerWidth });

	const drawerPaperClasses = classNames({
		[classes.drawerPaper]: true,
		[classes.drawerPaperClose]: !isExpanded,
	});

	const handleDrawerClose = () => {
		onCloseDrawer();
	};

	return (
		<Drawer
			variant="permanent"
			classes={{
				paper: drawerPaperClasses,
			}}
			open={isExpanded}
		>
			<div className={classes.toolbarIcon}>
				<IconButton onClick={handleDrawerClose}>
					<ChevronLeftIcon />
				</IconButton>
			</div>
			<Divider />
			<List>
				<MainMenuListItem
					title="Dashboard"
					to={AppUrl.Dashboard}
					icon={<DashboardIcon />}
					subItems={subItems}
					allowSubItems={isExpanded}
				/>
				<MainMenuListItem
					title="Brands"
					to={AppUrl.Brands}
					icon={<Icon> &#8482; </Icon>}
					subItems={subItems}
					allowSubItems={isExpanded}
					claimFlag={UserClaimFlag.BrandRead}
				/>
				<MainMenuListItem
					title="Brand Activations"
					to={AppUrl.BrandActivations}
					icon={<AddIcon />}
					subItems={subItems}
					allowSubItems={isExpanded}
					claimFlag={UserClaimFlag.BrandWrite}
				/>
				<MainMenuListItem
					title="Placements"
					to={AppUrl.Placements}
					icon={<PlaceIcon />}
					subItems={subItems}
					allowSubItems={isExpanded}
					claimFlag={UserClaimFlag.VideoRead}
				/>
				<MainMenuListItem
					title={'Videos'}
					to={AppUrl.Videos}
					icon={<VideoLibraryIcon />}
					allowSubItems={isExpanded}
					claimFlag={UserClaimFlag.VideoRead}
					subItems={subItems}
				/>
				<MainMenuListItem
					title={'Editor'}
					to={AppUrl.Editor}
					icon={<OndemandVideoIcon />}
					allowSubItems={isExpanded}
					claimFlag={UserClaimFlag.VideoRead}
					subItems={subItems}
				/>
				<MainMenuListItem
					title={'Projects'}
					to={AppUrl.Projects}
					icon={<NoteIcon />}
					allowSubItems={isExpanded}
					subItems={subItems}
				/>
			</List>
		</Drawer>
	);
};

export default MainMenu;
