import { Action } from 'redux';

export enum LoadingActionType {
	StartLoading = 'START_LOADING',
	StopLoading = 'STOP_LOADING',
}

export interface StartLoadingAction extends Action<LoadingActionType.StartLoading> {
	title?: string;
}
export type StopLoadingAction = Action<LoadingActionType.StopLoading>;

export type LoadingAction = StartLoadingAction | StopLoadingAction;
