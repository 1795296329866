import { Typography } from '@material-ui/core';
import LayoutAppBar from 'Components/Layout/LayoutAppBar';
import MainMenu from 'Components/Layout/MainMenu';
import { MainMenuListItemSubItem } from 'Components/Layout/MainMenuListItem';
import useLayoutClasses from 'Hooks/Styles/Layout/useLayoutClasses';
import useCommonClasses from 'Hooks/Styles/useCommonClasses';
import { FC, useState } from 'react';

interface LayoutProps {
	title: string;
	subItems?: MainMenuListItemSubItem[];
}

const DRAWER_WIDTH = 240;

const Layout: FC<LayoutProps> = ({ title, children, subItems }) => {
	const [isExpanded, setIsExpanded] = useState(true);

	const commonClasses = useCommonClasses();
	const classes = useLayoutClasses();

	const handleDrawerClosed = (): void => {
		setIsExpanded(false);
	};

	return (
		<div className={commonClasses.flex}>
			<LayoutAppBar expanded={isExpanded} onExpandedChanged={setIsExpanded} drawerWidth={DRAWER_WIDTH}>
				<Typography component="h1" variant="h6" noWrap={true} className={commonClasses.flexGrowOne}>
					{title}
				</Typography>
			</LayoutAppBar>
			<MainMenu
				isExpanded={isExpanded}
				onCloseDrawer={handleDrawerClosed}
				drawerWidth={DRAWER_WIDTH}
				subItems={subItems}
			/>
			<div className={classes.contentContainer}>
				<div className={classes.appBarSpacer} />
				<main className={classes.content}>{children}</main>
			</div>
		</div>
	);
};

export default Layout;
