import { Checkbox, InputLabel, MenuItem, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import ConditionalDisplay from 'Components/Common/ConditionalDisplay';
import Brand from 'Models/Brands/Brand';
import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { isNumberArray } from 'Utils/TypeGuards';

interface ChangeAllPlacementCardBrandTextSelectProps {
	displayContent: boolean;
	brand: Brand | null;
	selectedTextIds: number[];
	onSelectedTextIdsChanged: (ids: number[]) => void;
}

const ChangeAllPlacementCardBrandTextSelect = (props: ChangeAllPlacementCardBrandTextSelectProps): ReactElement => {
	const shouldDisplay = props.displayContent && props.brand !== null && props.brand.texts.length > 0;

	const handleTextsChanged = ({ target }: React.ChangeEvent<{ value: unknown }>): void => {
		if (isNumberArray(target.value)) props.onSelectedTextIdsChanged(target.value);
		else console.error(`expected number array but instead got 'target.value'`);
	};

	const renderValue = (selected: unknown): ReactNode => {
		if (!isNumberArray(selected) || props.brand === null) return null;
		const textNames = props.brand.texts.filter((x) => selected.includes(x.id)).map((x) => x.text);
		return textNames.join(', ');
	};

	return (
		<ConditionalDisplay displayContent={shouldDisplay}>
			<FormControl fullWidth={true} margin={'normal'}>
				<InputLabel id="change-all-placement-brands-text-select-label"></InputLabel>
				<Select
					labelId="change-all-placement-brands-text-select-label"
					value={props.selectedTextIds}
					onChange={handleTextsChanged}
					multiple={true}
					renderValue={renderValue}
				>
					{props.brand?.texts.map((x) => (
						<MenuItem value={x.id} key={x.id}>
							<Checkbox checked={props.selectedTextIds.includes(x.id)} />
							<ListItemText primary={x.text} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</ConditionalDisplay>
	);
};

export default ChangeAllPlacementCardBrandTextSelect;
