import { Snackbar } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import useAppSnackbarMessageStyles from 'Hooks/Styles/App/useAppSnackbarMessageStyles';
import { ReactElement } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import { MessageState } from 'Redux/Reducers/Messages';

interface AppSnackbarMessageProps {
	snackbarMessage: MessageState;
}

const AppSnackbarMessage = ({ snackbarMessage }: AppSnackbarMessageProps): ReactElement => {
	const { guid, duration, isOpen, level, message } = snackbarMessage;

	const { dispatchCloseMessageAction, dispatchClearMessageAction } = useMessagesDispatch();

	const classes = useAppSnackbarMessageStyles();
	const snackbarContentClasses = classNames({
		[classes.success]: level === MessageStatusLevel.Success,
		[classes.info]: level === MessageStatusLevel.Info,
		[classes.warning]: level === MessageStatusLevel.Warning,
		[classes.error]: level === MessageStatusLevel.Error,
	});

	const handleSnackbarClose = (event: object, reason: string): void => {
		if (reason === 'clickaway') {
			dispatchCloseMessageAction(guid);
		}
	};

	const handleSnackbarExited = (): void => {
		dispatchClearMessageAction(guid);
	};

	const handleButtonClicked = (): void => {
		dispatchCloseMessageAction(guid);
	};

	return (
		<Snackbar
			open={isOpen}
			anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
			autoHideDuration={duration}
			onClose={handleSnackbarClose}
			onExited={handleSnackbarExited}
		>
			<SnackbarContent
				className={snackbarContentClasses}
				message={
					<span id="snackbar-message" className={classes.message}>
						<Icon className={classes.icon} />
						{message}
					</span>
				}
				action={[
					<IconButton key="close" aria-label="Close" color="inherit" onClick={handleButtonClicked}>
						<CloseIcon className={classes.icon} />
					</IconButton>,
				]}
			/>
		</Snackbar>
	);
};

export default AppSnackbarMessage;
