import { Card, CardActions, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Project from 'Models/Project';
import { ReactElement } from 'react';

interface ProjectCardItemProps {
	project: Project;
	onEditClicked: (project: Project) => void;
	onDeleteClicked: (id: number) => void;
}

const useStyles = makeStyles((theme) => ({
	deleteIcon: {
		color: theme.palette.error.dark,
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
}));

const ProjectCardItem = ({ project, onEditClicked, onDeleteClicked }: ProjectCardItemProps): ReactElement => {
	const classes = useStyles();

	const handleEditClick = (): void => {
		onEditClicked(project);
	};

	const handleDeleteClick = (): void => {
		onDeleteClicked(project.id);
	};

	return (
		<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
			<Card>
				<CardContent>
					<Typography gutterBottom variant="h5" component="h2" noWrap align="center">
						{project.name}
					</Typography>
					<Divider />
					<List>
						<ListItem alignItems="flex-start">
							<ListItemText primary="description:" secondary={project.description} />
						</ListItem>
						<ListItem alignItems="flex-start">
							<ListItemText primary="Shown brand groups" secondary={project.brandGroupIds.length} />
						</ListItem>
					</List>
				</CardContent>
				<CardActions className={classes.actionsContainer} disableSpacing>
					<Tooltip title="Edit">
						<IconButton aria-label="Edit brand" onClick={handleEditClick}>
							<EditIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title="Delete" className={classes.deleteIcon}>
						<IconButton aria-label="Delete brand" onClick={handleDeleteClick}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</CardActions>
			</Card>
		</Grid>
	);
};

export default ProjectCardItem;
