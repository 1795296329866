import App from 'App';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducers from 'Redux/Reducers';
import './index.css';

// @ts-ignore ignore next line due to some mumbo jubmo
const enhancer = window['__REDUX_DEVTOOLS_EXTENSION__']
	? // @ts-ignore ignore next line due to some mumbo jubmo
	  window['__REDUX_DEVTOOLS_EXTENSION__']()(createStore)
	: createStore;
const store = enhancer(reducers);

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);
