import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useCommonClasses = makeStyles(({ palette, mixins, spacing }: Theme) =>
	createStyles({
		flexGrowOne: {
			flexGrow: 1,
		},
		cardBackground: {
			backgroundColor: palette.grey[300],
		},
		flex: {
			display: 'flex',
		},
		absolute: {
			position: 'absolute',
		},
		relative: {
			position: 'relative',
		},
		fullSize: {
			width: '100%',
			height: '100%',
		},
		errorLight: {
			color: palette.error.light,
		},
		error: {
			color: palette.error.main,
		},
		errorDark: {
			color: palette.error.dark,
		},
		success: {
			color: palette.success.main,
		},
		fullScreen: {
			height: `calc(100vh - (${mixins.toolbar.minHeight}px + ${spacing(7)}px))`,
		},
	})
);

export default useCommonClasses;
