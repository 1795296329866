import { AxiosPromise } from 'axios';
import useDataFetcher, { UseDataFetcherReturn } from 'Hooks/Http/useDataFetcher';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import { useCallback, useEffect } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import getBrandGroupsRequest, { GetBrandGroupsResponse } from 'Utils/Http/Requests/Brands/GetBrandGroupsRequest';

const useFetchBrandGroups = (options?: {
	refresh?: boolean;
	forceReload?: number;
}): UseDataFetcherReturn<GetBrandGroupsResponse> => {
	const { dispatchSetMessageAction } = useMessagesDispatch();

	const fetcher = useCallback<(token: string | null) => AxiosPromise<GetBrandGroupsResponse>>(
		(token) => {
			const response = getBrandGroupsRequest(token, options?.refresh);
			// This might seem stupid but it's necessary workaround so that effect that fetches this data runs.
			console.log(`Refreshed brands for ${options?.forceReload} time.`);
			return response;
		},
		[options?.refresh, options?.forceReload]
	);

	const fetcherData = useDataFetcher<GetBrandGroupsResponse>(fetcher);

	useEffect(() => {
		if (!fetcherData.loading && fetcherData.data !== null && options?.refresh)
			dispatchSetMessageAction('Successfully synchronized brands.', MessageStatusLevel.Success, 3000);
	}, [dispatchSetMessageAction, fetcherData.data, fetcherData.loading, options?.refresh]);

	return fetcherData;
};

export default useFetchBrandGroups;
