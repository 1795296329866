const TOKEN_KEY = 'TOKEN';
const SELECTED_VIDEO_ID_KEY = 'SELECTED_VIDEO_ID';
const EDITOR_TIME = 'EDITOR_TIME';

export const clearStorage = (): void => localStorage.clear();

// Common function for storage
const getNumberValue = (key: string): number | null => {
	const value = localStorage.getItem(key) ?? '';
	const numberValue = parseInt(value);
	if (isNaN(numberValue)) return null;
	return numberValue;
};

// Token storage
export const setToken = (token: string): void => localStorage.setItem(TOKEN_KEY, token);
export const getToken = (): string | null => localStorage.getItem(TOKEN_KEY);
export const deleteToken = (): void => localStorage.removeItem(TOKEN_KEY);

// Video storage
export const setSelectedVideoId = (id: number): void => localStorage.setItem(SELECTED_VIDEO_ID_KEY, id.toString());
export const getSelectedVideoId = (): number | null => getNumberValue(SELECTED_VIDEO_ID_KEY);
export const deleteSelectedVideoId = (): void => localStorage.removeItem(SELECTED_VIDEO_ID_KEY);

// Editor time storage
export const setEditorTime = (time: number): void => localStorage.setItem(EDITOR_TIME, time.toString());
export const getEditorTime = (): number | null => getNumberValue(EDITOR_TIME);
export const deleteEditorTime = (): void => localStorage.removeItem(EDITOR_TIME);
