import { useEffect, useRef } from 'react';
import { getEditorTime } from 'Utils/LocalStorageHelper';

/**
 * The hook will read the value of editor time from storage and will assign it to the given video node if the time is
 * valid and video node exists. This time will be set only 1 time after beeing rendered.
 * @param videoNode
 */
const useSetVideoEditorTime = (videoNode: HTMLVideoElement | null): void => {
	const editorTime = getEditorTime();
	const isVideoLoaded = videoNode !== null && !isNaN(videoNode?.duration);
	const wasSetRef = useRef(false);

	useEffect(() => {
		if (videoNode === null || editorTime === null || !isVideoLoaded || wasSetRef.current) return;
		const isEditorTimeValid = editorTime > 0 && editorTime < videoNode.duration;
		if (isEditorTimeValid) videoNode.currentTime = editorTime;
		wasSetRef.current = true;
	}, [editorTime, isVideoLoaded, videoNode]);
};

export default useSetVideoEditorTime;
