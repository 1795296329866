import { LinearProgress, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import VideoProgressBarPreviewTooltip from 'Components/Editor/VideoPlayer/VideoProgressBarPreviewTooltip';
import Video from 'Models/Videos/Video';
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';

const useStyles = makeStyles(({ spacing, transitions }: Theme) =>
	createStyles({
		progress: {
			marginTop: spacing(1),
			height: spacing(2),
			transition: transitions.create(['height', 'margin'], {
				easing: transitions.easing.easeInOut,
				duration: transitions.duration.standard,
			}),
			'&:hover': {
				height: spacing(3),
				margin: 0,
			},
		},
	})
);

interface VideoProgressBarProps {
	width: number;
	videoNode: HTMLVideoElement | null;
	retrievedTimeSpans: [number, number][];
	selectedVideo: Video | null;
}

const VideoProgressBar = (props: VideoProgressBarProps): ReactElement => {
	const { videoNode, retrievedTimeSpans, width } = props;

	const [videoBuffer, setVideoBuffer] = useState(0);
	const [detectionBuffer, setDetectionBuffer] = useState(0);
	const [currentProgress, setCurrentProgress] = useState(0);

	const classes = useStyles();

	const progressRef = useRef<HTMLDivElement | null>(null);

	const bufferProgress = Math.min(videoBuffer, detectionBuffer);

	const getCurrentDetectionBufferEnd = useCallback(
		(currentTime: number): number => {
			const currentDetectionBufferSpan = retrievedTimeSpans.find((x) => currentTime >= x[0] && currentTime < x[1]);
			if (!currentDetectionBufferSpan) {
				return currentTime;
			}
			return currentDetectionBufferSpan[1];
		},
		[retrievedTimeSpans]
	);

	const getCurrentVideoBufferEnd = useCallback((currentTime: number, videoBuffer: TimeRanges): number => {
		let currentVideoBuffer = currentTime;
		for (let i = 0; i < videoBuffer.length; i++) {
			const start = videoBuffer.start(i);
			const end = videoBuffer.end(i);
			if (currentTime >= start && currentTime < end) {
				currentVideoBuffer = end;
			}
		}
		return currentVideoBuffer;
	}, []);

	useEffect(
		// Effect updates the detections buffers whenever detection timespan changes.
		() => {
			if (!videoNode) {
				return;
			}

			const { duration, currentTime } = videoNode;
			const detectionBufferEnd = getCurrentDetectionBufferEnd(currentTime);
			const detectionBufferProgress = (detectionBufferEnd / duration) * 100;
			setDetectionBuffer(detectionBufferProgress);
		},
		[getCurrentDetectionBufferEnd, videoNode]
	);

	useEffect(() => {
		if (!videoNode) {
			return;
		}

		function handleVideoProgress(this: HTMLVideoElement): void {
			const { currentTime, buffered, duration } = this;
			const currentVideoBufferEnd = getCurrentVideoBufferEnd(currentTime, buffered);
			const videoBufferProgress = (currentVideoBufferEnd / duration) * 100;
			setVideoBuffer(videoBufferProgress);
		}

		videoNode.addEventListener('progress', handleVideoProgress);
		return () => videoNode.removeEventListener('progress', handleVideoProgress);
	}, [videoNode, getCurrentVideoBufferEnd]);

	useEffect(
		// Effect updates the current time whenever video's time has changed.
		() => {
			if (videoNode == null) {
				return;
			}

			function handleVideoTimeUpdate(this: HTMLVideoElement): void {
				const { currentTime, duration } = this;
				const currentProgress = (currentTime / duration) * 100;
				setCurrentProgress(currentProgress);
			}

			videoNode.addEventListener('timeupdate', handleVideoTimeUpdate);
			return () => videoNode.removeEventListener('timeupdate', handleVideoTimeUpdate);
		}
	);

	const handleProgressClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			if (!videoNode || !progressRef.current) return;

			if (isNaN(videoNode.duration)) {
				console.warn('Video is not loaded, yet user tried to seek.');
				return;
			}

			const progress = (event.pageX - progressRef.current.offsetLeft) / progressRef.current.clientWidth;
			videoNode.currentTime = Math.round(videoNode.duration * progress);
		},
		[videoNode, progressRef]
	);

	return useMemo(
		(): JSX.Element => (
			<>
				<VideoProgressBarPreviewTooltip selectedVideo={props.selectedVideo}>
					<LinearProgress
						className={classes.progress}
						style={{ maxWidth: width }}
						valueBuffer={bufferProgress}
						value={currentProgress}
						variant="buffer"
						color="secondary"
						onClick={handleProgressClick}
						ref={progressRef}
					/>
				</VideoProgressBarPreviewTooltip>
			</>
		),
		[props.selectedVideo, classes.progress, width, bufferProgress, currentProgress, handleProgressClick]
	);
};

export default VideoProgressBar;
