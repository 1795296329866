import { MutableRefObject, useEffect, useRef } from 'react';

const useValueRef = <T>(value: T): MutableRefObject<T> => {
	const ref = useRef(value);

	useEffect(() => {
		ref.current = value;
	}, [value]);

	return ref;
};

export default useValueRef;
