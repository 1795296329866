import * as React from 'react';
import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface TitledBoxProps {
	title: string;
	border?: number;
}

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
	box: {
		padding: spacing(2),
	},
	title: {
		position: 'relative',
		transform: 'translateY(-50%)',
		backgroundColor: palette.background.default,
		marginTop: `-${spacing(2)}px`,
		width: 'min-content',
		whiteSpace: 'nowrap',
	},
	boxContent: {
		marginTop: `-${spacing(1)}px`,
	},
}));

const TitledBox: React.FC<TitledBoxProps> = (props) => {
	const classes = useStyles();

	return (
		<Box border={props.border} className={classes.box}>
			<Typography component="h4" variant="h6" className={classes.title}>
				{props.title}
			</Typography>
			<div className={classes.boxContent}>{props.children}</div>
		</Box>
	);
};

export default TitledBox;
