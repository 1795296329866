export type Key =
	| 'b'
	| 'f'
	| 'n'
	| 'p'
	| 'm'
	| 'v'
	| 'ArrowRight'
	| 'ArrowLeft'
	| '+'
	| '-'
	| 'Control'
	| 'Escape'
	| 'Meta';

export const isExpectedKey = (event: KeyboardEvent, expectedKey: Key): boolean => {
	return event.key === expectedKey;
};
