import { CombinedState, combineReducers } from 'redux';
import { AppAction, IndexActionType } from 'Redux/Actions';
import loading, { LoadingState } from 'Redux/Reducers/Loading';
import messages, { MessagesState } from 'Redux/Reducers/Messages';
import profile, { ProfileState } from 'Redux/Reducers/Profile';
import video, { VideoState } from 'Redux/Reducers/Video';

export type AppState = CombinedState<{
	profile: ProfileState;
	loading: LoadingState;
	messages: MessagesState;
	video: VideoState;
}>;

const combinedReducers = combineReducers<AppState, AppAction>({
	profile,
	loading,
	messages,
	video,
});

const reducers = (state: AppState | undefined, action: AppAction): AppState => {
	if (action.type === IndexActionType.Logout) {
		state = undefined;
	}

	return combinedReducers(state, action);
};

export default reducers;
