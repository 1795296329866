import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import classNames from 'classnames';
import BrandSelect from 'Components/Common/BrandsSelect';
import EditorButton from 'Components/Common/Buttons/EditorButton';
import EditorDetectionPlacementLabel from 'Components/Common/Labels/EditorDetectionPlacementLabel';
import EditorButtonPlacementSelect from 'Components/Common/EditorButtonPlacementSelect';
import TitledBox from 'Components/Common/TitledBox';
import { UseVideoPlayerInstance } from 'Hooks/Editor/useVideoPlayerInstance';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import useEditorActionsStyles from 'Hooks/Styles/Editor/useEditorActionsStyles';
import EditorVideoDetection from 'Models/Videos/Detections/EditorVideoDetection';
import VideoDetectionPlacement from 'Models/Videos/VideoDetectionPlacement';
import { ReactElement, useEffect, useState } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import { isFilteredDetection } from 'Utils/DetectionHelpers';
import deleteVideoDetectionRequest from 'Utils/Http/Requests/Videos/Detections/DeleteVideoDetectionRequest';
import patchVideoDetection from 'Utils/Http/Requests/Videos/Detections/PatchVideoDetectionRequest';
import postVideoDetectionFeedbackRequest, {
	DetectFeedbackType,
	PostVideoDetectionFeedbackRequest,
} from 'Utils/Http/Requests/Videos/Detections/PostVideoDetectionFeedbackRequest';

interface EditorDetectionsActionsProps {
	instance: UseVideoPlayerInstance;
}

const EditorDetectionsActions = ({ instance }: EditorDetectionsActionsProps): ReactElement => {
	const {
		videoNode,
		frameDetections,
		detections,
		selectedDetectionIds,
		fps,
		selectDetectionIds,
		unselectDetectionId,
		placements,
		brands,
		brandGroups,
		filteredPlacementIds,
		filteredBrandIds,
		showFilteredDetections,
		video,
		changeDetectionPlacement,
		changeDetectionBrand,
	} = instance;

	const [maxHeight, setMaxHeight] = useState(0);
	const [currentFrame, setCurrentFrame] = useState(0);

	const frameDetectionIds = frameDetections[currentFrame]?.map((x) => x.videoDetectionId) ?? [];
	const filteredDetections = detections.filter((x) => {
		const isDetectionInFrame = frameDetectionIds.includes(x.id);
		if (!isDetectionInFrame) return false;
		const brand = brands.find((b) => b.id === x.brandId);

		const isDetectionFiltered = isFilteredDetection(
			{ placementId: x.placementId, brandId: brand?.id ?? 0 },
			filteredBrandIds,
			filteredPlacementIds
		);
		return !isDetectionFiltered || showFilteredDetections;
	});
	const filteredSelectedDetections = filteredDetections.filter((x) => selectedDetectionIds.includes(x.id));
	const disabledEditButtons = filteredSelectedDetections.length === 0;

	const { dispatchSetAxiosErrorMessage, dispatchSetMessageAction } = useMessagesDispatch();

	const token = useAppSelector((x) => x.profile.token);

	useEffect(() => {
		if (!videoNode) return;

		function func() {
			const mains = document.getElementsByTagName('main');
			if (mains.length < 1) return;
			setMaxHeight(mains[0].offsetHeight);
		}

		videoNode.addEventListener('loadeddata', func);
		window.addEventListener('resize', func);
		return () => {
			videoNode.removeEventListener('loadeddata', func);
			window.removeEventListener('resize', func);
		};
	}, [videoNode]);

	useEffect(() => {
		if (videoNode === null) return;

		const func = function (this: HTMLVideoElement): void {
			setCurrentFrame(Math.floor(this.currentTime * fps));
		};

		videoNode.addEventListener('timeupdate', func);
	}, [fps, videoNode]);

	const deleteDetection: (detectionId: number) => Promise<void> = async (detectionId) => {
		if (video == null) return;

		try {
			await deleteVideoDetectionRequest(token, video.id, detectionId);
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		}
	};

	const handleFeedback = async (type: DetectFeedbackType): Promise<void> => {
		if (video === null) return;
		const request: PostVideoDetectionFeedbackRequest = { type };
		const promises = selectedDetectionIds.map((x) => postVideoDetectionFeedbackRequest(token, video.id, x, request));
		await Promise.all(promises);
	};

	const handleTruePositiveClicked = async (): Promise<void> => {
		await handleFeedback(DetectFeedbackType.TruePositive);
		dispatchSetMessageAction('Sent TRUE positive feedback.', MessageStatusLevel.Success);
	};

	const handleFalsePositiveClicked = async (): Promise<void> => {
		await handleFeedback(DetectFeedbackType.FalsePositive);
		dispatchSetMessageAction('Sent FALSE positive feedback.', MessageStatusLevel.Success);
	};

	const handleDeleteButtonClicked: () => Promise<void> = async () => {
		const deletePromises: Promise<void>[] = [];
		for (let i = 0; i < instance.selectedDetectionIds.length; i++) {
			deletePromises.push(deleteDetection(instance.selectedDetectionIds[i]));
		}
		await Promise.all(deletePromises);
		dispatchSetMessageAction('Deleted selected detections.', MessageStatusLevel.Success);
		instance.removeDetections(selectedDetectionIds);
	};

	const handleBrandSelected = (brandId: number): void => {
		if (video === null) return;
		const responses = selectedDetectionIds.map((x) => patchVideoDetection(token, x, video.id, { brandId: brandId }));

		Promise.all(responses).then((r) => {
			r.forEach((x) => changeDetectionBrand(x.data.id, x.data.brandId));
		});
	};

	const handlePlacementSelectorItemClicked = (placement: VideoDetectionPlacement): void => {
		if (video === null) return;

		const responses = selectedDetectionIds.map((x) => {
			return patchVideoDetection(token, x, video.id, { placementId: placement.id });
		});

		Promise.all(responses).then((r) => {
			r.forEach((x) => changeDetectionPlacement(x.data.id, x.data.placementId));
		});
	};

	const handleDetectionRowClicked = (detection: EditorVideoDetection, select: boolean): void => {
		if (select) selectDetectionIds([detection.id], true);
		else unselectDetectionId(detection.id);
	};

	const classes = useEditorActionsStyles();
	return (
		<>
			<TitledBox border={1} title="Detections">
				<Grid container className={classes.root}>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item>
								<EditorButton
									title="True Positive"
									onClick={handleTruePositiveClicked}
									size="small"
									disabled={disabledEditButtons}
								>
									<ThumbUpIcon fontSize="small" />
								</EditorButton>
							</Grid>
							<Grid item>
								<EditorButton
									title="False Positive"
									onClick={handleFalsePositiveClicked}
									size="small"
									disabled={disabledEditButtons}
								>
									<ThumbDownIcon fontSize="small" />
								</EditorButton>
							</Grid>
							<Grid item>
								<EditorButton
									title="Delete"
									onClick={handleDeleteButtonClicked}
									size="small"
									disabled={disabledEditButtons}
								>
									<DeleteIcon fontSize="small" />
								</EditorButton>
							</Grid>
							<Grid item>
								<BrandSelect
									buttonTitle="Change Brand"
									brandGroups={brandGroups}
									onBrandSelected={handleBrandSelected}
									disabled={disabledEditButtons}
								/>
							</Grid>
							<Grid item>
								<EditorButtonPlacementSelect
									buttonTitle="Change placement"
									placements={placements}
									onPlacementSelected={handlePlacementSelectorItemClicked}
									disabled={disabledEditButtons}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<TableContainer style={{ maxHeight: `${maxHeight}px` }} component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Id</TableCell>
										<TableCell>Brand</TableCell>
										<TableCell>Placement</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredDetections.map((d) => {
										const isSelected: boolean = filteredSelectedDetections.findIndex((x) => x.id === d.id) > -1;
										const brand = brands.find((x) => x.id === d.brandId);
										const tableRowClasses = classNames({
											[classes.tableRow]: true,
											[classes.tableRowDisabled]: isFilteredDetection(
												{ placementId: d.placementId, brandId: brand?.id ?? 0 },
												instance.filteredBrandIds,
												instance.filteredPlacementIds
											),
										});
										const placement = placements.find((x) => x.id === d.placementId);
										return (
											<TableRow
												className={tableRowClasses}
												classes={{
													selected: classes.tableRowSelected,
												}}
												key={d.id}
												hover
												onClick={() => handleDetectionRowClicked(d, !isSelected)}
												selected={isSelected}
											>
												<TableCell>{d.id}</TableCell>
												<TableCell>{brand?.name ?? 'No data'}</TableCell>
												<TableCell>
													<EditorDetectionPlacementLabel placement={placement ?? null} />
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			</TitledBox>
		</>
	);
};

export default EditorDetectionsActions;
