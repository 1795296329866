import Grid from '@material-ui/core/Grid';
import EditorDetectionDetail from 'Components/Editor/Details/EditorDetectionDetail';
import { UseVideoPlayerInstance } from 'Hooks/Editor/useVideoPlayerInstance';
import { ReactElement } from 'react';

interface EditorDetectionDetailsProps {
	instance: UseVideoPlayerInstance;
}

const EditorDetectionDetails = ({ instance }: EditorDetectionDetailsProps): ReactElement => {
	const selectedDetectionIds = instance.selectedDetectionIds;
	const detections = instance.detections.filter((x) => selectedDetectionIds.includes(x.id));

	return (
		<Grid container spacing={2}>
			{detections.map((x) => (
				<Grid item={true} key={x.id}>
					<EditorDetectionDetail instance={instance} detection={x} />
				</Grid>
			))}
		</Grid>
	);
};

export default EditorDetectionDetails;
