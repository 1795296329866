import { createStyles } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import { ReactElement } from 'react';

interface ActionCardImageContentProps {
	imageUrl: string | null;
	size?: 'normal' | 'small';
	color?: string;
}

interface UseClassesProps {
	imageHeight: number;
}

type Classes = 'cardImage' | 'cardImageContainer' | 'brokenImageIcon';
type UseClasses = (props: UseClassesProps) => ClassNameMap<Classes>;

const useClasses: UseClasses = makeStyles((theme: Theme) =>
	createStyles({
		cardImage: {
			height: ({ imageHeight }) => `${imageHeight}px`,
			backgroundSize: 'contain',
		},
		cardImageContainer: {
			padding: theme.spacing(2),
		},
		brokenImageIcon: {
			fontSize: ({ imageHeight }) => `${imageHeight - 35}px`,
		},
	})
);

const CARD_IMAGE_HEIGHT = 96;
const CARD_IMAGE_HEIGHT_SMALL = 64;

const ActionCardImageContent = (props: ActionCardImageContentProps): ReactElement => {
	const imageHeight = props.size === 'small' ? CARD_IMAGE_HEIGHT_SMALL : CARD_IMAGE_HEIGHT;
	const classes = useClasses({ imageHeight });

	return (
		<div className={classes.cardImageContainer}>
			{!!props.imageUrl ? (
				<CardMedia image={props.imageUrl} style={{ height: imageHeight }} className={classes.cardImage} />
			) : (
				<BrokenImageIcon className={classes.brokenImageIcon} />
			)}
		</div>
	);
};

export default ActionCardImageContent;
