import { DataGrid, GridColDef } from '@material-ui/data-grid';
import TitledBox from 'Components/Common/TitledBox';
import useFetchVideoProcessing from 'Hooks/Http/Videos/useFetchVideosProcesing';
import VideoFolder from 'Models/Videos/VideoFolder';
import { ReactElement } from 'react';

interface VideosProcessingTableProps {
	folders: VideoFolder[];
	foldersLoading: boolean;
}

const VideosProcessingTable = (props: VideosProcessingTableProps): ReactElement => {
	const { data, loading: videosLoading } = useFetchVideoProcessing();
	const videosInProcessing = data ?? [];
	const loading = videosLoading || props.foldersLoading;

	const rows = videosInProcessing.map((x) => ({
		id: x.id,
		name: x.name,
		hash: x.hash,
		folder: props.folders.find((f) => f.id === x.folderId)?.name ?? '/',
		progress: `${x.progress}%`,
	}));

	const columns: GridColDef[] = [
		{ field: 'name', headerName: 'Name', width: 250 },
		{ field: 'hash', headerName: 'Hash', width: 250 },
		{ field: 'folder', headerName: 'Folder', width: 250 },
		{ field: 'progress', headerName: 'Progress', width: 110, align: 'center', headerAlign: 'center' },
	];
	return (
		<TitledBox title="Videos in Processing" border={1}>
			<DataGrid autoHeight={true} rows={rows} columns={columns} disableSelectionOnClick={true} loading={loading} />
		</TitledBox>
	);
};

export default VideosProcessingTable;
