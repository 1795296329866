import Grid from '@material-ui/core/Grid';
import DeleteVideoModal from 'Components/Videos/Videos/Modals/DeleteVideoModal';
import EditVideoModal from 'Components/Videos/Videos/Modals/EditVideoModal';
import RefreshVideoModal from 'Components/Videos/Videos/Modals/RefreshVideoModal';
import VideoCard from 'Components/Videos/Videos/VideoCard';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import Project from 'Models/Project';
import Video from 'Models/Videos/Video';
import { Dispatch, ReactElement, SetStateAction, useState } from 'react';

interface VideoCardGridItemsProps {
	videos: Video[];
	onVideoDataChanged: (videos: Video[]) => void;
	selectedVideoIds: number[];
	setSelectedVideoIds: Dispatch<SetStateAction<number[]>>;
	projects: Project[];
	onVideoDownloadButtonClicked: (videoId: number) => void;
}

interface VideoModalState {
	isOpen: boolean;
	video: Video | null;
}

const videoModalInitialState: VideoModalState = {
	isOpen: false,
	video: null,
};

const VideoCardGridItems = (props: VideoCardGridItemsProps): ReactElement => {
	const [deleteVideoModalState, setDeleteVideoModalState] = useState(videoModalInitialState);
	const [editVideoModalState, setEditVideoModalState] = useState(videoModalInitialState);
	const [refreshVideoModalState, setRefreshVideoModalState] = useState(videoModalInitialState);

	const selectedFolderId = useAppSelector((state) => state.video.selectedFolderId);

	const onSelectedVideoChanged = (selected: boolean, id: number): void => {
		if (selected)
			props.setSelectedVideoIds((prev) => {
				if (prev.includes(id)) return prev;
				return [...prev, id];
			});
		else
			props.setSelectedVideoIds((prev) => {
				if (!prev.includes(id)) return prev;
				return prev.filter((x) => x !== id);
			});
	};

	const handleDeleteButtonClicked = (video: Video): void => {
		if (video) setDeleteVideoModalState({ isOpen: true, video });
	};

	const handleEditButtonClicked = (video: Video): void => {
		setEditVideoModalState({ video, isOpen: true });
	};

	const handleDownloadButtonClicked = (videoId: number): void => {
		props.onVideoDownloadButtonClicked(videoId);
	};

	const handleRefreshButtonClicked = (video: Video): void => {
		setRefreshVideoModalState({ video, isOpen: true });
	};

	const handleDeleteVideoModalClose = (): void => {
		setDeleteVideoModalState((prev) => ({
			...prev,
			isOpen: false,
		}));
	};

	const handleVideoDeleted = (id: number): void => {
		props.onVideoDataChanged(props.videos.filter((x) => x.id !== id));
	};

	const handleEditVideoModalClose = (): void => {
		setEditVideoModalState((prev) => ({
			...prev,
			isOpen: false,
		}));
	};

	const handleVideoEdited = (video: Video): void => {
		const videoIndex = props.videos.findIndex((x) => x.id === video.id);
		if (videoIndex < 0) return;

		if (video.folderId !== selectedFolderId) {
			props.onVideoDataChanged(props.videos.filter((x) => x.id !== video.id));
		} else {
			const videos = [...props.videos];
			videos[videoIndex] = video;
			props.onVideoDataChanged(videos);
		}
	};

	const handleRefreshVideoModalClose = (): void => {
		setRefreshVideoModalState((prev) => ({
			...prev,
			isOpen: false,
		}));
	};

	return (
		<>
			{props.videos.map((x) => (
				<Grid item={true} xs={12} sm={6} md={4} lg={3} xl={2} key={x.id}>
					<VideoCard
						video={x}
						isSelected={props.selectedVideoIds.includes(x.id)}
						onSelectedChanged={onSelectedVideoChanged}
						onDeleteButtonClicked={handleDeleteButtonClicked}
						onEditButtonClicked={handleEditButtonClicked}
						onDownloadButtonClicked={handleDownloadButtonClicked}
						onRefreshButtonClicked={handleRefreshButtonClicked}
					/>
				</Grid>
			))}
			{deleteVideoModalState.video && (
				<DeleteVideoModal
					isOpen={deleteVideoModalState.isOpen}
					onClose={handleDeleteVideoModalClose}
					video={deleteVideoModalState.video}
					onVideoDeleted={handleVideoDeleted}
				/>
			)}
			<EditVideoModal
				isOpen={editVideoModalState.isOpen}
				onClose={handleEditVideoModalClose}
				video={editVideoModalState.video}
				onVideoEdited={handleVideoEdited}
				projects={props.projects}
			/>
			<RefreshVideoModal
				isOpen={refreshVideoModalState.isOpen}
				onClose={handleRefreshVideoModalClose}
				video={refreshVideoModalState.video}
			/>
		</>
	);
};
export default VideoCardGridItems;
