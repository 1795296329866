import { Input, InputLabel, Tab, Tabs } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import CheckboxSelector, { CheckboxSelectorData } from 'Components/Common/CheckboxSelector';
import SaveModal from 'Components/Common/Modals/SaveModal';
import useFetchBrandGroups from 'Hooks/Http/Brands/useFetchBrandGroups';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import Project from 'Models/Project';
import { ChangeEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import postProjectRequest from 'Utils/Http/Requests/Projects/PostProjectRequest';
import putProjectRequest from 'Utils/Http/Requests/Projects/PutProjectRequest';

interface CreateOrEditProjectModalProps {
	isOpen: boolean;
	onProjectSaved: (project: Project, isCreate: boolean) => void;
	onClose: () => void;
	project?: Project;
}

const CreateOrEditProjectModal = (props: CreateOrEditProjectModalProps): ReactElement => {
	const [name, setName] = useState(props.project?.name ?? '');
	const [description, setDescription] = useState(props.project?.description ?? '');
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedBrandGroupIds, setSelectedBrandGroupIds] = useState<number[]>(props.project?.brandGroupIds ?? []);

	const brandsState = useFetchBrandGroups();
	const { dispatchStopLoading, dispatchStartLoading } = useLoadingDispatch();
	const token = useAppSelector((state) => state.profile.token);
	const { dispatchSetAxiosErrorMessage, dispatchSetMessageAction } = useMessagesDispatch();

	const brandsData = useMemo(
		(): CheckboxSelectorData[] =>
			brandsState.data?.groups?.map((x) => ({
				name: x.name,
				id: x.id,
				selected: selectedBrandGroupIds.includes(x.id),
			})) ?? [],
		[brandsState.data?.groups, selectedBrandGroupIds]
	);

	// The effect will set the input values to that of a project that is given via props.
	useEffect(() => {
		if (props.project === undefined) return;

		setName(props.project.name);
		setDescription(props.project.description);
		setSelectedBrandGroupIds(props.project.brandGroupIds);
	}, [props.project]);

	const createProject: (data: Omit<Project, 'id'>) => Promise<Project | null> = async (data) => {
		try {
			const response = await postProjectRequest(token, data);
			dispatchSetMessageAction(`Created new Project ${response.data.name}.`, MessageStatusLevel.Success);
			return response.data;
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		}
		return null;
	};

	const updateProject: (data: Omit<Project, 'id'>, id: number) => Promise<Project | null> = async (data, id) => {
		try {
			const response = await putProjectRequest(token, id, data);
			dispatchSetMessageAction(`Updated Project ${response.data.name}.`, MessageStatusLevel.Success);
			return response.data;
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		}
		return null;
	};

	const handleModalSaveClicked: () => void = async () => {
		dispatchStartLoading();
		const isCreate = props.project === undefined;
		const data: Omit<Project, 'id'> = {
			name,
			description,
			brandGroupIds: selectedBrandGroupIds,
		};

		const project =
			props.project === undefined ? await createProject(data) : await updateProject(data, props.project.id);
		if (project === null) return;

		props.onProjectSaved(project, !isCreate);
		props.onClose();
		dispatchStopLoading();
	};

	const handleModalExited = (): void => {
		// Clear all input fields so that next time user opens the modal everything can is empty.
		setName('');
		setDescription('');
		setSelectedBrandGroupIds([]);
	};

	const handleTabChange = (e: ChangeEvent<{}>, newValue: number): void => {
		setSelectedTab(newValue);
	};

	const handleNameInputChanged = ({ target }: ChangeEvent<HTMLInputElement>): void => {
		setName(target.value);
	};

	const handleDescriptionInputChanged = ({ target }: ChangeEvent<HTMLInputElement>): void => {
		setDescription(target.value);
	};

	const handleBrandSelectionChanged = (id: number, checked: boolean): void => {
		if (checked) setSelectedBrandGroupIds((prev) => [...prev, id]);
		else setSelectedBrandGroupIds((prev) => prev.filter((x) => x !== id));
	};

	const handleSelectAllBrands = (on: boolean): void => {
		if (on) setSelectedBrandGroupIds(brandsData.map((x) => x.id));
		else setSelectedBrandGroupIds([]);
	};

	return (
		<SaveModal
			onSave={handleModalSaveClicked}
			open={props.isOpen}
			onClose={props.onClose}
			onModalExited={handleModalExited}
		>
			<Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered={true}>
				<Tab label="Project" />
				<Tab label="Brands" />
			</Tabs>
			{selectedTab === 0 && (
				<>
					<FormControl margin="normal" fullWidth={true}>
						<InputLabel required={true}>Name</InputLabel>
						<Input onChange={handleNameInputChanged} type="text" value={name} required={true} />
					</FormControl>
					<FormControl margin="normal" fullWidth={true}>
						<InputLabel required={true}>Description</InputLabel>
						<Input onChange={handleDescriptionInputChanged} type="text" value={description} required={true} />
					</FormControl>
				</>
			)}
			{selectedTab === 1 && (
				<CheckboxSelector
					data={brandsData}
					onCheckboxSelected={handleBrandSelectionChanged}
					selectAll={handleSelectAllBrands}
				/>
			)}
		</SaveModal>
	);
};

export default CreateOrEditProjectModal;
