import { createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { CSSProperties as ReactCSSProperties } from 'react';

interface UseMainMenuClassesProps {
	drawerWidth: number;
}

// A Hack to allow passing props and having return. Sadly must define twice.
type Classes = 'toolbarIcon' | 'drawerPaperClose' | 'drawerPaper';
type UseMainMenuClasses = (props: UseMainMenuClassesProps) => ClassNameMap<Classes>;

const useMainMenuClasses: UseMainMenuClasses = makeStyles(({ transitions, spacing, mixins }: Theme) =>
	createStyles({
		drawerPaper: {
			position: 'relative',
			whiteSpace: 'nowrap',
			width: ({ drawerWidth }) => drawerWidth,
			transition: transitions.create('width', {
				easing: transitions.easing.sharp,
				duration: transitions.duration.enteringScreen,
			}),
			overflowX: 'hidden',
			overflowY: 'hidden',
			height: '100vh',
		},
		drawerPaperClose: {
			width: `${spacing(9)}px !important`,
		},
		toolbarIcon: {
			...(mixins.toolbar as ReactCSSProperties),
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			padding: '0 8px',
		},
	})
);

export default useMainMenuClasses;
