import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useMainMenuListItemClasses = makeStyles(({ spacing }: Theme) =>
	createStyles({
		navLink: {
			textDecoration: 'none',
			color: 'unset',
		},
		navLinkSelected: {
			fontWeight: 'bold',
		},
		nestedList: {
			paddingLeft: spacing(2),
		},
	})
);

export default useMainMenuListItemClasses;
