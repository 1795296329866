import { AxiosPromise } from 'axios';
import VideoFolder from 'Models/Videos/VideoFolder';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

const getFoldersRequest = (token: string | null): AxiosPromise<VideoFolder[]> => {
	const url = getApiUrl(ApiUrl.Folders);
	const axiosInstance = getAxiosInstance(token);
	return axiosInstance.get(url);
};

export default getFoldersRequest;
