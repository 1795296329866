import { AxiosPromise } from 'axios';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

const deleteFolderRequest = (token: string | null, folderId: number): AxiosPromise => {
	const url = getApiUrl(ApiUrl.FoldersFolder).replace(':folderId', folderId.toString());
	const axios = getAxiosInstance(token);
	return axios.delete(url);
};

export default deleteFolderRequest;
