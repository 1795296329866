import BrandLogo from 'Models/Brands/BrandLogo';
import BrandText from 'Models/Brands/BrandText';

export enum BrandStatus {
	Activated,
	Deactivated,
}

export interface Brand {
	id: number;
	name: string;
	status: BrandStatus;
	iconUrl: string | null;
	logos: BrandLogo[];
	texts: BrandText[];
	groupId: number;
}

export default Brand;
