import { AxiosPromise } from 'axios';
import { User } from 'Models/User';
import { getAxiosInstance } from 'Utils/Http/ApiManager';
import { ApiUrl, getApiUrl } from 'Utils/Http/ApiUrls';

export type GetUserResponse = User;
export type GetUserRequest = (token: string | null) => AxiosPromise<GetUserResponse>;

const getUserRequest: GetUserRequest = async (token) => {
	const url = getApiUrl(ApiUrl.User);
	const axios = getAxiosInstance(token);
	return axios.get<GetUserResponse>(url);
};

export default getUserRequest;
