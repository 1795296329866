const JIRA_STYLE_TIME_REGEX = new RegExp(/^([0-9]*h)?\s*([0-9]*m)?\s*([0-9]*s)?$/i);

export const getLocalFormattedDateFromUTCTimestamp = (timestamp: number): string => {
	const date = getLocalDateFromUTCTimestamp(timestamp);
	return date.toLocaleString();
};

export const getDateFromTimestamp = (timestamp: number): Date => {
	return new Date(timestamp * 1000);
};

export const getLocalDateFromUTCTimestamp = (timestamp: number): Date => {
	const utcDate = getDateFromTimestamp(timestamp);

	const newDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000);

	const offset = utcDate.getTimezoneOffset() / 60;
	const hours = utcDate.getHours();

	newDate.setHours(hours - offset);

	return newDate;
};

export const validateJiraStyleTime = (value: string): boolean => {
	return JIRA_STYLE_TIME_REGEX.test(value.trim());
};

export const getTimestampFromJiraStyleTime = (value: string): number | null => {
	// If value is not set or is not valid time just return null

	if (value.length === 0 || !validateJiraStyleTime(value)) return null;
	const groups = value.trim().match(JIRA_STYLE_TIME_REGEX);
	if (groups === null) return null;

	let timestamp = 0;
	for (let i = 1; i < groups.length; i++) {
		const numberValue = parseInt(groups[i]);
		if (isNaN(numberValue)) continue;

		// To Miliseconds!
		if (groups[i].includes('h')) timestamp += numberValue * 60 * 60 * 1000;
		if (groups[i].includes('m')) timestamp += numberValue * 60 * 1000;
		if (groups[i].includes('s')) timestamp += numberValue * 1000;
	}
	return timestamp;
};

export const getJiraStyleTimeFromTimestamp = (value: number): string => {
	const totalSeconds = value / 1000;
	const seconds = totalSeconds % 60;
	const totalMinutes = totalSeconds / 60;
	const minutes = totalMinutes % 60;
	const hours = Math.floor(totalMinutes / 60);
	return `${hours}h ${minutes}m ${seconds}s`;
};
