import { ListItem, TextField, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { Autocomplete } from '@material-ui/lab';
import SaveModal from 'Components/Common/Modals/SaveModal';
import useFetchFolders from 'Hooks/Http/Folders/useFetchFolders';
import useAppSelector from 'Hooks/Redux/useAppSelector';
import useLoadingDispatch from 'Hooks/Redux/useLoadingDispatch';
import useMessagesDispatch from 'Hooks/Redux/useMessagesDispatch';
import Project from 'Models/Project';
import VideoFolder from 'Models/Videos/VideoFolder';
import { ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { MessageStatusLevel } from 'Redux/Actions/Messages';
import patchFolderRequest, { PatchFolderRequest } from 'Utils/Http/Requests/Folder/PatchFolderRequest';
import postFolderRequest, { PostFolderRequest } from 'Utils/Http/Requests/Folder/PostFolderRequest';

interface EditVideoFolderModalProps {
	isOpen: boolean;
	onClose: () => void;
	folder: VideoFolder | null;
	onFolderEdited: (folder: VideoFolder) => void;
	onFolderCreated: (folder: VideoFolder) => void;
	projects: Project[];
	currentFolderId: number | null;
}

const EditVideoFolderModal = ({
	isOpen,
	onClose,
	folder,
	onFolderEdited,
	onFolderCreated,
	projects,
	currentFolderId,
}: EditVideoFolderModalProps): ReactElement => {
	const [name, setName] = useState(folder?.name ?? '');
	const [parentId, setParentId] = useState(folder?.parentId ?? null);
	const [projectId, setProjectId] = useState(folder?.projectId ?? null);

	const { data: existingFolders } = useFetchFolders(!isOpen);
	const { dispatchStartLoading, dispatchStopLoading } = useLoadingDispatch();
	const { dispatchSetAxiosErrorMessage, dispatchSetMessageAction } = useMessagesDispatch();
	const token = useAppSelector<string | null>((state) => state.profile.token);

	const isSaveButtonDisabled = name === ''; // Aka invalid state.
	const selectedParentFolder = existingFolders?.find((x) => x.id === parentId) ?? null;
	const selectedProject = projects.find((x) => x.id === projectId) ?? null;

	const getFoldersChildIds = useCallback(
		(parentToSearchFor: number): number[] => {
			if (existingFolders === null) return [];
			const results: number[] = [];
			for (let i = 0; i < existingFolders.length; i++) {
				const folder = existingFolders[i];
				if (folder.parentId === parentToSearchFor) {
					results.push(folder.id, ...getFoldersChildIds(folder.id));
				}
			}
			return results;
		},
		[existingFolders]
	);

	const filteredFolderOptions = useMemo((): VideoFolder[] => {
		if (folder === null) return existingFolders ?? [];

		const folderChildIds = getFoldersChildIds(folder.id);
		return existingFolders?.filter((x) => !folderChildIds.includes(x.id) && x.id !== folder.id) ?? [];
	}, [existingFolders, folder, getFoldersChildIds]);

	// Effects updates the input field if folder props get changed.
	useEffect(() => {
		if (folder === null) return;

		setName(folder.name);
		setParentId(folder.parentId);
		setProjectId(folder.projectId);
	}, [folder]);

	useEffect(() => {
		if (parentId !== null || currentFolderId === null || !isOpen) return;
		setParentId(currentFolderId);
	}, [currentFolderId, isOpen, parentId]);

	const handleModalExited = (): void => {
		setName('');
		setParentId(null);
	};

	const handleCreateFolder: () => void = async () => {
		dispatchStartLoading();
		const postData: PostFolderRequest = {
			parentFolderId: parentId,
			name,
			projectId,
		};
		try {
			const response = await postFolderRequest(token, postData);
			onFolderCreated(response.data);
			dispatchSetMessageAction(
				`Successfully created folder ${response.data.name} [${response.data.id}].`,
				MessageStatusLevel.Success
			);
			onClose();
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		} finally {
			dispatchStopLoading();
		}
	};

	const handleUpdateFolder: (folderId: number) => void = async (folderId) => {
		dispatchStartLoading();
		const patchData: PatchFolderRequest = {
			parentFolderId: parentId,
			name: name,
			projectId,
		};
		try {
			const response = await patchFolderRequest(token, patchData, folderId);
			onFolderEdited(response.data);
			dispatchSetMessageAction(
				`Successfully updated folder ${response.data.name} [${response.data.id}].`,
				MessageStatusLevel.Success
			);
			onClose();
		} catch (e) {
			dispatchSetAxiosErrorMessage(e);
		} finally {
			dispatchStopLoading();
		}
	};

	const handleModalSaveClicked = () => {
		if (folder === null) handleCreateFolder();
		else handleUpdateFolder(folder.id);
	};

	const handleNameInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
		setName(event.target.value);
	};

	const handleParentIdSelectChanged = (event: ChangeEvent<{}>, value: VideoFolder | null): void => {
		if (value === null) setParentId(null);
		else setParentId(value.id);
	};

	const handleProjectIdSelectChanged = (event: ChangeEvent<{}>, value: Project | null): void => {
		if (value === null) setProjectId(null);
		else setProjectId(value.id);
	};

	return (
		<SaveModal
			open={isOpen}
			onClose={onClose}
			onModalExited={handleModalExited}
			onSave={handleModalSaveClicked}
			saveButtonDisabled={isSaveButtonDisabled}
		>
			<Typography variant="h5">
				{folder === null ? 'Create new Folder' : `Edit folder ${folder.name} [${folder.id}]`}
			</Typography>
			<Divider />
			<List>
				<ListItem>
					<TextField
						required={true}
						label="Name"
						type="text"
						value={name}
						onChange={handleNameInputChanged}
						fullWidth={true}
					/>
				</ListItem>
				<ListItem>
					<Autocomplete
						renderInput={(params) => <TextField {...params} label="Parent Folder" fullWidth={true} />}
						options={filteredFolderOptions}
						fullWidth={true}
						getOptionLabel={(option) => option.name}
						autoHighlight={true}
						getOptionSelected={(option) => option.id === parentId}
						onChange={handleParentIdSelectChanged}
						value={selectedParentFolder}
					/>
				</ListItem>
				<ListItem>
					<Autocomplete
						renderInput={(params) => <TextField {...params} label="Project" fullWidth={true} />}
						options={projects}
						fullWidth={true}
						getOptionLabel={(option) => option.name}
						autoHighlight={true}
						getOptionSelected={(option) => option.id === projectId}
						onChange={handleProjectIdSelectChanged}
						value={selectedProject}
					/>
				</ListItem>
			</List>
		</SaveModal>
	);
};

export default EditVideoFolderModal;
