import useAppSelector from 'Hooks/Redux/useAppSelector';
import { UserClaimFlag } from 'Models/User';
import { PropsWithChildren, ReactElement } from 'react';

interface ClaimPermissionComponentProps {
	claimFlag?: UserClaimFlag;
}

const ClaimPermissionComponent = (props: PropsWithChildren<ClaimPermissionComponentProps>): ReactElement | null => {
	const userClaimFlag = useAppSelector((state) => state.profile.user?.claimFlag);

	if (!props.claimFlag) return <>{props.children}</>;
	if (userClaimFlag === undefined || (userClaimFlag & props.claimFlag) === 0) return null;
	return <>{props.children}</>;
};

export default ClaimPermissionComponent;
