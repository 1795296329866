import { Breadcrumbs, createStyles, Link, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import SearchBar from 'Components/Common/SearchBar';
import useVideoDispatch from 'Hooks/Redux/useVideoDispatch';
import VideoFolder from 'Models/Videos/VideoFolder';
import { ReactElement, useCallback, useMemo } from 'react';

interface VideoMenuBarProps {
	currentFolderName: string;
	parentFolders: VideoFolder[];
	onSearchChanged: (value: string) => void;
}

const useClasses = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			padding: theme.spacing(2),
			marginBottom: theme.spacing(2),
		},
		link: {
			cursor: 'pointer',
		},
		breadcrumbs: {
			lineHeight: 2,
		},
	})
);

const VideoMenuBar = ({ parentFolders, onSearchChanged, currentFolderName }: VideoMenuBarProps): ReactElement => {
	const classes = useClasses();

	const { dispatchSelectFolder } = useVideoDispatch();

	const getSortedFolders = useCallback(() => {
		let previousFolderId: number | null = null;
		let sortedFolders: VideoFolder[] = [];
		for (let i = 0; i < parentFolders.length; i++) {
			// eslint-disable-next-line no-loop-func
			const folder = parentFolders.find((x) => x.parentId === previousFolderId);
			if (!folder) continue;

			previousFolderId = folder.id;
			sortedFolders.push(folder);
		}
		return sortedFolders;
	}, [parentFolders]);

	const sortedFolders = useMemo(getSortedFolders, [getSortedFolders]);

	const handleBreadcrumbClicked = (id: number | null): void => {
		dispatchSelectFolder(id);
	};

	const handleRootBreadcrumbClicked = (): void => {
		handleBreadcrumbClicked(null);
	};

	return (
		<Paper elevation={1} className={classes.paper}>
			<Grid container={true}>
				<Grid item={true} xs={6}>
					<Breadcrumbs className={classes.breadcrumbs} maxItems={5}>
						<Link onClick={handleRootBreadcrumbClicked} className={classes.link} href="#">
							Videos
						</Link>
						{sortedFolders.map((x, i) => (
							<Link key={i} onClick={() => handleBreadcrumbClicked(x.id)} className={classes.link} href="#">
								{x.name}
							</Link>
						))}
						{currentFolderName && (
							<Typography variant="body1" component="span">
								{currentFolderName}
							</Typography>
						)}
					</Breadcrumbs>
				</Grid>
				<Grid item={true} xs={6}>
					<SearchBar onSearchChanged={onSearchChanged} withoutBottomMargin={true} />
				</Grid>
			</Grid>
		</Paper>
	);
};

export default VideoMenuBar;
